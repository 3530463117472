import { FC, useState, useCallback, Dispatch, SetStateAction, useEffect } from 'react';
import { Box, FormLabel, NativeSelect, Typography } from '@material-ui/core';
import { Payment, ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { CustomLabel } from 'components/inputs';
import { BankDocument, DocumentCard, DocumentUpload } from 'components/documents';
import { additionalDocumentTypes, additionalDocumentOptions } from 'core/constants';
import { CompanyDocumentType } from 'core/types';
import { useOnboard } from 'store/onboard/hooks';
import { ReactComponent as AdditionalInformation } from 'assets/icons/additionalInformation.svg';
import { ReactComponent as FiledAccounts } from 'assets/icons/filedAccounts.svg';
import { ReactComponent as ManagementAccounts } from 'assets/icons/managementAccounts.svg';
import useCommonStyles from 'core/styles';
import { ApplicationDetailsRequested } from 'store/app/types';
import useStyles from './DocumentsSection.styles';

interface DocumentsSectionProps {
  errors: Record<CompanyDocumentType, string | undefined>;
  selectedCard: CompanyDocumentType | null;
  setSelectedCard: Dispatch<SetStateAction<CompanyDocumentType | null>>;
  appDetailsRequested?: ApplicationDetailsRequested;
}

const DocumentsSection: FC<DocumentsSectionProps> = ({
  errors,
  selectedCard,
  setSelectedCard,
  appDetailsRequested,
}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState<CompanyDocumentType>(CompanyDocumentType.VAT_RETURN);
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { additionalInfo } = useOnboard();

  useEffect(() => {
    if (appDetailsRequested) {
      const additionalDocumentType = appDetailsRequested?.document_types.find((type) =>
        additionalDocumentTypes.includes(type),
      );
      if (additionalDocumentType) setSelectedDocumentType(additionalDocumentType);
    }
  }, [appDetailsRequested]);

  const toggleCard = useCallback(
    (type: CompanyDocumentType | null) => {
      setSelectedCard(type);
    },
    [setSelectedCard],
  );

  const shouldRenderDocumentType = (type: CompanyDocumentType | CompanyDocumentType[]) => {
    if (!Array.isArray(type)) {
      return !appDetailsRequested || appDetailsRequested.document_types.includes(type);
    }
    return !appDetailsRequested || !!type.find((docType) => appDetailsRequested.document_types.includes(docType));
  };

  const hasPaymentData = additionalInfo[CompanyDocumentType.PAYMENT_DATA].length > 0;
  const hasManagementAccountData = additionalInfo[CompanyDocumentType.MANAGEMENT_ACCOUNTS].length > 0;
  const hasFiledAccountsData = additionalInfo[CompanyDocumentType.FILED_ACCOUNTS].length > 0;
  const hasVatReturnData = additionalInfo[CompanyDocumentType.VAT_RETURN].length > 0;
  const hasIdentityDocumentData = additionalInfo[CompanyDocumentType.IDENTITY_DOCUMENT].length > 0;

  return (
    <>
      <Typography className={clsx([commonClasses.subtitle2, commonClasses.primaryText, commonClasses.strongText])}>
        {t('pages.additionalInfo.sections.documents.title')}
      </Typography>

      <Typography className={clsx([commonClasses.subtitle2, commonClasses.mb40])}>
        {t('pages.additionalInfo.sections.documents.description')}
      </Typography>

      {shouldRenderDocumentType(CompanyDocumentType.BANK_STATEMENT) && (
        <BankDocument
          active={selectedCard === CompanyDocumentType.BANK_STATEMENT}
          toggleCard={toggleCard}
          validationError={errors[CompanyDocumentType.BANK_STATEMENT]}
        />
      )}

      {shouldRenderDocumentType(CompanyDocumentType.PAYMENT_DATA) && (
        <DocumentCard
          title={t('pages.additionalInfo.documentUploads.paymentData.title')}
          description={t('pages.additionalInfo.documentUploads.paymentData.description')}
          Icon={<Payment color="primary" />}
          documentType={CompanyDocumentType.PAYMENT_DATA}
          active={selectedCard === CompanyDocumentType.PAYMENT_DATA}
          toggleCard={toggleCard}
          withMainButton={false}
          success={hasPaymentData}
          optional={!appDetailsRequested}
        >
          <DocumentUpload
            documentType={CompanyDocumentType.PAYMENT_DATA}
            validationError={errors[CompanyDocumentType.PAYMENT_DATA]}
            multipleFiles
          />
        </DocumentCard>
      )}

      {shouldRenderDocumentType(CompanyDocumentType.MANAGEMENT_ACCOUNTS) && (
        <DocumentCard
          title={t('pages.additionalInfo.documentUploads.managementAccounts.title')}
          description={t('pages.additionalInfo.documentUploads.managementAccounts.description')}
          Icon={<ManagementAccounts />}
          documentType={CompanyDocumentType.MANAGEMENT_ACCOUNTS}
          active={selectedCard === CompanyDocumentType.MANAGEMENT_ACCOUNTS}
          toggleCard={toggleCard}
          withMainButton={false}
          success={hasManagementAccountData}
          optional={!appDetailsRequested}
        >
          <DocumentUpload
            documentType={CompanyDocumentType.MANAGEMENT_ACCOUNTS}
            validationError={errors[CompanyDocumentType.MANAGEMENT_ACCOUNTS]}
            multipleFiles
          />
        </DocumentCard>
      )}

      {shouldRenderDocumentType(CompanyDocumentType.FILED_ACCOUNTS) && (
        <DocumentCard
          title={t('pages.additionalInfo.documentUploads.filedAccounts.title')}
          description={t('pages.additionalInfo.documentUploads.filedAccounts.description')}
          Icon={<FiledAccounts />}
          documentType={CompanyDocumentType.FILED_ACCOUNTS}
          active={selectedCard === CompanyDocumentType.FILED_ACCOUNTS}
          toggleCard={toggleCard}
          withMainButton={false}
          success={hasFiledAccountsData}
          optional={!appDetailsRequested}
        >
          <DocumentUpload
            documentType={CompanyDocumentType.FILED_ACCOUNTS}
            validationError={errors[CompanyDocumentType.FILED_ACCOUNTS]}
            multipleFiles
          />
        </DocumentCard>
      )}

      {shouldRenderDocumentType(additionalDocumentTypes) && (
        <DocumentCard
          title={t('pages.additionalInfo.documentUploads.additionalInfo.title')}
          description={t('pages.additionalInfo.documentUploads.additionalInfo.description')}
          Icon={<AdditionalInformation />}
          documentType={selectedDocumentType}
          active={selectedCard ? additionalDocumentTypes.includes(selectedCard) : false}
          toggleCard={toggleCard}
          withMainButton={false}
          success={hasVatReturnData && hasIdentityDocumentData}
          optional={!appDetailsRequested}
        >
          <Box className={clsx([commonClasses.documentUploadPadding, classes.selectContainer])}>
            <FormLabel
              component={() => <CustomLabel title={t('pages.additionalInfo.documentUploads.additionalInfo.label')} />}
            />
            <NativeSelect
              IconComponent={() => <ExpandMore className={classes.expandIcon} />}
              onChange={({ target: { value } }) => setSelectedDocumentType(value as CompanyDocumentType)}
              value={selectedDocumentType}
              fullWidth
            >
              {additionalDocumentOptions
                .filter((option) => appDetailsRequested?.document_types.includes(option.value))
                .map(({ label, value }) => {
                  return (
                    <option key={`option${value}`} value={value}>
                      {label}
                    </option>
                  );
                })}
            </NativeSelect>
          </Box>

          <DocumentUpload
            documentType={selectedDocumentType}
            validationError={
              errors[selectedDocumentType] ??
              errors[CompanyDocumentType.VAT_RETURN] ??
              errors[CompanyDocumentType.IDENTITY_DOCUMENT]
            }
            multipleFiles
          />
        </DocumentCard>
      )}
    </>
  );
};

export default DocumentsSection;
