import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnboard } from 'store/onboard/hooks';
import useStyles from './HideOptionalFields.styles';

const WithOptionalFields: FC = () => {
  const { t } = useTranslation();
  const { hideOptionalFields, setHideOptionalFields } = useOnboard();
  const classes = useStyles();

  return (
    <Alert severity="success" className={classes.container} icon={<div />}>
      <FormControlLabel
        control={
          <Checkbox
            checked={hideOptionalFields}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const checked = !!event.target.checked;
              setHideOptionalFields(checked);
            }}
            name="hideOptionalFields"
            size="medium"
            id="hideOptionalFields"
            color="primary"
          />
        }
        label={
          <Box className={classes.labelContainer}>
            <Typography className={classes.inputTitle}>{t('onboard.withOptionalFieldsTitle')}</Typography>
            <Typography className={classes.inputDesc}>{t('onboard.withOptionalFieldsDesc')}</Typography>
          </Box>
        }
      />
    </Alert>
  );
};

export default WithOptionalFields;
