import { FC, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useCommonStyles from 'core/styles';
import { Layout } from 'components/Layout';
import routes from 'router/routes';
import { useOnboard } from 'store/onboard/hooks';
import { openCalendly } from 'core/calendly';
import { businessScore } from 'core/constants';
import { useAppState } from 'store/app/hooks';
import { useHistory } from 'react-router-dom';
import analytics from 'core/services/analytics';
import useStyles from './Rejected.styles';

const Rejected: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { applicant } = useOnboard();
  const { t } = useTranslation();
  const { rejection, clearApplication, customCalendlyLink } = useAppState();
  const { push } = useHistory();

  useEffect(() => {
    analytics.track(routes.rejected);
  }, []);

  const scheduleCall = () => {
    openCalendly({
      url: customCalendlyLink ?? businessScore.calendly.brokers,
      prefill: {
        name: applicant.first_name ? `${applicant.first_name} ${applicant.last_name}` : '',
        email: applicant.email || '',
      },
    });
  };

  const tryAgain = () => {
    clearApplication();
    push(routes.home);
  };

  const renderDescription = () => {
    if (rejection) {
      return (
        <Typography className={commonClasses.subtitle}>
          {t('pages.rejected.description.line1')}{' '}
          {rejection.rejection_reason && (
            <>
              <strong className={commonClasses.primaryText}>{t('pages.rejected.description.line2')}</strong> &quot;
              {rejection.rejection_reason}&quot;{'.\n'}
            </>
          )}
          {t('pages.rejected.description.line3')}
        </Typography>
      );
    }

    return (
      <Typography className={commonClasses.subtitle}>
        {t('pages.rejected.description.line1')}
        {'.\n'}
        {t('pages.rejected.description.line3')}
      </Typography>
    );
  };

  return (
    <Layout showSidebar={false} withBackButton={false} showShapeBackground>
      <Box className={classes.container}>
        <Typography className={commonClasses.title}>{t('pages.rejected.title')}</Typography>

        {renderDescription()}

        <Button variant="contained" color="primary" className={classes.button} onClick={tryAgain}>
          {t('pages.rejected.buttons.main')}
        </Button>

        <Button variant="outlined" color="primary" className={classes.button} onClick={scheduleCall}>
          {t('pages.rejected.buttons.schedule')}
        </Button>
      </Box>
    </Layout>
  );
};

export default Rejected;
