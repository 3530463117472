/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { Box, Typography, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useCommonStyles from 'core/styles';
import { Layout } from 'components/Layout';
import routes from 'router/routes';
import analytics from 'core/services/analytics';
import useStyles from './Finish.styles';

const Finish: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    analytics.track(routes.finish);
  }, []);

  const onNext = () => {
    push(routes.offers);
  };

  return (
    <Layout withBackButton={false} showSidebar={false} showShape2Background>
      <Box className={classes.container}>
        <Typography className={commonClasses.title}>{t('pages.finish.title')}</Typography>

        <Typography className={commonClasses.subtitle}>
          {t('pages.finish.description.line1')}{' '}
          <Link href="#" className={commonClasses.strongText}>
            {t('pages.finish.description.line2')}
          </Link>
          .
        </Typography>

        <Button variant="contained" color="primary" className={classes.button} onClick={onNext}>
          {t('pages.finish.buttons.main')}
        </Button>
      </Box>
    </Layout>
  );
};

export default Finish;
