import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    cardTitle: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: 700,
      marginTop: theme.spacing(0.5),
    },
    acceptedContainer: {
      boxShadow: '0px 2px 8px 2px rgba(206, 213, 219, 0.40464)',
      borderRadius: 12,
      padding: 12,
      marginBottom: 16,
      height: 350,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: 40,
      backgroundColor: theme.palette.secondary.main,
    },
    acceptedIcon: {
      width: 80,
      height: 80,
      borderRadius: 80,
      marginBottom: theme.spacing(3),
    },
    acceptedTitle: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(36),
      textAlign: 'center',
      fontWeight: 700,
    },
    acceptedTitleStrong: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(36),
      textAlign: 'center',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    acceptedDescription: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      textAlign: 'center',
      marginTop: '12px !important',
    },
    drawdownLinkDescription: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    lenderLogoContainer: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    lenderLogo: {
      width: '100% !important',
      height: 'auto !important',
    },
  }),
);
