import { FC, useState, useEffect, useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';

import { AlertDialog } from 'components/alerts';
import { Layout } from 'components/Layout';
import { composeDirectorName, noop } from 'utils';
import { parseMoney } from 'utils/currency';
import { OfferCard } from 'components/offers';
import routes from 'router/routes';
import { useOffers } from 'store/offers/hooks';
import { openCalendly } from 'core/calendly';
import { businessScore } from 'core/constants';
import { useOnboard } from 'store/onboard/hooks';
import { useAppState } from 'store/app/hooks';
import { Loader } from 'components/Loader';
import useCommonStyles from 'core/styles';
import { LenderOffer, OfferStatus } from 'store/offers/types';
import analytics from 'core/services/analytics';
import { useAuth } from 'store/auth/hooks';
import useStyles from './Offers.styles';

interface OffersProps {
  onNotNow: () => void;
}

const Offers: FC<OffersProps> = ({ onNotNow }) => {
  const [selectedOfferUniqueId, setSelectedOfferUniqueId] = useState<string | null>(null);
  const [exitAlertOpened, setExitAlertOpened] = useState(false);
  const [offerAlertOpened, setOfferAlertOpened] = useState(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { push } = useHistory();
  const { loading, offers, getOffers, acceptOffer, setSelectedOfferId } = useOffers();
  const { t } = useTranslation();
  const { applicant } = useOnboard();
  const { clearApplication, popup, exitEnabled, customCalendlyLink } = useAppState();
  const { adminEditMode } = useAuth();

  useEffect(() => {
    analytics.track(routes.offers);
  }, []);

  useEffect(() => {
    getOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCard = (rateId: string | null) => {
    setSelectedOfferUniqueId(rateId);
  };

  const onSelectOffer = () => {
    setOfferAlertOpened(true);
  };

  const onNext = () => {
    openCalendly({
      url: customCalendlyLink ?? businessScore.calendly.brokers,
      prefill: {
        name: composeDirectorName(applicant.first_name ?? '', applicant.last_name ?? ''),
        email: applicant.email || '',
      },
    });
  };

  const onExit = () => {
    setExitAlertOpened(true);
  };

  const handleExitConfirm = () => {
    setExitAlertOpened(false);

    clearApplication(true);

    if (popup) {
      onNotNow();
    }
  };

  const handleExitCancel = () => {
    setExitAlertOpened(false);
  };

  const handleOfferSelectConfirm = () => {
    setOfferAlertOpened(false);
    if (selectedOfferUniqueId) acceptOffer(selectedOfferUniqueId);
  };

  const handleOfferSelectCancel = () => {
    setOfferAlertOpened(false);
  };

  const onGoBack = () => {
    push(routes.customiseGoal);
  };

  const provideDetails = (offerId: string) => {
    setSelectedOfferId(offerId);
    push(routes.detailsRequested);
  };

  const offersData = useMemo(() => {
    const validOffers: LenderOffer[] = [];
    const expiredOffers: LenderOffer[] = [];
    const detailsRequestedOffers: LenderOffer[] = [];
    const nowMs = Date.now();

    offers.forEach((offer) => {
      if (offer.status !== OfferStatus.ACCEPTED && offer.valid_until && new Date(offer.valid_until).getTime() < nowMs) {
        expiredOffers.push(offer);
      } else if (offer.status === OfferStatus.ADDITIONAL_DETAILS_REQUESTED) {
        detailsRequestedOffers.push(offer);
      } else {
        validOffers.push(offer);
      }
    });

    return { validOffers, expiredOffers, detailsRequestedOffers };
  }, [offers]);

  const renderContent = () => {
    if (loading) {
      return null;
    }

    if (offers.length > 0) {
      return (
        <>
          {offersData.validOffers.length > 0 && (
            <>
              <Typography className={classes.sectionHeader}>{t('pages.offers.offers')}</Typography>

              {offersData.validOffers.map((offer) => (
                <OfferCard
                  key={offer.unique_id}
                  title={parseMoney(offer.loan_amount, offer.currency)}
                  offer={offer}
                  toggleCard={toggleCard}
                  active={offer.unique_id === selectedOfferUniqueId}
                  onMainAction={onSelectOffer}
                  mainActionDisabled={offer.status === OfferStatus.ACCEPTED && !offer.selected}
                  cardName={offer.unique_id}
                />
              ))}
            </>
          )}

          {offersData.expiredOffers.length > 0 && (
            <>
              <Typography className={classes.sectionHeader}>{t('pages.offers.expiredOffers')}</Typography>

              {offersData.expiredOffers.map((offer) => (
                <OfferCard
                  key={offer.unique_id}
                  title={parseMoney(offer.loan_amount, offer.currency)}
                  offer={offer}
                  toggleCard={toggleCard}
                  active={offer.unique_id === selectedOfferUniqueId}
                  withMainButton={false}
                  cardName={offer.unique_id}
                />
              ))}
            </>
          )}

          {offersData.detailsRequestedOffers.length > 0 && (
            <>
              <Typography className={classes.sectionHeader}>
                {t('pages.offers.detailsRequestedOffers.title')}
              </Typography>

              <Typography className={clsx([commonClasses.subtitle2, classes.sectionDescription])}>
                {t('pages.offers.detailsRequestedOffers.description')}
              </Typography>

              {offersData.detailsRequestedOffers.map((offer) => (
                <OfferCard
                  key={offer.unique_id}
                  title={parseMoney(offer.loan_amount, offer.currency)}
                  offer={offer}
                  toggleCard={noop}
                  active
                  cardName={offer.unique_id}
                  buttonTitle={t('pages.offers.card.buttons.provideDetails')}
                  onMainAction={() => provideDetails(offer.id)}
                  detailsRequested
                />
              ))}
            </>
          )}
        </>
      );
    }

    return (
      <Box flex="1" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Typography className={commonClasses.title2}>{t('pages.offers.pending.title')}</Typography>

        <Typography className={commonClasses.subtitle2}>
          {t('pages.offers.pending.description.line1')}{' '}
          <strong className={commonClasses.primaryText}>{t('pages.offers.pending.description.line2')}</strong>{' '}
          {t('pages.offers.pending.description.line3')}
        </Typography>
      </Box>
    );
  };

  return (
    <Layout
      headerTitle={t('pages.offers.title')}
      onSecondaryAction={onNext}
      withBackButton={adminEditMode}
      secondaryButtonTitle={t('pages.offers.buttons.main')}
      withCloseButton={exitEnabled}
      onCloseApp={onExit}
      onGoBack={onGoBack}
      loading={loading}
      Loader={<Loader visible />}
      SecondaryActionMessage={
        <Box marginBottom="20px">
          <Alert severity="info">{t('pages.offers.alertInfo')}</Alert>
        </Box>
      }
    >
      <Typography className={commonClasses.subtitle2}>{t('pages.offers.description')}</Typography>

      <Box marginTop="48px" />

      {renderContent()}

      <AlertDialog
        open={exitAlertOpened}
        handleCancel={handleExitCancel}
        handleConfirm={handleExitConfirm}
        dialogContentTitle={t('pages.offers.dialogTitle')}
        dialogContentText={t('pages.offers.dialogText')}
        cancelText={t('pages.offers.dialogCancel')}
        confirmText={t('pages.offers.dialogConfirm')}
      />

      <AlertDialog
        open={offerAlertOpened}
        handleCancel={handleOfferSelectCancel}
        handleConfirm={handleOfferSelectConfirm}
        dialogContentTitle={t('pages.offers.offerSelectDialog.title')}
        dialogContentText={t('pages.offers.offerSelectDialog.description')}
      />
    </Layout>
  );
};

export default Offers;
