import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

import { MessageAlert } from 'components/alerts';
import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import { CompanyDocumentType, MessageAlertStatus } from 'core/types';
import routes from 'router/routes';
import { useAppState } from 'store/app/hooks';
import { useOnboard } from 'store/onboard/hooks';
import { useOffers } from 'store/offers/hooks';
import { getRequiredDocTranslationKey } from 'utils';
import analytics from 'core/services/analytics';
import { useAuth } from 'store/auth/hooks';
import useStyles from './AdditionalInfo.styles';
import { DocumentsSection } from './components';

const initialDocsErrors = {
  [CompanyDocumentType.BANK_STATEMENT]: undefined,
  [CompanyDocumentType.PAYMENT_DATA]: undefined,
  [CompanyDocumentType.MANAGEMENT_ACCOUNTS]: undefined,
  [CompanyDocumentType.FILED_ACCOUNTS]: undefined,
  [CompanyDocumentType.VAT_RETURN]: undefined,
  [CompanyDocumentType.IDENTITY_DOCUMENT]: undefined,
};

const AdditionalInfo: FC = () => {
  const [selectedCard, setSelectedCard] = useState<CompanyDocumentType | null>(null);
  const [errors, setErrors] = useState<Record<CompanyDocumentType, string | undefined>>(initialDocsErrors);
  const { documentUploadSuccess, setDocAlert, additionalInfo } = useOnboard();
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { details_requested, getApplication, loading } = useAppState();
  const { selectedOfferId, setSelectedOfferId } = useOffers();
  const { adminEditMode } = useAuth();
  const history = useHistory();

  useEffect(() => {
    analytics.track(routes.additionalInfo);
  }, []);

  useEffect(() => {
    return () => {
      setSelectedOfferId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Object.values(CompanyDocumentType).forEach((type) => {
      if (additionalInfo[type].length > 0) {
        const docType = (type as unknown) as CompanyDocumentType;
        setErrors((prevErrors) => ({ ...prevErrors, [docType]: undefined }));
      }
    });
  }, [additionalInfo, setErrors]);

  const appDetailsRequested = useMemo(() => {
    if (selectedOfferId) {
      return details_requested.find((dReq) => dReq.offer_id === selectedOfferId);
    }
    return details_requested.find((dReq) => !dReq.offer_id && !dReq.resolved);
  }, [details_requested, selectedOfferId]);

  const validDocuments = useCallback(() => {
    let valid = true;
    const docsErrors: Record<CompanyDocumentType, string | undefined> = { ...initialDocsErrors };

    const requiredDocs = Array.from(
      new Set([...(appDetailsRequested?.document_types || []), CompanyDocumentType.BANK_STATEMENT]),
    );

    requiredDocs.forEach((docType) => {
      const docUploadedType = (docType as unknown) as CompanyDocumentType;
      if (additionalInfo[docUploadedType].length === 0) {
        docsErrors[docType] = t(getRequiredDocTranslationKey(docType));
        valid = false;
        setSelectedCard(docUploadedType);
      }
    });

    setErrors(docsErrors);
    return valid;
  }, [additionalInfo, appDetailsRequested, t]);

  const onNext = useCallback(() => {
    if (!validDocuments() || !appDetailsRequested) return;

    getApplication();
  }, [appDetailsRequested, getApplication, validDocuments]);

  const handleClose = useCallback(
    (event?: SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') return;
      setDocAlert(false);
    },
    [setDocAlert],
  );

  const onGoBack = () => {
    if (!adminEditMode) return;
    history.push(routes.fundingGoal);
  };

  const pageTitle = appDetailsRequested ? t('pages.additionalInfo.followUp.title') : t('pages.additionalInfo.title');

  const pageDescription = appDetailsRequested
    ? appDetailsRequested.notes || t('pages.additionalInfo.followUp.description')
    : t('pages.additionalInfo.description');

  return (
    <Layout
      headerTitle={t('pages.additionalInfo.header')}
      onMainAction={onNext}
      mainButtonTitle={t('pages.additionalInfo.buttons.main')}
      withBackButton={adminEditMode}
      MainActionMessage={
        <Box marginBottom="20px" marginTop="20px">
          <Alert severity="info">{t('pages.additionalInfo.documents.missing.message')}</Alert>
        </Box>
      }
      showSidebar={false}
      mainButtonLoading={loading}
      onGoBack={onGoBack}
    >
      <Typography className={commonClasses.title2}>{pageTitle}</Typography>

      <Typography className={commonClasses.subtitle2}>{pageDescription}</Typography>

      <Box marginTop="60px" />

      <DocumentsSection
        errors={errors}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        appDetailsRequested={appDetailsRequested}
      />

      <MessageAlert
        open={documentUploadSuccess}
        message={t('documents.upload.success')}
        handleClose={handleClose}
        status={MessageAlertStatus.SUCCESS}
        containerClassName={classes.successAlertContainer}
      />
    </Layout>
  );
};

export default AdditionalInfo;
