import { FC, useEffect } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import { ReactComponent as AcceptedOfferSvg } from 'assets/icons/acceptedOffer.svg';
import routes from 'router/routes';
import analytics from 'core/services/analytics';
import useStyles from './AcceptedOffer.styles';

const AcceptedOffer: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { state } = useLocation<{ lenderName: string }>();

  useEffect(() => {
    analytics.track(routes.acceptedOffer);
  }, []);

  const goToOffers = () => push(routes.offers);

  return (
    <Layout showSidebar={false} withBackButton={false}>
      <Box className={classes.container}>
        <AcceptedOfferSvg className={classes.image} />

        <Typography className={commonClasses.title}>
          {t('pages.acceptedOffer.title', { lenderName: state?.lenderName })}
        </Typography>

        <Typography className={commonClasses.subtitle}>{t('pages.acceptedOffer.description')}</Typography>

        <Button variant="contained" color="primary" className={classes.button} onClick={goToOffers}>
          {t('pages.acceptedOffer.buttons.main')}
        </Button>
      </Box>
    </Layout>
  );
};

export default AcceptedOffer;
