import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    selectContainer: {
      '& .MuiNativeSelect-select': {
        paddingRight: 16,
      },
    },
    expandIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
      position: 'absolute',
      top: 7,
      right: 0,
    },
    successAlertContainer: {
      marginBottom: 145,
    },
  }),
);
