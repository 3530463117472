import { FC } from 'react';
import { TextField, Box, InputLabel } from '@material-ui/core';
import { FieldProps } from 'formik';

import { CustomLabel } from '../CustomLabel';
import { CustomInputError } from '../CustomInputError';

interface CustomTextFieldProps extends FieldProps {
  id: string;
  title: string;
  placeholder?: string;
  tooltipMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputProps?: Record<string, any>;
  labelClassName?: string;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

const CustomTextField: FC<CustomTextFieldProps> = ({
  title,
  placeholder,
  form,
  field,
  tooltipMessage,
  labelClassName,
  error,
  helperText,
  optional,
  ...rest
}) => {
  const hasError = !!(form.touched[field.name] && form.errors[field.name]) || error;
  const InputProps = rest.InputProps || {};

  const renderHelperText = () => {
    if (!hasError) return null;
    const message = (form.errors[field.name] || helperText) as string;
    return <CustomInputError message={message} component="span" />;
  };

  return (
    <Box>
      <InputLabel htmlFor={rest.id}>
        <CustomLabel
          title={title}
          tooltipMessage={tooltipMessage}
          labelClassName={labelClassName}
          optional={optional}
        />
      </InputLabel>

      <TextField
        type="text"
        placeholder={placeholder}
        error={hasError}
        InputProps={{ ...InputProps }}
        {...field}
        {...rest}
        helperText={renderHelperText()}
      />
    </Box>
  );
};

export default CustomTextField;
