import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useSliderStyles = makeStyles(() =>
  createStyles({
    rail: {
      height: 4,
    },
    track: {
      height: 4,
    },
    thumb: {
      marginTop: -4,
      boxShadow: '0px 4px 8px rgba(0, 122, 255, 0.2)',
    },
  }),
);

export default makeStyles(() =>
  createStyles({
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& p': {
        fontWeight: 700,
        position: 'absolute',
        right: 15,
      },
      width: 70,
    },
    input: {
      position: 'relative',
      width: 70,
      '& input': {
        paddingLeft: 10,
      },
    },
  }),
);
