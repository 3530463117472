import { CompanyDocumentType } from 'core/types';
import { FC } from 'react';

import { CollapsibleCard } from '../../CollapsibleCard';
import { CollapsibleCardProps } from '../../CollapsibleCard/CollapsibleCard';

interface DocumentCardProps extends Omit<CollapsibleCardProps, 'handleExpandClick'> {
  documentType: CompanyDocumentType;
  toggleCard: (integration: CompanyDocumentType | null) => void;
}

const DocumentCard: FC<DocumentCardProps> = ({ active, documentType, toggleCard, children, ...rest }) => {
  const handleExpandClick = () => {
    if (active) {
      toggleCard(null);
    } else {
      toggleCard(documentType);
    }
  };

  return (
    <CollapsibleCard {...rest} active={active} handleExpandClick={handleExpandClick} cardName={documentType}>
      {children}
    </CollapsibleCard>
  );
};

export default DocumentCard;
