import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
      paddingTop: theme.spacing(5),
    },
    logoContainer: {
      width: 236,
      height: 236,
      borderRadius: 236,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.common.white,
      boxShadow: '0px 0px 20px rgba(149, 164, 177, 0.200828)',
      margin: 20,
      '& > svg': {
        width: 131,
        height: 150,
        marginLeft: 10,
      },
      '& > img': {
        width: 131,
        height: 150,
      },
    },
    notNowButton: {
      textTransform: 'none',
      color: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 400,
      position: 'absolute',
      top: 16,
      right: 16,
      [theme.breakpoints.up('lg')]: {
        top: theme.spacing(10),
        display: 'none',
        visibility: 'hidden',
      },
    },

    button: {
      width: 285,
      height: 46,
      marginTop: theme.spacing(2),
    },
    signInLink: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: 'none',
      color: theme.palette.greyText.main,
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(18),
        fontWeight: 500,
      },
      '& > span > span': {
        color: theme.palette.primary.main,
        fontWeight: 700,
      },
    },
  }),
);
