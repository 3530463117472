import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    addAddressBtn: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    addressDateWarning: {
      color: theme.palette.error.main,
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    addressTitle: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(3),
    },
    addressDescription: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      marginBottom: theme.spacing(4),
    },
    addressNumberTitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      width: '100%',

      color: theme.palette.primary.main,
    },
    dividerContainer: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1),

      '& svg': {
        width: '100%',
      },
    },
    personalAddressContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(1),
    },
    deleteAddressButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 400,
      marginTop: -6,
      minHeight: 24,
      width: 61,
      height: 24,
      padding: 0,
      borderRadius: 6,
    },
    captchaContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
