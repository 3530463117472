import { FC, SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, FormHelperText, Box, CircularProgress, Button } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import ReactCodeInput from 'react-code-input';
import { ErrorOutline } from '@material-ui/icons';
import clsx from 'clsx';

import useCommonStyles from 'core/styles';
import { Layout } from 'components/Layout';
import { useAuth } from 'store/auth/hooks';
import { MessageAlert } from 'components/alerts';
import { SupportedCountryCode, MessageAlertStatus } from 'core/types';
import { useOnboard } from 'store/onboard/hooks';
import { Applicant } from 'store/onboard/types';
import analytics from 'core/services/analytics';
import routes from 'router/routes';
import { useAppState } from 'store/app/hooks';
import { usePrevious } from 'hooks';
import { mapCountryCodeToAppRegion } from 'utils';
import useStyles from './ConfirmCode.styles';

const Auth: FC = () => {
  const [codeSentSuccess, setCodeSentSuccess] = useState(false);
  const [resend, setResend] = useState(false);
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const {
    error: authError,
    loading: authLoading,
    clearError: clearAuthError,
    initAuth,
    email,
    getLeadApplications,
    accessToken,
  } = useAuth();
  const { loading: appLoading } = useAppState();
  const { loading: onboardLoading } = useOnboard();
  const prevAuthLoading = usePrevious(authLoading);

  useEffect(() => {
    analytics.track(routes.confirmCode);
  }, []);

  const onSuccess = useCallback(() => {
    if (!accessToken && resend) {
      setCodeSentSuccess(true);
    }
  }, [accessToken, resend]);

  const {
    company,
    applicant,
    createApplication,
    clearError: clearOnboardError,
    verifyEmail,
    error: onboardError,
  } = useOnboard(onSuccess);

  useEffect(() => {
    if (prevAuthLoading && !authLoading && resend) {
      setCodeSentSuccess(!authError);
    }
  }, [authError, authLoading, prevAuthLoading, resend]);

  useEffect(() => {
    return () => {
      clearAuthError();
      clearOnboardError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authSchema = yup.object({
    token: yup
      .string()
      .matches(/^\d{6}$/, t('pages.auth.inputs.token.error'))
      .length(6, t('pages.auth.inputs.token.error'))
      .required(t('pages.auth.inputs.token.required'))
      .nullable(),
  });

  const initialValues = {
    token: '',
  };

  const onSubmit = (values: typeof initialValues) => {
    if (codeSentSuccess) setCodeSentSuccess(false);

    if (!company.registered_number && !applicant.email) {
      getLeadApplications(values.token);
      return;
    }

    const { id: aId, ...applicantData } = applicant;

    const directorsData =
      company.directors?.reduce((dirs: Partial<Applicant>[], { id, ...rest }) => {
        if (id !== aId) {
          const addresses = rest.addresses.map((addr) => ({
            ...addr,
            country_code: addr.country_code ?? SupportedCountryCode.UNITED_KINGDOM,
          }));
          return [...dirs, { ...rest, addresses }];
        }
        return dirs;
      }, []) ?? [];

    createApplication({
      auth_code: values.token,
      company: {
        funding_amount: Number(company.funding_amount),
        funding_reasons: company.funding_reasons,
        formation_date: company.formation_date,
        monthly_revenue: Number(company.monthly_revenue),
        registered_name: company.registered_name,
        registered_number: company.registered_number,
        region: company.region,
        type: company.type,
        address: company.address,
        ein: company.ein,
        industry: company.industry,
      },
      applicant: {
        ...applicantData,
        addresses: applicant.addresses ?? [],
      },
      directors: directorsData,
      online_sales_revenue_percentage: company.online_sales_revenue_percentage ?? 0,
      region: mapCountryCodeToAppRegion(company.region as SupportedCountryCode),
    });
  };

  const resendCode = () => {
    setResend(true);
    if (email) {
      initAuth(email);
    } else if (company.registered_number && applicant.email) {
      verifyEmail({
        email: applicant.email,
        registeredNumber: company.registered_number,
        region: mapCountryCodeToAppRegion(company.region as SupportedCountryCode),
      });
    }
  };

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason !== 'clickaway') {
      setCodeSentSuccess(false);
    }
  };

  const loading = authLoading || appLoading || onboardLoading;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={authSchema} enableReinitialize>
      {({ values, setFieldValue, errors, handleSubmit, resetForm }) => {
        const withError = authError || onboardError || (values.token.length === 6 && errors.token);
        return (
          <Layout showSidebar={false} noError>
            <Box className={classes.container}>
              <Typography className={commonClasses.title}>{t('pages.auth.title')}</Typography>

              <Typography className={commonClasses.subtitle}>
                {t('pages.auth.inputs.token.label.line1')}{' '}
                <strong className={commonClasses.primaryText}>{t('pages.auth.inputs.token.label.line2')}</strong>{' '}
                {t('pages.auth.inputs.token.label.line3')}
              </Typography>

              <Form noValidate className={commonClasses.form} style={{ marginTop: 46 }}>
                <Grid container direction="column" spacing={4} className={classes.gridContainer}>
                  <Grid item className={classes.codeInputContainer}>
                    <ReactCodeInput
                      key={String(codeSentSuccess)}
                      name="token"
                      type="tel"
                      fields={6}
                      inputMode="tel"
                      className={clsx([classes.codeInput, withError && classes.codeInputError])}
                      onChange={(value) => {
                        setFieldValue('token', value);
                        if (/^\d{6}$/.test(value)) {
                          setTimeout(() => handleSubmit(), 350);
                        }
                      }}
                      value={values.token}
                      disabled={loading}
                    />

                    {withError && (
                      <FormHelperText className={clsx([commonClasses.error, classes.codeError])} error>
                        <ErrorOutline /> {errors.token || t('pages.auth.inputs.token.error')}
                      </FormHelperText>
                    )}

                    {loading && (
                      <Box className={classes.loaderContainer}>
                        <CircularProgress />
                      </Box>
                    )}

                    {(authError || onboardError) && (
                      <Button
                        id="resendCodeButton"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          resetForm();
                          resendCode();
                        }}
                      >
                        {t('pages.auth.buttons.resend')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Box>

            <MessageAlert
              status={MessageAlertStatus.SUCCESS}
              open={codeSentSuccess}
              handleClose={handleClose}
              message={t('pages.auth.alerts.resendSuccess')}
            />
          </Layout>
        );
      }}
    </Formik>
  );
};

export default Auth;
