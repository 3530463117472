import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 13,
      position: 'relative',
    },
    title: {
      padding: '40px 16px 8px',
      '& h2': {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
        fontWeight: 700,
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
          fontSize: theme.typography.pxToRem(20),
          lineHeight: theme.typography.pxToRem(30),
        },
      },
    },
    contentContainer: {
      display: 'flex',
      whiteSpace: 'pre-wrap',
      // alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 48px 16px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 32px 16px',
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
      },
      '& a': {
        fontWeight: 700,
      },
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.greyIcon.main}`,
      height: 35,
      '& button': {
        flex: 1,
        borderRadius: 0,
      },
      '& button:first-child': {
        borderRight: `1px solid ${theme.palette.greyIcon.main}`,
        fontWeight: 400,
      },
    },
    loderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  }),
);
