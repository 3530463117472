import { FC, ReactNode, useMemo } from 'react';
import { Box, Paper, Typography, Button, Collapse, Divider, Chip } from '@material-ui/core';
import { Add, Remove, CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { noop } from 'utils';
import useCommonStyles from 'core/styles';
import useStyles from './CollapsibleCard.styles';

export interface CollapsibleCardProps {
  Icon?: ReactNode;
  title: string;
  active: boolean;
  handleExpandClick: () => void;
  description?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  iconBackgroundColor?: string;
  onMainAction?: () => void;
  buttonTitle?: string;
  withDivider?: boolean;
  withHeaderChange?: boolean;
  withMainButton?: boolean;
  success?: boolean;
  mainActionDisabled?: boolean;
  cardName?: string;
  optional?: boolean;
}

const CollapsibleCard: FC<CollapsibleCardProps> = ({
  Icon,
  title,
  active,
  handleExpandClick,
  description,
  titleClassName,
  descriptionClassName,
  children,
  iconBackgroundColor = '#E6F0FD',
  onMainAction = noop,
  buttonTitle = 'Main Action',
  withDivider = false,
  withHeaderChange = true,
  withMainButton = true,
  success = false,
  mainActionDisabled = false,
  cardName = '',
  optional = false,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const iconClasses = useMemo(() => {
    const classesNames = [classes.iconContainer];
    if (withHeaderChange && active) {
      classesNames.push(classes.iconContainerExpanded);
      if (success) {
        classesNames.push(classes.iconSuccessExpanded);
      }
    }
    return clsx(classesNames);
  }, [
    classes.iconContainer,
    classes.iconContainerExpanded,
    classes.iconSuccessExpanded,
    success,
    withHeaderChange,
    active,
  ]);

  const titleClasses = useMemo(() => {
    if (active) {
      return clsx([classes.title, classes.titleExpanded, titleClassName]);
    }
    return clsx([classes.title, titleClassName]);
  }, [active, classes.title, classes.titleExpanded, titleClassName]);

  return (
    <Paper className={classes.container} elevation={0}>
      <Box className={classes.contentContainer}>
        <Box className={iconClasses} bgcolor={iconBackgroundColor}>
          {!success && <>{Icon}</>}

          {success && <CheckCircle className={classes.checkIcon} />}
        </Box>

        <Box className={classes.infoContainer}>
          <Typography className={titleClasses}>
            {title}{' '}
            {optional && (
              <Chip size="small" label={t('global.optional')} className={commonClasses.chip} component="span" />
            )}
          </Typography>

          {!active && (
            <Typography className={clsx([classes.description, descriptionClassName])}>{description}</Typography>
          )}
        </Box>

        <Button className={classes.button} onClick={handleExpandClick} id={`toggleCardButton-${cardName}`}>
          {active ? <Remove /> : <Add />}
        </Button>
      </Box>

      <Collapse in={active} timeout="auto">
        <Box className={classes.collapsibleContent}>
          {withDivider && <Divider className={classes.divider} />}

          {children}

          {withMainButton && !success && (
            <Box className={classes.actionButtonContainer}>
              <Button
                onClick={onMainAction}
                variant="outlined"
                color="primary"
                fullWidth
                disabled={mainActionDisabled}
                id={`mainButton-${cardName}`}
              >
                {buttonTitle}
              </Button>
            </Box>
          )}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default CollapsibleCard;
