import { FC, ReactNode } from 'react';
import { Fade, Box, Typography, CircularProgress, Container } from '@material-ui/core';

import useCommonStyles from 'core/styles';
import useStyles from './Loader.styles';

interface LoaderProps {
  visible: boolean;
  imgSrc?: string;
  title?: string;
  description?: string | ReactNode;
  timeout?: number;
}

const Loader: FC<LoaderProps> = ({ visible, imgSrc, title, description, timeout }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <Fade in={visible} timeout={timeout} data-testid="bs-layout-loader">
      <Box className={classes.container}>
        <Container className={classes.contentContainer} maxWidth="sm">
          {imgSrc ? (
            <img src={imgSrc} alt="Loading" className={classes.image} />
          ) : (
            <Box className={classes.image}>
              <svg width="300" height="300">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" stopColor="#DFEFFF" />
                  <stop offset="50%" stopColor="#99CCFF" />
                  <stop offset="100%" stopColor="#007AFF" />
                </linearGradient>
              </svg>
              <CircularProgress
                size={224}
                thickness={2.2}
                classes={{ circle: classes.circle, root: classes.circleRoot }}
              />
            </Box>
          )}

          {title && <Typography className={commonClasses.title}>{title}</Typography>}

          {description && <Typography className={commonClasses.subtitle}>{description}</Typography>}
        </Container>
      </Box>
    </Fade>
  );
};

export default Loader;
