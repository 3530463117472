import moment from 'moment';
import i18n from 'i18next';

import { Company } from 'store/onboard/types';
import { CompanyTypes, SupportedCountryCode } from 'core/types';
import yup from './yup-extended';

export const websiteRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const httpifyURL = (url: string): string => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

export type FormValues = Omit<Partial<Company>, 'vat_registration_date' | 'trading_from_date' | 'sic_codes'> & {
  vat_registered: boolean;
  vat_registration_date: Date | null;
  trading_from_date: Date | null;
  sic_codes: string;
};

export const formSchema = yup.object({
  trading_name: yup.string().nullable(),
  registered_name: yup.string().required(i18n.t('pages.companyInfo.inputs.registered_name.required')).nullable(),
  registered_number: yup.string().required(i18n.t('pages.companyInfo.inputs.registered_number.required')).nullable(),
  type: yup.string().required(i18n.t('pages.companyInfo.inputs.type.required')).nullable(),
  region: yup.string().required(i18n.t('pages.companyInfo.inputs.region.required')).nullable(),
  website: yup.string().matches(websiteRegExp, i18n.t('pages.companyInfo.inputs.website.valid')).nullable(),
  formation_date: yup.string().required(i18n.t('pages.companyInfo.inputs.formation_date.required')).nullable(),
  trading_from_date: yup.date().required(i18n.t('pages.companyInfo.inputs.trading_from_date.required')).nullable(),
  vat_registered: yup.boolean(),
  vat_number: yup.string().nullable(),
  vat_registration_date: yup.date().nullable(),
  sic_codes: yup.string().nullable(),
});

export const getInitialValues = (company: Company): FormValues => {
  let trading_from_date = null;
  if (company.trading_from_date) trading_from_date = moment(company.trading_from_date).toDate();
  if (company.formation_date) trading_from_date = moment(company.formation_date).toDate();

  return {
    id: company.id,
    trading_name: company.trading_name,
    registered_name: company.registered_name,
    registered_number: company.registered_number,
    type: company.type || CompanyTypes.LTD,
    region: company.region || SupportedCountryCode.UNITED_KINGDOM,
    website: company.website,
    formation_date: company.formation_date,
    trading_from_date,
    vat_registered: company.vat_registered || !!company.vat_number || !!company.vat_registration_date,
    vat_number: company.vat_number,
    vat_registration_date: company.vat_registration_date ? moment(company.vat_registration_date).toDate() : null,
    sic_codes: company.sic_codes.join(', '),
  };
};
