import {
  CompanyTypes,
  CompanyDocumentType,
  FundingReasons,
  AddressStatus,
  AreaPhoneCode,
  ApplicationRegion,
  CompanyIndustry,
} from 'core/types';
import { CCJType } from 'store/onboard/types';
import { mapApplicationRegionToLabel, mapCompanyDocumentTypeToLabel, toTitleCase } from 'utils';

export const averageMonthlyRevenueOptions = [
  { label: 'less than £1,000', value: 999.0 },
  { label: '£1,000+', value: 1000.0 },
  { label: '£5,000+', value: 5000.0 },
  { label: '£10,000+', value: 10000.0 },
  { label: '£25,000+', value: 25000.0 },
  { label: '£50,000+', value: 50000.0 },
  { label: '£100,000+', value: 100000.0 },
  { label: '£150,000+', value: 150000.0 },
  { label: '£250,000+', value: 250000.0 },
];

export const operatingTimeOptions = [
  { label: '6-12 months', value: '6-12m' },
  { label: '1-2 years', value: '1-2y' },
  { label: '3-4 years', value: '3-4y' },
  { label: '>5 years', value: '>5y' },
];

export const fundingReasonOptions = [
  { value: FundingReasons.DAILY_OPERATIONS, label: 'Daily operations' },
  { value: FundingReasons.PURCHASING_STOCK, label: 'Purchasing stock' },
  { value: FundingReasons.LOAN_REFINANCE, label: 'Loan refinance' },
  { value: FundingReasons.MARKETING, label: 'Marketing' },
  { value: FundingReasons.HIRING, label: 'Hiring' },
  { value: FundingReasons.PAYING_TAX_BILL, label: 'Paying a tax bill' },
  { value: FundingReasons.PURCHASING_ASSET, label: 'Purchase company asset(s)' },
  { value: FundingReasons.DEBT_CONSOLIDATION, label: 'Consolidate debts' },
  { value: FundingReasons.WORKING_CAPITAL, label: 'Access working capital' },
  { value: FundingReasons.BUYING_PROPERTY, label: 'Buying a property' },
  { value: FundingReasons.OTHER, label: 'Other' },
];

export const onlineSalesOptions = [
  { value: 0, label: '0%' },
  { value: 24, label: '< 25%' },
  { value: 26, label: '25% - 50%' },
  { value: 51, label: '50% - 75%' },
  { value: 76, label: '75% and more' },
];

export const businessTypeOptions = [
  { value: CompanyTypes.LTD, label: 'Limited Company' },
  { value: CompanyTypes.LLP, label: 'Limited Liability Partnership' },
  { value: CompanyTypes.LLC, label: 'Limited Liability Company' },
  { value: CompanyTypes.PLC, label: 'Public Limited Company' },
  { value: CompanyTypes.SOLE_TRADER, label: 'Sole Trader' },
  { value: CompanyTypes.LP, label: 'Limited Partnership' },
  { value: CompanyTypes.EEIG, label: 'European Economic Interest Group' },
  { value: CompanyTypes.NI, label: 'Northern Ireland' },
  { value: CompanyTypes.NIO, label: 'Northern Ireland Other' },
  { value: CompanyTypes.OC, label: 'Oversea Company' },
  { value: CompanyTypes.OTHER, label: 'Other' },
];

export const areaCodeOptions = [
  { label: '+44', value: AreaPhoneCode.GB },
  // { label: '+1', value: '+1' },
];

export const residentialStatusOptions = [
  { value: '', label: '' },
  { value: AddressStatus.TENANT, label: 'Tenant' },
  { value: AddressStatus.OWNER_WITH_MORTGAGE, label: 'Homeowner with mortgage' },
  { value: AddressStatus.OWNER_NO_MORTGAGE, label: 'Homeowner without mortgage' },
  { value: AddressStatus.RENT_FREE, label: 'Living with friends or family' },
];

export const currentAddressOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export const allowedFileTypes = [
  {
    mimeType: 'application/msword',
    name: 'Microsoft Word',
    extension: '.doc',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessing',
    name: 'Microsoft Word (OpenXML)',
    extension: '.docx',
  },
  {
    mimeType: 'image/png',
    name: 'PNG Image',
    extension: '.png',
  },
  {
    mimeType: 'image/jpeg',
    name: 'JPEG Image',
    extension: '.jpg',
  },
  {
    mimeType: 'image/svg+xml',
    name: 'SVG Image',
    extension: '.svg',
  },
  {
    mimeType: 'text/csv',
    name: 'Comma-separated values (CSV)',
    extension: '.csv',
  },
  {
    mimeType: 'application/pdf',
    name: 'Adobe Portable Document Format (PDF)',
    extension: ' .pdf',
  },
  {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    name: 'Microsoft Excel',
    extension: '.xlsx',
  },
  {
    mimeType: 'application/vnd.ms-excel', // .xls (older Excel format)
    name: 'Microsoft Excel',
    extension: ' .xls',
  },
];

export const minFundingAmount = 1000;
export const maxFundingAmount = 25000000;

export const ccjsOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export const ccjTypeOptions = [
  { value: CCJType.ACTIVE, label: 'Active' },
  { value: CCJType.HISTORIC, label: 'Historic' },
];

export const vatRegisteredOptions = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export const additionalDocumentTypes = [CompanyDocumentType.VAT_RETURN, CompanyDocumentType.IDENTITY_DOCUMENT];

export const additionalDocumentOptions = additionalDocumentTypes.map((type) => ({
  value: type,
  label: mapCompanyDocumentTypeToLabel(type),
}));

export const applicationRegionOptions = Object.values(ApplicationRegion).map((region) => ({
  label: mapApplicationRegionToLabel(region),
  value: region,
}));

export const companyIndustryOptions = [{ label: '', value: '' }].concat(
  Object.values(CompanyIndustry).map((industry) => ({
    label: toTitleCase(industry.replace(/_/g, ' ')),
    value: industry,
  })),
);
