import { FC, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  StepConnector,
  withStyles,
  Theme,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';
import useStyles, { useStepLabelStyles, useStepIconStyles } from './Sidebar.styles';
import { appSteps } from '../../core/constants';

const StepConnectorComponent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    marginLeft: 10,
    height: 20,
    overflow: 'hidden',
    opacity: 0.5,
  },
  active: {
    height: 40,
    '& $line': {
      height: 40,
      borderColor: theme.palette.primary.main,
    },
    opacity: 1,
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  disabled: {
    '& $line': {
      borderColor: theme.palette.greyText.main,
    },
  },
  line: {
    borderColor: theme.palette.common.black,
    height: 20,
  },
}))(StepConnector);

const StepIconComponent: FC<StepIconProps> = ({ active, completed }) => {
  const classes = useStepIconStyles();

  return (
    <Box
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckCircle className={classes.completed} />
      ) : (
        <Box className={clsx([classes.circle, active && classes.circleActive])} />
      )}
    </Box>
  );
};

const Sidebar: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const stepClasses = useStepLabelStyles();
  const location = useLocation();

  useEffect(() => {
    const currentStep = appSteps.find((step) => step.route === location.pathname);
    if (currentStep) {
      setActiveStep(currentStep.step);
    }
  }, [location.pathname]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Your Application</Typography>

      <Stepper activeStep={activeStep} orientation="vertical" connector={<StepConnectorComponent />}>
        {appSteps.map(({ label }) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIconComponent} classes={stepClasses}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Sidebar;
