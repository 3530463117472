import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      fontWeight: 700,
    },
    offerSection: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    offerSectionTitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      flex: 1,
      marginRight: 15,
    },
    offerSectionValueContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: '100%',
      flexDirection: 'column',
    },
    offerSectionValue: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      width: 'fit-content',
      textAlign: 'right',
    },
    highlightedValue: {
      borderBottom: `1px dashed ${theme.palette.common.black}`,
      lineHeight: theme.typography.pxToRem(20),
      '& svg': {
        fontSize: theme.typography.pxToRem(15),
        marginBottom: -2,
      },
    },
    tooltipMessageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignContent: 'center',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
        visibility: 'hidden',
      },
    },
    tooltipMessage: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      textAlign: 'right',
      '& svg': {
        fontSize: theme.typography.pxToRem(13),
        marginBottom: -2,
      },
    },
  }),
);
