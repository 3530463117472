import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Payment } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import gatheringOffersSvg from 'assets/icons/gatheringOffers.svg';
import { AlertDialog, MessageAlert } from 'components/alerts';
import { BankDocument, DocumentCard, DocumentUpload } from 'components/documents';
import { CustomSlider, CustomTextField } from 'components/inputs';
import { Layout } from 'components/Layout';
import { Loader } from 'components/Loader';
import { minFundingAmount } from 'core/constants';
import analytics from 'core/services/analytics';
import useCommonStyles from 'core/styles';
import { ApplicationRegion, CompanyDocumentType, EcommerceProviders, MessageAlertStatus } from 'core/types';
import routes from 'router/routes';
import { useOnboard } from 'store/onboard/hooks';
import { getRequiredDocTranslationKey } from 'utils';
import { useAuth } from 'store/auth/hooks';
import { useAppState } from 'store/app/hooks';
import { AmountNumberInput } from 'components/AmountNumberInput';
import { parseMoney } from 'utils/currency';
import { IntegrationsSection } from '../AdditionalInfo/components';
import useStyles from './CustomiseGoal.styles';

const ENABLED_PROVIDERS = [EcommerceProviders.AMAZON, EcommerceProviders.STRIPE];

const CustomiseGoal: FC = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CompanyDocumentType[]>([
    CompanyDocumentType.BANK_STATEMENT,
    CompanyDocumentType.PAYMENT_DATA,
  ]);
  const [docError, setDocError] = useState<string | undefined>();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const {
    company,
    prequalifyResult,
    additionalInfo,
    documentUploadSuccess,
    setDocAlert,
    gatherOffers,
    setSuccess,
  } = useOnboard(undefined, () => setLoading(false));
  const { t } = useTranslation();
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const history = useHistory();
  const { adminEditMode } = useAuth();
  const { region: appRegion } = useAppState();

  useEffect(() => {
    analytics.track(routes.customiseGoal);

    return () => setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (additionalInfo[CompanyDocumentType.BANK_STATEMENT].length > 0) {
      setDocError(undefined);
    }
  }, [additionalInfo]);

  const maxValue = useMemo(() => {
    let maxAmountValue = prequalifyResult?.credit_limit || 0;
    if (maxAmountValue < minFundingAmount) maxAmountValue = minFundingAmount;
    return maxAmountValue;
  }, [prequalifyResult]);

  const fundingAmount = company.funding_amount || 0;

  const minValue = useMemo(() => {
    let min = minFundingAmount;
    if (min > fundingAmount) {
      min = fundingAmount;
    }
    return min;
  }, [fundingAmount]);

  const companyInfoSchema = yup.object({
    funding_amount: yup
      .number()
      .min(minValue, ({ min }) => t('pages.customiseGoal.inputs.funding_amount.min', { min: parseMoney(min) }))
      .max(maxValue, ({ max }) => t('pages.customiseGoal.inputs.funding_amount.max', { max: parseMoney(max) }))
      .required(t('pages.customiseGoal.inputs.funding_amount.required'))
      .nullable(),
  });

  const initialValues = {
    funding_amount: Math.min(fundingAmount, maxValue),
  };

  const onSubmit = (values: typeof initialValues) => {
    if (!company.id) return;

    if (!adminEditMode) {
      setConfirmVisible(false);
      setLoading(true);
    }

    gatherOffers({ funding_amount: Number(values.funding_amount) });
  };

  const toggleCard = (docType: CompanyDocumentType | null) => {
    setSelectedCard((prevState) => {
      if (!docType) {
        return [...prevState.filter((dT) => dT !== docType)];
      }
      return [...prevState, docType];
    });
  };

  const handleClose = useCallback(
    (event?: SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') return;
      setDocAlert(false);
    },
    [setDocAlert],
  );

  const onNext = (submit: () => void) => {
    if (additionalInfo[CompanyDocumentType.BANK_STATEMENT].length === 0) {
      const errorMessage = t(getRequiredDocTranslationKey(CompanyDocumentType.BANK_STATEMENT));
      setDocError(errorMessage);
      setSelectedCard((prevState) => [
        ...prevState.filter((dT) => dT !== CompanyDocumentType.BANK_STATEMENT),
        CompanyDocumentType.BANK_STATEMENT,
      ]);
      return;
    }

    if (adminEditMode) {
      submit();
      return;
    }

    setConfirmVisible(true);
  };

  const onGoBack = () => {
    history.push(routes.fundingGoal);
  };

  const onGoForward = () => {
    history.push(routes.offers);
  };

  const hasPaymentData = additionalInfo[CompanyDocumentType.PAYMENT_DATA].length > 0;

  const hasOnlineSales = !!company.online_sales_revenue_percentage;

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={companyInfoSchema} enableReinitialize>
        {({ values, setFieldValue, handleSubmit }) => (
          <Layout
            headerTitle={t('pages.customiseGoal.header')}
            onMainAction={() => onNext(handleSubmit)}
            mainButtonTitle={t('pages.customiseGoal.buttons.main')}
            loading={loading}
            Loader={
              <Loader
                visible
                title={t('pages.customiseGoal.loader.title')}
                description={
                  <>
                    {t('pages.customiseGoal.loader.description.line1')}{' '}
                    <strong className={commonClasses.primaryText}>
                      {t('pages.customiseGoal.loader.description.line2')}
                    </strong>{' '}
                    {t('pages.customiseGoal.loader.description.line3')}
                  </>
                }
                imgSrc={gatheringOffersSvg}
              />
            }
            onGoBack={onGoBack}
            onGoForward={adminEditMode ? onGoForward : undefined}
            withBackButton={appRegion === ApplicationRegion.UK}
          >
            <Typography variant="h1" className={commonClasses.title2}>
              {t('pages.customiseGoal.title')}
            </Typography>

            <Typography className={commonClasses.subtitle2}>{t('pages.customiseGoal.description')}</Typography>

            {!loading && (
              <>
                <Box marginTop="40px" marginBottom="40px">
                  <BankDocument
                    active={selectedCard.includes(CompanyDocumentType.BANK_STATEMENT)}
                    toggleCard={toggleCard}
                    validationError={docError}
                  />

                  {hasOnlineSales && (
                    <DocumentCard
                      title={t('pages.additionalInfo.documentUploads.paymentData.title')}
                      description={t('pages.additionalInfo.documentUploads.paymentData.description')}
                      Icon={<Payment color="primary" />}
                      documentType={CompanyDocumentType.PAYMENT_DATA}
                      active={selectedCard.includes(CompanyDocumentType.PAYMENT_DATA)}
                      toggleCard={toggleCard}
                      withMainButton={false}
                      success={hasPaymentData}
                      optional
                    >
                      <Alert severity="info">{t('pages.additionalInfo.documentUploads.paymentData.description')}</Alert>
                      <DocumentUpload documentType={CompanyDocumentType.PAYMENT_DATA} multipleFiles />

                      <Box marginTop="10px">
                        <IntegrationsSection
                          title={t('global.actions.or')}
                          description={t('pages.customiseGoal.ecommerce.description')}
                          enabledProviders={ENABLED_PROVIDERS}
                        />
                      </Box>
                    </DocumentCard>
                  )}

                  <Form noValidate className={commonClasses.form}>
                    <Grid container direction="column" spacing={4}>
                      <Grid item className={classes.inputContainer}>
                        <Field
                          id="funding_amount"
                          fullWidth
                          component={CustomTextField}
                          autoFocus
                          name="funding_amount"
                          value={values.funding_amount}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('funding_amount', Number(event.target.value))
                          }
                          title={t('pages.customiseGoal.inputs.funding_amount.label')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputComponent: AmountNumberInput,
                          }}
                          className={classes.fundingAmountInput}
                          variant="outlined"
                          labelClassName={classes.inputLabel}
                        />
                      </Grid>

                      <Grid item className={classes.sliderContainer}>
                        {maxValue > values.funding_amount && (
                          <Alert severity="success">
                            {t('pages.customiseGoal.inputs.funding_amount.gtMaxMsg', {
                              maxValue: parseMoney(maxValue),
                            })}{' '}
                          </Alert>
                        )}

                        <CustomSlider
                          value={values.funding_amount}
                          onChange={(value: number) => setFieldValue('funding_amount', value)}
                          aria-label="funding amount slider"
                          min={minValue}
                          max={maxValue}
                          step={1000}
                          id="funding_amount_slider"
                          disabled={minValue >= maxValue}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
              </>
            )}

            <MessageAlert
              open={documentUploadSuccess}
              message={t('documents.upload.success')}
              handleClose={handleClose}
              status={MessageAlertStatus.SUCCESS}
              containerClassName={classes.successAlertContainer}
            />

            <AlertDialog
              open={!!confirmVisible}
              handleCancel={() => setConfirmVisible(false)}
              handleConfirm={handleSubmit}
              dialogContentTitle={t('pages.customiseGoal.title')}
              dialogContentText={t('pages.customiseGoal.makeApplicationMessage')}
              loading={loading}
            />
          </Layout>
        )}
      </Formik>
    </>
  );
};

export default CustomiseGoal;
