import { ApplicantLeadsList } from 'core/types';
import { apiCall } from 'http/index';
import { ReApplyResponse } from 'store/auth/types';

export const initAuth = async (email: string): Promise<null> =>
  apiCall({ method: 'post', url: 'auth/companies/init', data: { email: email.toLowerCase() } });

export const getLeadApplications = async (
  email: string,
  code: string,
): Promise<ApplicantLeadsList & { widget_token: string }> =>
  apiCall({ method: 'get', url: 'me/applications', params: { email, auth_code: code } });

export const signIn = async (appId: string, widgetToken: string): Promise<string> => {
  const data = await apiCall<{ access_token: string }>({
    method: 'post',
    url: 'auth/companies/jwt',
    data: { application_id: appId },
    headers: { Authorization: `Bearer ${widgetToken}` },
  });
  return data.access_token;
};

export const reApply = async (applicationId: string, widgetToken: string): Promise<ReApplyResponse> =>
  apiCall({
    method: 'post',
    url: `applications/reapply/${applicationId}`,
    headers: { Authorization: `Bearer ${widgetToken}` },
  });
