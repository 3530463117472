import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    sicCodeButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(1),
    },
    deleteSicCodeButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 400,
      marginTop: -6,
      minHeight: 24,
      width: 61,
      height: 24,
      padding: 0,
      borderRadius: 6,
    },
    addSicCodeBtn: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    inputLabel: {
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
  }),
);
