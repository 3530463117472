import { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import {
  startIdentityVerification as startIdentityVerificationApi,
  getVerificationSession as getVerificationSessionApi,
} from 'http/kyc';
import { useOnboard } from 'store/onboard/hooks';
import { useInterval } from 'hooks';
import routes from 'router/routes';
import { Loader } from 'components/Loader';
import { VerificationSessionStatus } from 'core/types';
import { useAuth } from 'store/auth/hooks';
import { openCalendly } from 'core/calendly';
import { businessScore } from 'core/constants';
import analytics from 'core/services/analytics';
import { useAppState } from 'store/app/hooks';
import useStyles from './Kyc.styles';

const pollingIntervalInMs = 15000;
const maxDefaultAttempts = 10;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);

const Kyc: FC = () => {
  const [stripeClient, setStripeClient] = useState<Stripe | null>(null);
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [kycError, setKycError] = useState<boolean | string>(false);
  const [tryCount, setTryCount] = useState(0);
  const [maxAttempts, setMaxAttempts] = useState(maxDefaultAttempts);
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { applicant } = useOnboard();
  const history = useHistory();
  const { setVerified, verified } = useAuth();
  const { customCalendlyLink } = useAppState();

  useEffect(() => {
    analytics.track(routes.kyc);
  }, []);

  const onVerificationSuccess = useCallback(() => {
    setSessionId(null);
    setVerified();
    setLoading(false);
  }, [setVerified]);

  const onVerificationFailure = useCallback((reason: string | boolean) => {
    setSessionId(null);
    setLoading(false);
    setKycError(reason);
  }, []);

  const getVerificationSession = useCallback(
    async (verificationSessionId?: string) => {
      try {
        const kycSessionId = verificationSessionId || sessionId;
        if (!kycSessionId) return;

        const session = await getVerificationSessionApi(kycSessionId);

        if (session.status === VerificationSessionStatus.VERIFIED) {
          onVerificationSuccess();
        } else if (session.last_error) {
          onVerificationFailure(session.last_error.reason);
        }
      } catch (err) {
        onVerificationFailure((err as Error).message || true);
      }
    },
    [onVerificationFailure, onVerificationSuccess, sessionId],
  );

  useInterval(() => getVerificationSession(), sessionId ? pollingIntervalInMs : null);

  const getStripe = useCallback(async () => {
    const stripe = await stripePromise;
    setStripeClient(stripe);
  }, []);

  useEffect(() => {
    getStripe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startIdentityVerification = useCallback(async () => {
    try {
      if (!stripeClient) return;
      setLoading(true);

      const { client_secret, session_id, status, max_attempts } = await startIdentityVerificationApi();

      if (tryCount === 0) setMaxAttempts(max_attempts);
      setTryCount((prevCount) => prevCount + 1);

      if (status === VerificationSessionStatus.VERIFIED) {
        onVerificationSuccess();
        return;
      }

      const { error } = await stripeClient.verifyIdentity(client_secret);
      if (error) {
        onVerificationFailure(error.message || true);
        return;
      }

      setSessionId(session_id);
      await getVerificationSession(session_id);
    } catch (err) {
      setTryCount((prevCount) => prevCount + 1);
      onVerificationFailure((err as Error).message || true);
    }
  }, [getVerificationSession, onVerificationFailure, onVerificationSuccess, stripeClient, tryCount]);

  const clearError = () => setKycError(false);

  const onNext = useCallback(() => {
    history.push(routes.customiseGoal);
  }, [history]);

  const scheduleCall = useCallback(() => {
    openCalendly({
      url: customCalendlyLink ?? businessScore.calendly.brokers,
      prefill: {
        name: applicant.first_name ? `${applicant.first_name} ${applicant.last_name}` : '',
        email: applicant.email || '',
      },
    });
  }, [applicant.email, applicant.first_name, applicant.last_name, customCalendlyLink]);

  const onGoBack = useCallback(() => {
    history.push(routes.fundingGoal);
  }, [history]);

  const cannotVerify = (applicant.kyc_identity?.verification_attempts || 0) + tryCount >= maxAttempts;

  const verifyFailure = (tryCount > 1 && !verified) || cannotVerify;

  const description = useMemo(() => {
    if (verified) return t('pages.kyc.success');
    if (cannotVerify) return t('pages.kyc.cannotVerify');
    if (tryCount > 1 && !verified) return t('pages.kyc.failure');
    return t('pages.kyc.description');
  }, [cannotVerify, t, tryCount, verified]);

  return (
    <Layout
      showSidebar={false}
      onGoBack={onGoBack}
      showShapeBackground
      loading={loading}
      Loader={
        <Loader
          visible
          title={sessionId ? t('pages.kyc.verifying.title') : undefined}
          description={sessionId ? t('pages.kyc.verifying.description') : undefined}
        />
      }
      error={kycError}
      clearError={clearError}
    >
      <Box className={classes.container}>
        <Typography variant="h1" className={commonClasses.title}>
          {t('pages.kyc.title.identity')} {verified ? t('pages.kyc.title.verified') : t('pages.kyc.title.notVerified')}
        </Typography>

        <Typography className={commonClasses.subtitle}>{description}</Typography>

        {!cannotVerify && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={verified ? onNext : startIdentityVerification}
            disabled={!stripeClient}
          >
            {verified ? t('pages.kyc.buttons.continue') : t('pages.kyc.buttons.main')}
          </Button>
        )}

        {verifyFailure && (
          <Button variant="outlined" color="primary" className={classes.button} onClick={scheduleCall}>
            {t('pages.kyc.buttons.scheduleCall')}
          </Button>
        )}
      </Box>
    </Layout>
  );
};

export default Kyc;
