/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BSLogo } from 'assets/icons/businessScore.svg';
import { AlertDialog } from 'components/alerts';
import { Layout } from 'components/Layout';
import { Terms } from 'components/Terms';
import analytics from 'core/services/analytics';
import useCommonStyles from 'core/styles';
import { validateReCaptcha as validateReCaptchaApi } from 'http/onboard';
import routes from 'router/routes';
import { useAppState } from 'store/app/hooks';
import { useOnboard } from 'store/onboard/hooks';
import useStyles from './Home.styles';

interface HomeProps {
  onNotNow: () => void;
}

const Home: FC<HomeProps> = ({ onNotNow }) => {
  const [reCaptchaOpen, setReCaptchaOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const {
    widget,
    logoUrl,
    partnerName,
    captchaEnabled,
    exitEnabled,
    homeTitle,
    homeSubTitle,
    termsAgreed,
  } = useAppState();
  const history = useHistory();
  const { setError } = useOnboard();

  useEffect(() => {
    analytics.track(routes.home);
  }, []);

  const closeWidget = () => {
    if (widget) {
      onNotNow();
    }
  };

  const goToSignIn = () => {
    history.push(routes.signIn);
  };

  const onGetStarted = () => {
    if (captchaEnabled) {
      setReCaptchaOpen(true);
    } else {
      history.push(routes.preApply);
    }
  };

  const onChangeCaptcha = async (reCaptcha: string | null) => {
    if (reCaptcha) {
      setLoading(true);
      try {
        await validateReCaptchaApi(reCaptcha);
        history.push(routes.preApply);
      } catch (error) {
        reCaptchaRef.current?.reset();
        setLoading(false);
        setError((error as Error)?.message || true);
      }
    }
  };

  const GetSubTitle = () => {
    if (homeSubTitle) {
      return <Typography className={commonClasses.subtitle}>{homeSubTitle}</Typography>;
    }

    if (partnerName && !homeSubTitle) {
      return (
        <Typography className={commonClasses.subtitle}>
          <strong className={commonClasses.primaryText}>{partnerName}</strong>{' '}
          {t('pages.home.partnerDescription.line1')}{' '}
          <strong className={commonClasses.primaryText}>{t('pages.home.partnerDescription.line2')}</strong>{' '}
          {t('pages.home.partnerDescription.line3')}
        </Typography>
      );
    }

    return <Typography className={commonClasses.subtitle}>{t('pages.home.description')}</Typography>;
  };

  return (
    <Layout withBackButton={false} showSidebar={false} showBackground>
      <Box className={classes.container}>
        <Paper className={classes.logoContainer}>{logoUrl ? <img src={logoUrl} alt="Logo" /> : <BSLogo />}</Paper>

        <Typography variant="h1" className={commonClasses.title}>
          {homeTitle ?? t('pages.home.title')}
        </Typography>

        <GetSubTitle />

        <Terms />

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onGetStarted}
          disabled={!termsAgreed}
          id="getStartedButton"
        >
          {t('pages.home.getStarted')}
        </Button>

        <Button variant="text" onClick={goToSignIn} className={classes.signInLink} id="goSignInButtton">
          {t('pages.home.signInLink.line1')} &nbsp; <span>{t('pages.home.signInLink.line2')}</span>
        </Button>
      </Box>

      {exitEnabled && (
        <Button onClick={closeWidget} className={classes.notNowButton} id="closeWidgetButton">
          {t('pages.home.notNow')}
        </Button>
      )}

      <AlertDialog open={reCaptchaOpen} loading={loading}>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY as string}
          onChange={onChangeCaptcha}
          ref={reCaptchaRef}
        />
      </AlertDialog>
    </Layout>
  );
};

export default Home;
