import { FC, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import analytics from 'core/services/analytics';
import routes from 'router/routes';
import useStyles from './InvalidKey.styles';

const InvalidKey: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    analytics.track(routes.invalidKey);
  }, []);

  return (
    <Layout showSidebar={false} withBackButton={false} showShapeBackground>
      <Box className={classes.container}>
        <Typography variant="h1" className={commonClasses.title}>
          {t('pages.invalidKey.title')}
        </Typography>

        <Typography className={commonClasses.subtitle}>{t('pages.invalidKey.description')}</Typography>
      </Box>
    </Layout>
  );
};

export default InvalidKey;
