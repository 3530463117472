import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createMerchantAuthConnectionUrl } from 'http/integrations';
import { useOnboard } from 'store/onboard/hooks';
import { LoadingButton } from 'components/buttons';
import { EcommerceProviders } from 'core/types';
import analytics from 'core/services/analytics';
import { useAppState } from 'store/app/hooks';
import { ReactComponent as Amazon } from 'assets/icons/amazon.svg';
import { ReactComponent as Stripe } from 'assets/icons/stripe.svg';
import useStyles from './IntegrationButton.styles';

export const getProviderIcon = (provider: EcommerceProviders): JSX.Element | null => {
  switch (provider) {
    case EcommerceProviders.AMAZON:
      return <Amazon />;
    case EcommerceProviders.STRIPE:
      return <Stripe />;
    default:
      return null;
  }
};

interface IntegrationButtonProps {
  provider: EcommerceProviders;
}

const IntegrationButton: FC<IntegrationButtonProps> = ({ provider }) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { setError, company } = useOnboard();
  const { application_id } = useAppState();

  const handleIntegration = async () => {
    analytics.track('connect-integration', { application_id, provider });
    try {
      const validProvider = company.providers?.find(
        (coProvider) => coProvider.provider === provider && Boolean(coProvider.link_url),
      );
      if (validProvider) {
        window.open(validProvider.link_url, '_blank');
        return;
      }
      setLoading(true);
      const linkUrl = await createMerchantAuthConnectionUrl(provider);
      setLoading(false);
      window.open(linkUrl, '_blank');
    } catch (error) {
      setError((error as Error)?.message || true);
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      onClick={handleIntegration}
      variant="outlined"
      color="primary"
      fullWidth
      startIcon={getProviderIcon(provider)}
      className={classes.button}
      disabled={loading}
      loading={loading}
    >
      {t(`pages.additionalInfo.integrations.ecommerce.${provider.toLowerCase()}`)}
    </LoadingButton>
  );
};

export default IntegrationButton;
