import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      '& .MuiFormControl-marginNormal': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    icon: {
      width: 14,
      height: 14,
      color: '#687C8D',
      position: 'absolute',
      top: 24,
      right: 5,
    },
  }),
);
