import { FC, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useCommonStyles from 'core/styles';
import { Layout } from 'components/Layout';
import routes from 'router/routes';
import { parseMoney } from 'utils/currency';
import { useOnboard } from 'store/onboard/hooks';
import analytics from 'core/services/analytics';
import { LoadingButton } from 'components/buttons';
import { ApplicationRegion, VerificationSessionStatus } from 'core/types';
import { useAuth } from 'store/auth/hooks';
import { useAppState } from 'store/app/hooks';
import useStyles from './FundingResult.styles';

const FundingResult: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { prequalifyResult, company, applicant, loading, getCompanyInfo } = useOnboard();
  const { verified, adminEditMode } = useAuth();
  const { kycEnabled, region: appRegion } = useAppState();

  useEffect(() => {
    analytics.track(routes.fundingResult);
  }, []);

  const startApplication = () => {
    if (appRegion !== ApplicationRegion.UK) {
      history.push(routes.customiseGoal);
      return;
    }

    if (company.year_to_date_revenue) {
      const verifiedIdentity =
        applicant.kyc_identity?.session_status === VerificationSessionStatus.VERIFIED || verified;
      const nextRoute = kycEnabled && !verifiedIdentity ? routes.kyc : routes.customiseGoal;
      history.push(nextRoute);
    } else if (applicant.phone_number) {
      history.push(routes.fundingGoal);
    } else if (adminEditMode) {
      history.push(routes.companyInfo);
    } else {
      getCompanyInfo(company.registered_number as string);
    }
  };

  const onGoBack = () => history.push(routes.fundingGoal);

  const renderDescription = () => {
    if (prequalifyResult?.approved) {
      return (
        <Typography className={commonClasses.subtitle}>
          {t('pages.fundingResult.approved.description.line1')}{' '}
          <strong className={commonClasses.primaryText}>{parseMoney(prequalifyResult.credit_limit)}</strong>
          {t('pages.fundingResult.approved.description.line2')}
        </Typography>
      );
    }

    return <Typography className={commonClasses.subtitle}>{t('pages.fundingResult.rejected.description')}</Typography>;
  };

  const title = prequalifyResult?.approved
    ? t('pages.fundingResult.approved.title')
    : t('pages.fundingResult.rejected.title');

  return (
    <Layout
      showSidebar={false}
      showShapeBackground
      withBackButton={
        (adminEditMode || (prequalifyResult?.approved && !!applicant.phone_number)) &&
        appRegion !== ApplicationRegion.USA
      }
      onGoBack={onGoBack}
    >
      <Box className={classes.container}>
        <Typography variant="h1" className={commonClasses.title}>
          {title}
        </Typography>

        {renderDescription()}

        {prequalifyResult?.approved && (
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            className={classes.button}
            onClick={startApplication}
            loading={loading}
            id="continueAppButton"
          >
            {t('pages.fundingResult.approved.button')}
          </LoadingButton>
        )}
      </Box>
    </Layout>
  );
};

export default FundingResult;
