import { useActionCreator } from 'hooks';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { setError, getFaqs, setFaqsError } from './actions';
import { GlobalHookReturn } from './types';

export const useGlobalState = (): GlobalHookReturn => {
  const globalState = useSelector((state: RootState) => state.global, shallowEqual);

  return {
    ...globalState,
    setError: useActionCreator(setError),
    getFaqs: useActionCreator(getFaqs),
    setFaqsError: useActionCreator(setFaqsError),
  };
};
