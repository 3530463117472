import { OfferStatus } from 'store/offers/types';

export const DISPLAYABLE_OFFERS: Record<OfferStatus, boolean> = {
  [OfferStatus.OFFERED]: true,
  [OfferStatus.ACCEPTED]: true,
  [OfferStatus.ADDITIONAL_DETAILS_REQUESTED]: true,
  [OfferStatus.PENDING]: false,
  [OfferStatus.DECLINED]: false,
  [OfferStatus.DEFERRED]: false,
  [OfferStatus.CANCELLED]: false,
};
