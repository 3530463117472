import React from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete'; // Replace with the actual import
import { FieldProps } from 'formik'; // Replace with the actual import
import { CustomInputError, CustomLabel } from 'components/inputs';
import { Button, InputLabel, Typography } from '@material-ui/core';
import HomeIcon from 'assets/icons/home.svg';
import SearchIcon from 'assets/icons/search.svg';
import { AddressTypes, ApplicationRegion } from 'core/types';
import { Address } from 'store/onboard/types';
import useStyles from './GooglePlacesCustomTextField.styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface GooglePlacesAutocompleteFieldProps {
  id: string;
  // Add any other props you need for styling
  error: boolean;
  title: string;
  tooltipMessage: string;
  helperText?: string;
  placeholder?: string;
  debounce?: number;
  onChange: (addresses?: Partial<Address> | null) => void;
  language: string;
  region: ApplicationRegion;
  caption?: string;
  captionOnClick: () => void;
}

const gpKey = process.env.REACT_APP_GOOGLE_PLACES_KEY as string;

const customConfigStyle = () => {
  return {
    control: (provided: any) => ({
      ...provided,
      border: 0,
      backgroundColor: '#f6f7f9',
      boxShadow: 'none !important',
      fontFamily: 'Roboto, sans-serif',
      position: 'relative',
      '&::before': {
        content: `'I'`,
        fontSize: '0',
        width: '24px',
        height: '24px',
        backgroundColor: 'transparent',
        backgroundImage: `url(${SearchIcon})`,
        filter: 'invert(80%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '15px',
        backgroundPosition: 'center',
        marginLeft: '13px',
        display: 'block',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    input: (provided: any) => ({
      ...provided,
      '&:focus': {
        borderColor: 'red',
        borderWidth: 0,
        backgroundColor: 'red !important',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#f6f7f9',
      fontWeight: 'bolder',
      marginTop: 0,
      borderTop: '1px solid #ccc',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: 'none',
    }),
    menuList: (provided: any) => ({
      ...provided,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      fontFamily: 'Roboto, sans-serif',
    }),
    option: (provided: any) => ({
      ...provided,
      borderBottom: '1px solid #edeff3',
      fontFamily: 'Roboto, sans-serif',
      display: 'flex',
      position: 'relative',
      paddingLeft: '40px',
      '&:hover': {
        backgroundColor: '#eeeeee',
      },
      '&::before': {
        position: 'absolute',
        top: '3px',
        left: '13px',
        content: `'I'`,
        fontSize: '0',
        width: '24px',
        height: '24px',
        backgroundColor: 'transparent',
        backgroundImage: `url(${HomeIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '15px',
        backgroundPosition: 'center',
        marginRight: '10px',
        display: 'block',
      },
    }),
  };
};

const GooglePlacesAutocompleteField: React.FC<GooglePlacesAutocompleteFieldProps & FieldProps> = ({
  field,
  form,
  id,
  error,
  helperText,
  title,
  tooltipMessage,
  placeholder,
  onChange,
  region,
  language,
  debounce,
  caption,
  captionOnClick,
}) => {
  const { name } = field;

  const generateAddress = (addressDetails: any) => {
    const addressComponents = addressDetails.address_components;
    const formattedAddress = addressDetails.formatted_address;

    const parsedValues: Partial<Address> = {
      house_number: null,
      line_1: null,
      line_2: null,
      city: null,
      county: null,
      postcode: null,
      full: '',
    };

    addressComponents.forEach((component: { types: string[]; long_name: string }) => {
      const { types } = component;

      switch (true) {
        case types.includes(AddressTypes.PREMISE):
          parsedValues.house_number = `${component.long_name}, `;
          break;

        case types.includes(AddressTypes.STREET_NUMBER):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.ROUTE):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.LANDMARK):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.NEIGHBORHOOD):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.SUBLOCALITY_LEVEL_3):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.SUBLOCALITY_LEVEL_2):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.SUBLOCALITY_LEVEL_1):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.POSTAL_TOWN):
          if (parsedValues.line_1) {
            parsedValues.line_1 += `, ${component.long_name}`;
          } else parsedValues.line_1 = component.long_name;
          break;

        case types.includes(AddressTypes.LOCALITY):
          parsedValues.city = component.long_name;
          break;

        case types.includes(AddressTypes.ADMINISTRATIVE_AREA_LEVEL_2):
          parsedValues.county = component.long_name;
          break;

        case types.includes(AddressTypes.ADMINISTRATIVE_AREA_LEVEL_1):
          if (parsedValues.county) {
            parsedValues.county += ` / ${component.long_name}`;
          } else parsedValues.county = component.long_name;
          break;
        case types.includes(AddressTypes.POSTAL_CODE):
          parsedValues.postcode = component.long_name;
          break;
        default:
          break;
      }
    });
    if (formattedAddress) parsedValues.full = formattedAddress;
    return parsedValues;
  };

  const handlePlaceSelect = async (place: any) => {
    if (place !== null) {
      const results = await geocodeByAddress(place?.value?.description);
      if (place !== null && results && results.length > 0) {
        const parsedAddress = generateAddress(results[0]);
        onChange(parsedAddress);
      }
    } else onChange(null);
  };

  const hasError = error || (form.touched[field.name] && !!form.errors[field.name]);

  const classes = useStyles();

  return (
    <>
      <InputLabel htmlFor={id}>
        <CustomLabel title={title} tooltipMessage={tooltipMessage} />
      </InputLabel>
      <GooglePlacesAutocomplete
        apiKey={gpKey}
        apiOptions={{ language, region }}
        debounce={debounce ?? debounce}
        selectProps={{
          isClearable: true,
          onChange: handlePlaceSelect,
          placeholder,
          styles: customConfigStyle(),
          name,
        }}
      />
      {caption && (
        <Button variant="text" onClick={captionOnClick}>
          <Typography variant="caption" align="right" className={classes.caption}>
            {caption}
          </Typography>
        </Button>
      )}

      {hasError && <CustomInputError message={(form.errors[field.name] || helperText) as string} />}
    </>
  );
};

export { GooglePlacesAutocompleteField };
