/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';

const environment = process.env.NODE_ENV as string;
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN as string;
const mixpanelEnabled = process.env.REACT_APP_MIXPANEL_ENABLED === 'true' && mixpanelToken;
const hjId = process.env.REACT_APP_HOTJAR_ID as string;
const hjSv = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION as string;
const hotjarEnabled = process.env.REACT_APP_HOTJAR_ENABLED === 'true' && hjId;

const env_check = environment === 'production';

const init = (): void => {
  if (!env_check) return;
  if (mixpanelEnabled) mixpanel.init(mixpanelToken);
  if (hotjarEnabled) hotjar.initialize(Number(hjId), Number(hjSv));
};

const identify = (id: string): void => {
  if (!env_check) return;
  if (mixpanelEnabled) mixpanel.identify(id);
  if (hotjarEnabled) hotjar.identify(id, {});
};

const alias = (id: string): void => {
  if (env_check && mixpanelEnabled) mixpanel.alias(id);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track = (name: string, props?: any): void => {
  if (env_check && mixpanelEnabled) mixpanel.track(name, props);
};

const people = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set: (props: any): void => {
    if (env_check && mixpanelEnabled) mixpanel.people.set(props);
  },
};

const reset = () => {
  if (env_check && mixpanelEnabled) mixpanel.reset();
};

export default { init, identify, alias, track, people, reset };
