import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { history } from 'router/service';
import routes from 'router/routes';
import { sortByDate } from 'utils';
import { acceptOffer as acceptOfferApi, getOffers as getOffersApi } from 'http/offers';
import { DISPLAYABLE_OFFERS } from 'core/constants';
import { AcceptOfferData, LenderOffer, OfferProductType, OffersState } from './types';
import { OnboardState } from '../onboard/types';

export const GET_OFFERS = 'offers/GET_OFFERS';
export const CLEAR_ERROR = 'offers/CLEAR_ERROR';
export const ACCEPT_OFFER = 'offers/ACCEPT_OFFER';
export const SET_SELECTED_OFFER_ID = 'offers/SET_SELECTED_OFFER_ID';

export const getOffers = createAsyncThunk<LenderOffer[], void, { state: { onboard: OnboardState } }>(
  GET_OFFERS,
  async (_, { getState }) => {
    const {
      onboard: { company },
    } = getState();

    if (company.id) {
      const offers = await getOffersApi(company.id);
      // store only offers that have rates
      return offers
        .reduce((acc: LenderOffer[], value: LenderOffer): LenderOffer[] => {
          const offer = { ...value, date: value.updated_at }; // for sort by date function
          if (DISPLAYABLE_OFFERS[offer.status]) {
            if (offer.product_type !== OfferProductType.REVENUE_BASED) {
              const ratesOffers =
                offer.rates.map((rate) => ({
                  ...offer,
                  rate_id: rate.rate_id,
                  loan_amount: rate.principal,
                  interest_rate: rate.interest_rate,
                  selected: rate.selected,
                  unique_id: rate.rate_id,
                  monthly_repayment: rate.monthly_repayment,
                  monthly_repayment_type: rate.monthly_repayment_type,
                  total_repayment: rate.total_repayment,
                })) ?? [];
              return [...acc, ...ratesOffers];
            }
            const revenueRepaymentOffers =
              offer.revenueRepayments.map((revenueRepayment) => ({
                ...offer,
                repayment_id: revenueRepayment.revenue_repayment_id,
                loan_amount: revenueRepayment.total_get,
                selected: revenueRepayment.selected,
                sweep: revenueRepayment.sweep,
                sweep_terms: revenueRepayment.sweep_terms,
                total_repayment: revenueRepayment.total_repayment,
                unique_id: revenueRepayment.revenue_repayment_id,
              })) ?? [];
            return [...acc, ...revenueRepaymentOffers];
          }
          return acc;
        }, [])
        .sort(sortByDate);
    }

    return [];
  },
);

export const clearError = createAction(CLEAR_ERROR);

export const acceptOffer = createAsyncThunk<void, string, { state: { onboard: OnboardState; offers: OffersState } }>(
  ACCEPT_OFFER,
  async (uniqueId: string, { getState }) => {
    const {
      onboard: { company },
      offers: { offers },
    } = getState();

    const selectedOffer = offers.find((offer) => offer.unique_id === uniqueId);
    if (company.id && selectedOffer) {
      const data: AcceptOfferData = {
        companyId: company.id,
        offerId: selectedOffer.id,
        amount: selectedOffer.loan_amount,
      };
      if (selectedOffer.product_type === OfferProductType.REVENUE_BASED) {
        data.repaymentId = selectedOffer.repayment_id;
      } else {
        data.rateId = selectedOffer.rate_id;
      }
      await acceptOfferApi(data);
      history.push({ pathname: routes.acceptedOffer, state: { lenderName: selectedOffer.lender_name } });
    }
  },
);

export const setSelectedOfferId = createAction<string | null>(SET_SELECTED_OFFER_ID);
