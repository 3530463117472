import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    textInput: {
      minWidth: 'auto',
      width: 350,
    },
    button: {
      width: 285,
      height: 46,
      marginTop: theme.spacing(5),
    },
  }),
);
