import { ApplicationRegion, CurrencySymbol, SupportedCurrencies, SupportedLocales } from 'core/types';
import { store } from 'store';

export const mapAppRegionToCurrencySymbol = (region?: ApplicationRegion): CurrencySymbol => {
  switch (region) {
    case ApplicationRegion.UK:
      return CurrencySymbol.GBP;
    case ApplicationRegion.USA:
      return CurrencySymbol.USD;
    case ApplicationRegion.IRL:
      return CurrencySymbol.EUR;
    default:
      return CurrencySymbol.GBP;
  }
};

export const mapAppRegionToSupportedCurrency = (region?: ApplicationRegion): SupportedCurrencies => {
  switch (region) {
    case ApplicationRegion.UK:
      return SupportedCurrencies.GBP;
    case ApplicationRegion.USA:
      return SupportedCurrencies.USD;
    case ApplicationRegion.IRL:
      return SupportedCurrencies.EUR;
    default:
      return SupportedCurrencies.GBP;
  }
};

/**
 *
 * @param currency - SupportedCurrencies
 * @returns SupportedLocale
 */
const getLocaleForCurrency = (currency: SupportedCurrencies) => {
  switch (currency) {
    case SupportedCurrencies.GBP:
      return SupportedLocales.GB;
    case SupportedCurrencies.USD:
      return SupportedLocales.US;
    case SupportedCurrencies.EUR:
      return SupportedLocales.IE;
    default:
      return SupportedLocales.GB;
  }
};

/**
 *  Returns properly formatted money string
 * @param value
 * @param currency
 * @returns
 */
export const parseMoney = (value?: number, currency?: SupportedCurrencies): string => {
  const { region } = store.getState().app;
  const supportedCurrency = currency ?? mapAppRegionToSupportedCurrency(region);

  return Intl.NumberFormat(getLocaleForCurrency(supportedCurrency), {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: supportedCurrency,
  }).format(value || 0);
};
