import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100vw',
      minHeight: '75vh',
      position: 'relative',
    },
    title: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 700,
    },
    faqsContent: {
      '& *': {
        margin: '4px 0',
        fontFamily: theme.typography.fontFamily,
      },
      '& h2': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(28),
        fontWeight: 700,
      },
      '& h3': {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 700,
      },
      '& h4': {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(22),
        fontWeight: 700,
      },
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
      },
    },
    loaderContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
