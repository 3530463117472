import { FC, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import { useAuth } from 'store/auth/hooks';
import routes from 'router/routes';
import { AppButton } from './components';

const UserApps: FC = () => {
  const [activeApp, setActiveApp] = useState<{ id: string; active: boolean } | null>(null);
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();
  const { leadApplications, loading, error, reApply, signIn, clearError } = useAuth();
  const history = useHistory();

  const continueWithApplication = () => {
    if (!activeApp) return;

    if (activeApp.active) {
      signIn(activeApp.id);
      return;
    }

    reApply(activeApp.id);
  };

  const onGoBack = () => {
    history.push(routes.signIn);
  };

  return (
    <Layout
      showSidebar={false}
      onGoBack={onGoBack}
      error={error}
      clearError={clearError}
      onMainAction={continueWithApplication}
      mainButtonTitle={t('global.actions.next')}
      mainButtonDisabled={!activeApp}
      mainButtonLoading={loading}
    >
      <Typography className={commonClasses.title2}>{t('pages.reApply.title')}</Typography>

      {leadApplications && leadApplications.active.length > 0 && (
        <Box marginTop="40px">
          <Typography className={clsx([commonClasses.subtitle2, commonClasses.primaryText, commonClasses.strongText])}>
            {t('pages.reApply.sections.activeApps.title')}
          </Typography>

          <Typography className={commonClasses.subtitle2}>
            {t('pages.reApply.sections.activeApps.description')}
          </Typography>

          <Box marginTop="40px">
            {leadApplications.active.map((app) => (
              <AppButton
                key={app.application_id}
                application={app}
                selected={activeApp?.id === app.application_id}
                setSelected={() => setActiveApp({ id: app.application_id, active: true })}
              />
            ))}
          </Box>
        </Box>
      )}

      {leadApplications && leadApplications.inactive.length > 0 && (
        <Box marginTop="40px">
          <Typography className={clsx([commonClasses.subtitle2, commonClasses.primaryText, commonClasses.strongText])}>
            {t('pages.reApply.sections.inactiveApps.title')}
          </Typography>

          <Typography className={commonClasses.subtitle2}>
            {t('pages.reApply.sections.inactiveApps.description')}
          </Typography>

          <Box marginTop="40px">
            {leadApplications.inactive.map((app) => (
              <AppButton
                key={app.application_id}
                application={app}
                selected={activeApp?.id === app.application_id}
                setSelected={() => setActiveApp({ id: app.application_id, active: false })}
              />
            ))}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default UserApps;
