import { FC } from 'react';
import { AppBar, Toolbar, Box, Typography } from '@material-ui/core';
import moment from 'moment';

import { ReactComponent as BSLetterWhite } from 'assets/icons/bScoreLetterWhite.svg';
import useStyles from './Navbar.styles';
import { useAppState } from '../../store/app/hooks';

const Navbar: FC = () => {
  const classes = useStyles();
  const { letterLogoUrl } = useAppState();

  return (
    <AppBar position="absolute" elevation={0} color="primary">
      <Toolbar>
        {/* Render custom logo when both logos are available */}
        {letterLogoUrl ? (
          <Box className={classes.imgContainer}>
            <img src={letterLogoUrl} alt="Logo" />
          </Box>
        ) : (
          <BSLetterWhite className={classes.bsLetterLogo} />
        )}

        <Box className={classes.rightContent}>
          <Typography className={classes.date}>
            <span>{moment().format('DD MMMM YYYY')}</span> {moment().format('HH:mm')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
