import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionHeader: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: theme.palette.primary.main,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(2),
    },
    sectionDescription: {
      textAlign: 'left',
      marginBottom: theme.spacing(4),
    },
  }),
);
