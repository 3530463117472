import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    sliderContainer: {
      marginTop: 20,
    },
    slider: {
      '& .MuiSlider-thumb': {
        width: 24,
        height: 24,
        marginTop: -10,
        marginLeft: -12,
        boxShadow: '0px 2px 8px 1px rgba(0, 122, 255, 0.4)',
      },
      '& .MuiSlider-rail': {
        height: 6,
        backgroundColor: theme.palette.customGrey.main,
        borderRadius: 10,
      },
      '& .MuiSlider-track': {
        height: 6,
        borderRadius: 10,
      },
    },
    sliderIntervalContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    sliderInterval: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
    },
  }),
);
