export enum MessageAlertStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}

export interface Document {
  id: string;
  name: string;
  type: CompanyDocumentType;
}

export type FileErrors = Array<{ file: string; error: string }>;

export interface DocUploadResponse {
  companyDocs: Document[];
  errors: FileErrors;
}

interface PageSettings {
  hideLandingPageDetails?: boolean;
  hideEventTypeDetails?: boolean;
  backgroundColor?: string;
  textColor?: string;
  primaryColor?: string;
}

interface Prefill {
  name: string;
  email: string;
  firstName?: string;
  lastName?: string;
}

interface Utm {
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  utmTerm: string;
}

export type CalendlyPopupProps = {
  url: string;
  prefill?: Prefill;
  pageSettings?: PageSettings;
  utm?: Utm;
};

export enum FontNames {
  INTER = 'Inter',
  RUBIK = 'Rubik',
  ITIM = 'Itim',
  RALEWAY = 'Raleway',
  LATO = 'Lato',
  NUNITO = 'Nunito',
  ANTONIO = 'Antonio',
}

export interface FontObject {
  url: string;
  fontFamily: string;
}

export type FontOptions = Record<string, FontObject>;

export enum FundingReasons {
  DAILY_OPERATIONS = 'DAILY_OPERATIONS',
  PURCHASING_STOCK = 'PURCHASING_STOCK',
  LOAN_REFINANCE = 'LOAN_REFINANCE',
  MARKETING = 'MARKETING',
  HIRING = 'HIRING',
  PAYING_TAX_BILL = 'PAYING_TAX_BILL',
  PURCHASING_ASSET = 'PURCHASING_ASSET',
  DEBT_CONSOLIDATION = 'DEBT_CONSOLIDATION',
  WORKING_CAPITAL = 'WORKING_CAPITAL',
  BUYING_PROPERTY = 'BUYING_PROPERTY',
  OTHER = 'OTHER',
}

export enum CompanyDocumentType {
  BANK_STATEMENT = 'bank-statement',
  MANAGEMENT_ACCOUNTS = 'management-accounts',
  FILED_ACCOUNTS = 'filed-accounts',
  VAT_RETURN = 'vat-return',
  PAYMENT_DATA = 'payment-data',
  IDENTITY_DOCUMENT = 'identity-document',
}

export enum VerificationSessionStatus {
  REQUIRES_INPUT = 'requires_input', // Requires user input before processing can continue.
  PROCESSING = 'processing', // The session has been submitted and is being processed. Most verification checks take a few minutes to process.
  VERIFIED = 'verified', // Processing of all the verification checks are complete and successfully verified.
  CANCELED = 'canceled', // The VerificationSession has been invalidated for future submission attempts.
}

export enum VerificationSessionType {
  DOCUMENT = 'document',
  ID_NUMBER = 'id_number',
}

export interface VerificationSession {
  id: string;
  object: string;
  client_secret?: string;
  created: number;
  last_error?: { code: string; reason: string };
  livemode: boolean;
  metadata: {
    user_id: string;
  };
  status: VerificationSessionStatus;
  type: VerificationSessionType;
}

export interface StartVerificationSessionResponse {
  client_secret: string;
  session_id: string;
  status: VerificationSessionStatus;
  last_error?: { code: string; reason: string };
  max_attempts: number;
}

export enum SupportedCurrencies {
  GBP = 'GBP',
  USD = 'USD',
  EUR = 'EUR',
}

export enum SupportedLocales {
  GB = 'en-GB',
  US = 'en-US',
  IE = 'en-IE',
}

export enum CurrencySymbol {
  GBP = '£',
  USD = '$',
  EUR = '€',
  JPY = '¥',
}

export enum CompanyTypes {
  LLC = 'llc',
  LTD = 'ltd',
  LLP = 'llp',
  SOLE_TRADER = 'sole_trader',
  PLC = 'plc',
  LP = 'limited_partnership',
  EEIG = 'EEIG',
  NI = 'northern_ireland',
  NIO = 'northern_ireland_other',
  OC = 'oversea-company',
  OTHER = 'OTHER',
}

export enum SupportedCountryCode {
  UNITED_KINGDOM = 'GB',
  IRELAND = 'IE',
  UNITED_ARAB_EMIRATES = 'AE',
  USA = 'US',
  OTHER = 'OTHER',
}

export enum AddressStatus {
  TENANT = 'tenant',
  OWNER_WITH_MORTGAGE = 'owner_with_mortgage',
  OWNER_NO_MORTGAGE = 'owner_no_mortgage',
  RENT_FREE = 'rent_free',
}

export enum AreaPhoneCode {
  GB = '+44',
  US = '+1',
  IE = '+353',
}

export enum EcommerceProviders {
  AMAZON = 'AMAZON',
  SHOPIFY = 'SHOPIFY',
  SQUARESPACE = 'SQUARESPACE',
  BIG_COMMERCE = 'BIG_COMMERCE',
  WOO_COMMERCE = 'WOO_COMMERCE',
  SQUARE = 'SQUARE',
  WIX = 'WIX',
  MAGENTO = 'MAGENTO',
  EBAY = 'EBAY',
  ETSY = 'ETSY',
  PRESTASHOP = 'PRESTASHOP',
  SHOPER = 'SHOPER',
  WALMART = 'WALMART',
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}

export interface BaseApplicationData {
  application_id: string;
  company_name: string;
  creation_date: string;
}

export interface ApplicantLeadsList {
  active: BaseApplicationData[];
  inactive: BaseApplicationData[];
}

export interface LeadApplicationsPayload {
  leadApplications?: ApplicantLeadsList;
  widgetToken?: string;
  accessToken?: string;
}

export enum SettingName {
  FAQS = 'FAQS',
}

export interface Setting {
  id: string;
  name: SettingName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
}

export enum ApplicationRegion {
  USA = 'USA',
  UK = 'UK',
  IRL = 'IRL',
}

export enum AddressTypes {
  PREMISE = 'premise',
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  LANDMARK = 'landmark',
  NEIGHBORHOOD = 'neighborhood',
  SUBLOCALITY_LEVEL_3 = 'sublocality_level_3',
  SUBLOCALITY_LEVEL_2 = 'sublocality_level_2',
  SUBLOCALITY_LEVEL_1 = 'sublocality_level_1',
  POSTAL_TOWN = 'postal_town',
  LOCALITY = 'locality',
  COUNTRY = 'country',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3',
  POSTAL_CODE = 'postal_code',
}

export enum CompanyIndustry {
  ACCOUNTING = 'accounting',
  AIRLINES_AVIATION = 'airlines_aviation',
  ALTERNATIVE_DISPUTE_RESOLUTION = 'alternative_dispute_resolution',
  ALTERNATIVE_MEDICINE = 'alternative_medicine',
  ANIMATION = 'animation',
  APPAREL_AND_FASHION = 'apparel_and_fashion',
  ARCHITECTURE_AND_PLANNING = 'architecture_and_planning',
  ARTS_AND_CRAFTS = 'arts_AND_crafts',
  AUTOMOTIVE = 'automotive',
  AVIATION_AND_AEROSPACE = 'aviation_and_aerospace',
  BANKING = 'banking',
  BIOTECHNOLOGY = 'biotechnology',
  BROADCAST_MEDIA = 'broadcast_media',
  BUILDING_MATERIALS = 'building_materials',
  BUSINESS_SUPPLIES_AND_EQUIPMENT = 'business_supplies_and_equipment',
  CAPITAL_MARKETS = 'capital_markets',
  CHEMICALS = 'chemicals',
  CIVIC_AND_SOCIAL_ORGANIZATION = 'civic_and_social_organization',
  CIVIL_ENGINEERING = 'civil_engineering',
  COMMERCIAL_REAL_ESTATE = 'commercial_real_estate',
  COMPUTER_AND_NETWORK_SECURITY = 'computer_and_network_security',
  COMPUTER_GAMES = 'computer_games',
  COMPUTER_HARDWARE = 'computer_hardware',
  COMPUTER_NETWORKING = 'computer_networking',
  COMPUTER_SOFTWARE = 'computer_software',
  CONSTRUCTION = 'construction',
  CONSUMER_ELECTRONICS = 'consumer_electronics',
  CONSUMER_GOODS = 'consumer_goods',
  CONSUMER_SERVICES = 'consumer_services',
  COSMETICS = 'cosmetics',
  DAIRY = 'dairy',
  DEFENSE_AND_SPACE = 'defense_and_space',
  DESIGN = 'design',
  EDUCATION_MANAGEMENT = 'education_management',
  E_LEARNING = 'e_learning',
  ELECTRICAL_AND_ELECTRONIC_MANUFACTURING = 'electrical_and_electronic_manufacturing',
  ENTERTAINMENT = 'entertainment',
  ENVIRONMENTAL_SERVICES = 'environmental_services',
  EVENTS_SERVICES = 'events_services',
  EXECUTIVE_OFFICE = 'executive_office',
  FACILITIES_SERVICES = 'facilities_services',
  FARMING = 'farming',
  FINANCIAL_SERVICES = 'financial_services',
  FINE_ART = 'fine_art',
  FISHERY = 'fishery',
  FOOD_AND_BEVERAGES = 'food_and_beverages',
  FOOD_PRODUCTION = 'food_production',
  FUNDRAISING = 'fundraising',
  FURNITURE = 'furniture',
  GAMBLING_AND_CASINOS = 'gambling_and_casinos',
  GLASS = 'glass',
  CERAMICS_AND_CONCRETE = 'glass, ceramics_and_concrete',
  GOVERNMENT_ADMINISTRATION = 'government_administration',
  GOVERNMENT_RELATIONS = 'government_relations',
  GRAPHIC_DESIGN = 'graphic_design',
  HEALTH = 'health',
  WELLNESS_AND_FITNESS = 'health, wellness_and_fitness',
  HIGHER_EDUCATION = 'higher_education',
  HOSPITAL_AND_HEALTH_CARE = 'hospital_and_health_care',
  HOSPITALITY = 'hospitality',
  HUMAN_RESOURCES = 'human_resources',
  IMPORT_AND_EXPORT = 'import_and_export',
  INDIVIDUAL_AND_FAMILY_SERVICES = 'individual_and_family_services',
  INDUSTRIAL_AUTOMATION = 'industrial_automation',
  INFORMATION_SERVICES = 'information_services',
  INFORMATION_TECHNOLOGY_AND_SERVICES = 'information_technology_and_services',
  INSURANCE = 'insurance',
  INTERNATIONAL_AFFAIRS = 'international_affairs',
  INTERNATIONAL_TRADE_AND_DEVELOPMENT = 'international_trade_and_development',
  INTERNET = 'internet',
  INVESTMENT_BANKING_VENTURE = 'investment_banking_venture',
  INVESTMENT_MANAGEMENT = 'investment_management',
  JUDICIARY = 'judiciary',
  LAW_ENFORCEMENT = 'law_enforcement',
  LAW_PRACTICE = 'law_practice',
  LEGAL_SERVICES = 'legal_services',
  LEGISLATIVE_OFFICE = 'legislative_office',
  LEISURE_AND_TRAVEL = 'leisure_and_travel',
  LIBRARIES = 'libraries',
  LOGISTICS_AND_SUPPLY_CHAIN = 'logistics_and_supply_chain',
  LUXURY_GOODS_AND_JEWELRY = 'luxury_goods_and_jewelry',
  MACHINERY = 'machinery',
  MANAGEMENT_CONSULTING = 'management_consulting',
  MARITIME = 'maritime',
  MARKETING_AND_ADVERTISING = 'marketing_and_advertising',
  MARKET_RESEARCH = 'market_research',
  MECHANICAL_OR_INDUSTRIAL_ENGINEERING = 'mechanical_or_industrial_engineering',
  MEDIA_PRODUCTION = 'media_production',
  MEDICAL_DEVICE = 'medical_device',
  MEDICAL_PRACTICE = 'medical_practice',
  MENTAL_HEALTH_CARE = 'mental_health_care',
  MILITARY = 'military',
  MINING_AND_METALS = 'mining_and_metals',
  MOTION_PICTURES_AND_FILM = 'motion_pictures_and_film',
  MUSEUMS_AND_INSTITUTIONS = 'museums_and_institutions',
  MUSIC = 'music',
  NANOTECHNOLOGY = 'nanotechnology',
  NEWSPAPERS = 'newspapers',
  NONPROFIT_ORGANIZATION_MANAGEMENT = 'nonprofit_organization_management',
  OIL_AND_ENERGY = 'oil_and_energy',
  ONLINE_PUBLISHING = 'online_publishing',
  OUTSOURCING_OFFSHORING = 'outsourcing_offshoring',
  PACKAGE_FREIGHT_DELIVERY = 'package_freight_delivery',
  PACKAGING_AND_CONTAINERS = 'packaging_and_containers',
  PAPER_AND_FOREST_PRODUCTS = 'paper_and_forest_products',
  PERFORMING_ARTS = 'performing_arts',
  PHARMACEUTICALS = 'pharmaceuticals',
  PHILANTHROPY = 'philanthropy',
  PHOTOGRAPHY = 'photography',
  PLASTICS = 'plastics',
  POLITICAL_ORGANIZATION = 'political_organization',
  PRIMARY_SECONDARY = 'primary_secondary',
  PRINTING = 'printing',
  PROFESSIONAL_TRAINING = 'professional_training',
  PROGRAM_DEVELOPMENT = 'program_development',
  PUBLIC_POLICY = 'public_policy',
  PUBLIC_RELATIONS = 'public_relations',
  PUBLIC_SAFETY = 'public_safety',
  PUBLISHING = 'publishing',
  RAILROAD_MANUFACTURE = 'railroad_manufacture',
  RANCHING = 'ranching',
  REAL_ESTATE = 'real_estate',
  RECREATIONAL = 'recreational',
  FACILITIES_AND_SERVICES = 'facilities_and_services',
  RELIGIOUS_INSTITUTIONS = 'religious_institutions',
  RENEWABLES_AND_ENVIRONMENT = 'renewables_and_environment',
  RESEARCH = 'research',
  RESTAURANTS = 'restaurants',
  RETAIL = 'retail',
  SECURITY_AND_INVESTIGATIONS = 'security_and_investigations',
  SEMICONDUCTORS = 'semiconductors',
  SHIPBUILDING = 'shipbuilding',
  SPORTING_GOODS = 'sporting_goods',
  SPORTS = 'sports',
  STAFFING_AND_RECRUITING = 'staffing_and_recruiting',
  SUPERMARKETS = 'supermarkets',
  TELECOMMUNICATIONS = 'telecommunications',
  TEXTILES = 'textiles',
  THINK_TANKS = 'think_tanks',
  TOBACCO = 'tobacco',
  TRANSLATION_AND_LOCALIZATION = 'translation_and_localization',
  TRANSPORTATION_TRUCKING_RAILROAD = 'transportation_trucking_railroad',
  UTILITIES = 'utilities',
  VENTURE_CAPITAL = 'venture_capital',
  VETERINARY = 'veterinary',
  WAREHOUSING = 'warehousing',
  WHOLESALE = 'wholesale',
  WINE_AND_SPIRITS = 'wine_and_spirits',
  WIRELESS = 'wireless',
  WRITING_AND_EDITING = 'writing_and_Editing',
}
