import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
    },
    outer: {
      border: `2px dashed ${theme.palette.greyIcon.main}`,
      padding: '40px 40px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      borderRadius: 12,
      height: 100,
    },
    outerError: {
      borderColor: theme.palette.error.main,
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    disabled: {
      opacity: 0.33,
    },
    uploadMessage: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      marginTop: 2,
    },
    progress: {
      position: 'absolute',
    },
    errorMessage: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.error.main,
      marginTop: theme.spacing(1),
      marginBottom: -theme.spacing(1),
    },
  }),
);
