import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainText: {
      fontSize: 24,
      lineHeight: '28px',
      textAlign: 'center',
      letterSpacing: -0.576,
    },
    fundingAmountInput: {
      '& .MuiOutlinedInput-root': {
        height: 54,
        borderRadius: 12,
        marginTop: theme.spacing(1),
      },
      '& input': {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
        textAlign: 'center',
        fontWeight: 700,
        paddingTop: 10,
        paddingBottom: 10,
      },
      '& .MuiFormHelperText-contained': {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    fundingReasonsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: theme.spacing(1),
      '& > *': {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        marginRight: theme.spacing(1.5),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        marginTop: theme.spacing(1),
      },
    },
    fundingReasonOption: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    radioInput: {
      '& label:last-child span.MuiRadio-root': {
        position: 'relative',
        '&::after': {
          content: '" "',
          position: 'absolute',
          bottom: -8,
          left: 10,
          height: 1,
          width: 24,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    detailsContainer: {
      marginBottom: 20,
    },
    textarea: {
      marginTop: theme.spacing(1),
      '& .MuiOutlinedInput-root': {
        borderRadius: 12,
        padding: 24,
      },
    },
    dividerContainer: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(1),

      '& svg': {
        width: '100%',
      },
    },
    ccjsButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(-2),
      marginTop: theme.spacing(1),
    },
    deleteCcjButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 400,
      marginTop: -6,
      minHeight: 24,
      width: 61,
      height: 24,
      padding: 0,
      borderRadius: 6,
    },
    ccJNumberTitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      width: '100%',
      color: theme.palette.primary.main,
    },
    addCcjBtn: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
    inputLabel: {
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(18),
      },
    },
  }),
);
