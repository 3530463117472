import { FC, useEffect } from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import routes from 'router/routes';
import analytics from 'core/services/analytics';
import useStyles from './Logout.styles';

const Logout: FC = () => {
  const { t } = useTranslation();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    analytics.track(routes.logout);
  }, []);

  const onLogin = () => {
    history.push(routes.signIn);
  };

  return (
    <Layout showSidebar={false} withBackButton={false} showShapeBackground>
      <Box className={classes.container}>
        <Typography className={commonClasses.title}>{t('pages.logout.title')}</Typography>

        <Typography className={commonClasses.subtitle}>{t('pages.logout.description')}</Typography>

        <Button variant="contained" color="primary" className={classes.button} onClick={onLogin}>
          {t('pages.logout.buttons.main')}
        </Button>
      </Box>
    </Layout>
  );
};

export default Logout;
