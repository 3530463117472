/**
 *
 * @param dimension
 * @returns
 */
export const parseDimension = (dimension: string | number): string => {
  if (typeof dimension === 'string') {
    return dimension;
  }
  return `${dimension}px`;
};
