import { apiCall } from 'http/index';
import { AcceptOfferBody, AcceptOfferData, LenderOffer } from 'store/offers/types';

export const getOffers = async (companyId: string): Promise<LenderOffer[]> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/offers` });

export const acceptOffer = async ({
  companyId,
  offerId,
  amount,
  rateId,
  repaymentId,
}: AcceptOfferData): Promise<null> => {
  const body: AcceptOfferBody = { borrow_amount: amount };
  if (rateId) body.selected_rate_id = rateId;
  else if (repaymentId) body.selected_revenue_repayment_id = repaymentId;
  return apiCall({ method: 'post', url: `/companies/${companyId}/offers/${offerId}/accept`, data: body });
};
