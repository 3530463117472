import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      '& .MuiButton-startIcon': {
        width: '100%',
        maxWidth: 88,
        height: 'auto',
        marginLeft: -44,
        [theme.breakpoints.down(450)]: {
          display: 'none',
          visibility: 'hidden',
        },
      },
    },
  }),
);
