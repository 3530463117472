import { FC, ReactNode } from 'react';
import { Box, Typography, Tooltip, IconButton, Chip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import * as CSS from 'csstype';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import useCommonStyles from 'core/styles';
import useStyles, { useTooltipStyles } from './CustomLabel.styles';

interface CustomLabelProps {
  title: string | ReactNode;
  containerStyle?: CSS.Properties;
  labelStyle?: CSS.Properties;
  labelClassName?: string;
  tooltipMessage?: string;
  optional?: boolean;
}

const CustomLabel: FC<CustomLabelProps> = ({
  title,
  labelClassName,
  tooltipMessage,
  containerStyle = {},
  labelStyle = {},
  optional = false,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container} style={containerStyle}>
      <Typography className={clsx([classes.inputLabel, labelClassName])} style={labelStyle}>
        {title}
      </Typography>

      {optional && <Chip size="small" label={` ${t('global.optional')}`} className={commonClasses.chip} />}

      {tooltipMessage && (
        <Tooltip
          title={tooltipMessage}
          arrow
          classes={tooltipClasses}
          enterTouchDelay={0}
          leaveDelay={3000}
          style={{ marginLeft: 2 }}
        >
          <IconButton aria-label="tooltip-icon" size="small">
            <InfoOutlined fontSize="small" className={classes.tooltipIcon} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default CustomLabel;
