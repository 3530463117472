import { useActionCreator } from 'hooks';
import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { clearError, initAuth, setVerified, getLeadApplications, reApply, signIn } from './actions';
import { AuthHookReturn } from './types';

export const useAuth = (): AuthHookReturn => {
  const authState = useSelector((state: RootState) => state.auth, shallowEqual);

  return {
    ...authState,
    clearError: useActionCreator(clearError),
    initAuth: useActionCreator(initAuth),
    setVerified: useActionCreator(setVerified),
    getLeadApplications: useActionCreator(getLeadApplications),
    reApply: useActionCreator(reApply),
    signIn: useActionCreator(signIn),
  };
};
