import React from 'react';
import NumberFormat from 'react-number-format';

interface CustomNumberFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  useFormattedValue?: boolean;
}

interface Values {
  value: string;
  formattedValue: string;
}

const CustomNumberFormat: React.FC<CustomNumberFormatProps> = ({
  name,
  inputRef,
  onChange,
  useFormattedValue,
  ...other
}) => (
  <NumberFormat
    getInputRef={inputRef}
    displayType="input"
    onValueChange={(values: Values) => {
      onChange({
        target: {
          name,
          value: useFormattedValue ? values.formattedValue : values.value,
        },
      });
    }}
    thousandSeparator
    isNumericString={false}
    {...other}
  />
);

export default CustomNumberFormat;
