const routes = {
  home: '/',

  signIn: '/auth/signIn',
  confirmCode: '/auth/confirmCode',
  userApps: '/auth/userApps',

  companySearch: '/onboard/companySearch',
  preApply: '/onboard/preApply',
  companyInfo: '/onboard/companyInfo',
  applicantInfo: '/onboard/applicantInfo',

  fundingGoal: '/onboard/fundingGoal',
  fundingResult: '/onboard/fundingResult',
  additionalInfo: '/onboard/additionalInfo',
  kyc: '/onboard/kyc',
  customiseGoal: '/onboard/customiseGoal',

  acceptedOffer: '/offers/accepted',
  offers: '/offers',
  creditAgreement: '/offers/creditAgreement',
  bankDetails: '/offers/bankDetails',
  finish: '/offers/finish',

  invalidKey: '/invalid-key',
  rejected: '/rejected',

  detailsRequested: '/detailsRequested',
  logout: '/logout',
};

export default routes;
