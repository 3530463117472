/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { ReactComponent as BankingData } from 'assets/icons/bankingData.svg';
import { CompanyDocumentType } from 'core/types';
import { useOnboard } from 'store/onboard/hooks';
import { ReactComponent as CircleCheckMark } from 'assets/icons/circleCheckMark.svg';
import { noop } from 'utils';
import { DocumentCard } from '../DocumentCard';
import { AlertDialog } from '../../alerts';
import useStyles from './BankDocument.styles';
import { DocumentUpload } from '../DocumentUpload';

interface BankDocumentProps {
  active: boolean;
  toggleCard: (integration: CompanyDocumentType | null) => void;
  validationError?: string;
}

const BankDocument: FC<BankDocumentProps> = ({ active = true, toggleCard, validationError }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [uploadDocsActive, setUploadDocsActive] = useState(true);
  const { t } = useTranslation();
  const { additionalInfo, company } = useOnboard();
  const classes = useStyles();

  useEffect(() => {
    if (validationError) setUploadDocsActive(true);
  }, [validationError]);

  const handleConfirm = () => {
    setOpenAlert(false);
  };

  const handleCancel = () => {
    setOpenAlert(false);
  };

  const currentIntegration = additionalInfo[CompanyDocumentType.BANK_STATEMENT];
  const integrationSuccess = typeof currentIntegration === 'boolean' && currentIntegration;
  const hasDocuments = typeof currentIntegration !== 'boolean' && currentIntegration.length > 0;

  return (
    <DocumentCard
      title={t('pages.additionalInfo.documentUploads.bankDetails.title')}
      description={t('pages.additionalInfo.documentUploads.bankDetails.description')}
      Icon={<BankingData />}
      documentType={CompanyDocumentType.BANK_STATEMENT}
      toggleCard={toggleCard}
      active={active}
      success={integrationSuccess || hasDocuments}
      withMainButton={false}
    >
      {integrationSuccess ? (
        <Box className={classes.successContainer}>
          <CircleCheckMark className={classes.circleCheckMark} />
          <Typography className={classes.connectedMessage}>
            {t('pages.additionalInfo.documentUploads.bankDetails.linked')}
          </Typography>
        </Box>
      ) : (
        <>
          <Box className={classes.contentContainer} style={{ marginBottom: uploadDocsActive ? 0 : 16 }}>
            <Alert severity="info">
              {t('pages.additionalInfo.documentUploads.bankDetails.description', {
                companyName: company.trading_name || company.registered_name || 'your business',
              })}
            </Alert>
          </Box>

          {(uploadDocsActive || hasDocuments) && (
            <DocumentUpload
              documentType={CompanyDocumentType.BANK_STATEMENT}
              validationError={validationError}
              multipleFiles
            />
          )}
        </>
      )}

      <AlertDialog
        open={openAlert}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        dialogContentTitle={t('pages.additionalInfo.documentUploads.bankDetails.dialogTitle')}
        dialogContentText={
          <>
            {t('pages.additionalInfo.documentUploads.bankDetails.dialogText.line1')}{' '}
            <Link href="#" onClick={noop}>
              {t('pages.additionalInfo.documentUploads.bankDetails.dialogText.line2')}
            </Link>
            .
          </>
        }
      />
    </DocumentCard>
  );
};

export default BankDocument;
