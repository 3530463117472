import { apiCall } from '../index';
import { Document, DocUploadResponse } from '../../core/types';

export const getDocuments = async (companyId: string): Promise<Document[]> =>
  apiCall({ method: 'get', url: `/companies/${companyId}/documents` });

export const uploadDocument = async (companyId: string, formData: FormData): Promise<DocUploadResponse> =>
  apiCall({
    method: 'post',
    url: `/companies/${companyId}/documents`,
    data: formData,
    headers: { 'content-type': 'multipart/form-data' },
  });

export const deleteDocument = async (companyId: string, documentId: string): Promise<null> =>
  apiCall({ method: 'delete', url: `/companies/${companyId}/documents/${documentId}` });
