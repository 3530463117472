import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { ReactComponent as DashedDivider } from 'assets/icons/dashedDivider.svg';
import clsx from 'clsx';
import { AmountNumberInput } from 'components/AmountNumberInput';
import { HideOptionalFields } from 'components/HideOptionalFields';
import {
  CustomDatePicker,
  CustomInputError,
  CustomLabel,
  CustomNumberFormat,
  CustomRadioField,
  CustomSliderField,
  CustomTextField,
} from 'components/inputs';
import { Layout } from 'components/Layout';
import { Loader } from 'components/Loader';
import { ccjTypeOptions, fundingReasonOptions, maxFundingAmount, onlineSalesOptions } from 'core/constants';
import analytics from 'core/services/analytics';
import useCommonStyles from 'core/styles';
import { ApplicationRegion, FundingReasons } from 'core/types';
import { Formik, FastField, FieldArray, Form } from 'formik';
import { Moment } from 'moment';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from 'router/routes';
import { useAppState } from 'store/app/hooks';
import { useAuth } from 'store/auth/hooks';
import { useOnboard } from 'store/onboard/hooks';
import { CCJType, CompanyCCJ } from 'store/onboard/types';
import { mapAppRegionToCurrencySymbol } from 'utils/currency';
import useStyles from './FundingGoal.styles';
import { FormValues, getCcjFieldError, getFormSchema, getInitialValues } from './utils';

const FundingGoal: FC = () => {
  const [loading, setLoading] = useState(false);
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const history = useHistory();
  const { company, prequalifyResult, hideOptionalFields, prequalifyCompany, setSuccess } = useOnboard(undefined, () =>
    setLoading(false),
  );
  const { t } = useTranslation();
  const { adminEditMode } = useAuth();
  const { region: appRegion } = useAppState();

  useEffect(() => {
    analytics.track(routes.fundingGoal);

    return () => setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const creditLimit = prequalifyResult?.credit_limit ?? maxFundingAmount;

  const companyInfoSchema = useMemo(() => getFormSchema(creditLimit), [creditLimit]);

  const initialValues = getInitialValues(company, creditLimit);

  const onSubmit = (values: FormValues) => {
    if (!adminEditMode) setLoading(true);

    prequalifyCompany({
      companyInfo: {
        ...values,
        funding_amount: Number(values.funding_amount),
        funding_reasons: values.funding_reasons,
        funding_date: values.funding_date?.toISOString(),
        monthly_revenue: Number(values.monthly_revenue),
        year_to_date_revenue: Number(values.year_to_date_revenue || (values.monthly_revenue || 0) * 12),
        online_sales_revenue_percentage: (values.online_sales_revenue_percentage || 0) / 100,
        repeat_customers_revenue_percentage: (values.repeat_customers_revenue_percentage || 0) / 100,
      },
    });
  };

  const goBack = () => {
    history.push(routes.applicantInfo);
  };

  const onGoForward = () => {
    history.push(routes.customiseGoal);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={companyInfoSchema} enableReinitialize>
      {({ values, setFieldValue, handleSubmit, handleChange, errors, touched }) => (
        <Layout
          headerTitle={t('pages.fundingGoal.header')}
          onMainAction={handleSubmit}
          mainButtonTitle={t('pages.fundingGoal.buttons.main')}
          onGoBack={goBack}
          loading={loading}
          Loader={
            <Loader
              visible
              title={t('pages.fundingGoal.loader.title')}
              description={t('pages.fundingGoal.loader.description')}
            />
          }
          onGoForward={adminEditMode ? onGoForward : undefined}
          withBackButton={appRegion === ApplicationRegion.UK}
        >
          <Typography variant="h1" className={commonClasses.title2}>
            {t('pages.fundingGoal.title')}
          </Typography>

          <Typography className={commonClasses.subtitle2}>{t('pages.fundingGoal.description')}</Typography>

          <HideOptionalFields />

          {!loading && (
            <Form noValidate className={commonClasses.form}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <FastField
                    id="funding_amount"
                    fullWidth
                    component={CustomTextField}
                    autoFocus
                    name="funding_amount"
                    value={values.funding_amount}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('funding_amount', Number(event.target.value))
                    }
                    title={t('pages.fundingGoal.inputs.funding_amount.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: AmountNumberInput,
                    }}
                    className={classes.fundingAmountInput}
                    variant="outlined"
                  />
                </Grid>

                <Grid item>
                  <CustomLabel
                    title={
                      <>
                        <span>{t('pages.fundingGoal.inputs.funding_reasons.label.line1')}</span>{' '}
                        <strong>{t('pages.fundingGoal.inputs.funding_reasons.label.line2')}</strong>
                      </>
                    }
                  />

                  <Box className={classes.fundingReasonsContainer}>
                    {fundingReasonOptions.map((option) => {
                      const alreadySelected = values.funding_reasons.find((v: FundingReasons) => v === option.value);
                      const firstSelected = values.funding_reasons[0] === option.value;
                      const color = () => {
                        if (alreadySelected && firstSelected) {
                          return 'primary';
                        }

                        if (alreadySelected && !firstSelected) {
                          return 'secondary';
                        }

                        return 'default';
                      };
                      return (
                        <Chip
                          id={option.value}
                          key={option.value}
                          color={color()}
                          label={option.label}
                          onClick={() => {
                            if (alreadySelected) {
                              setFieldValue(
                                'funding_reasons',
                                values.funding_reasons.filter((v: FundingReasons) => v !== option.value),
                              );
                            } else {
                              setFieldValue('funding_reasons', [...values.funding_reasons, option.value]);
                            }
                          }}
                          className={classes.fundingReasonOption}
                        />
                      );
                    })}
                  </Box>

                  {touched.funding_reasons && errors.funding_reasons && (
                    <CustomInputError message={errors.funding_reasons as string} />
                  )}
                </Grid>

                {!hideOptionalFields && (
                  <Grid item>
                    <CustomDatePicker
                      id="funding_date"
                      name="funding_date"
                      label={t('pages.fundingGoal.inputs.funding_date.label')}
                      value={values.funding_date}
                      onChange={(date: Moment | null) => setFieldValue('funding_date', date?.toDate() ?? null)}
                      disablePast
                      error={errors.funding_date}
                      clearable
                      optional
                    />
                  </Grid>
                )}

                <Grid item>
                  <CustomLabel title={t('pages.fundingGoal.inputs.online_sales_revenue_percentage.label')} />

                  <Box className={classes.fundingReasonsContainer}>
                    {onlineSalesOptions.map((option) => {
                      return (
                        <Chip
                          key={option.label}
                          id={option.label}
                          label={option.label}
                          onClick={() => setFieldValue('online_sales_revenue_percentage', option.value)}
                          color={values.online_sales_revenue_percentage === option.value ? 'primary' : 'default'}
                        />
                      );
                    })}
                  </Box>

                  {touched.online_sales_revenue_percentage && errors.online_sales_revenue_percentage && (
                    <CustomInputError message={errors.online_sales_revenue_percentage as string} />
                  )}
                </Grid>

                <Grid item>
                  <FastField
                    id="monthly_revenue"
                    fullWidth
                    component={CustomTextField}
                    name="monthly_revenue"
                    value={values.monthly_revenue}
                    onChange={handleChange}
                    title={t('pages.fundingGoal.inputs.monthly_revenue.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">{mapAppRegionToCurrencySymbol(appRegion)}</InputAdornment>
                      ),
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      inputComponent: CustomNumberFormat as any,
                    }}
                    className={commonClasses.textInput}
                  />
                </Grid>

                {!hideOptionalFields && (
                  <Grid item>
                    <FastField
                      id="year_to_date_revenue"
                      fullWidth
                      component={CustomTextField}
                      name="year_to_date_revenue"
                      value={values.year_to_date_revenue}
                      onChange={handleChange}
                      title={t('pages.fundingGoal.inputs.year_to_date_revenue.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">{mapAppRegionToCurrencySymbol(appRegion)}</InputAdornment>
                        ),
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        inputComponent: CustomNumberFormat as any,
                      }}
                      className={commonClasses.textInput}
                      optional
                    />
                  </Grid>
                )}

                {!hideOptionalFields && (
                  <Grid item>
                    <CustomSliderField
                      title={t('pages.fundingGoal.inputs.repeat_customers_revenue_percentage.label')}
                      value={values.repeat_customers_revenue_percentage}
                      onChange={(value) => setFieldValue('repeat_customers_revenue_percentage', value)}
                      min={0}
                      max={100}
                      step={1}
                      id="repeat_customers_revenue_percentage"
                      optional
                    />

                    {touched.repeat_customers_revenue_percentage && errors.repeat_customers_revenue_percentage && (
                      <FormHelperText error>{errors.repeat_customers_revenue_percentage}</FormHelperText>
                    )}
                  </Grid>
                )}

                {appRegion === ApplicationRegion.UK && !(hideOptionalFields && !values.any_ccj) && (
                  <FieldArray name="ccjs">
                    {({ remove: removeCcj, push: pushCcj }) => {
                      return (
                        <>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.any_ccj}
                                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    const ccj = !!event.target.checked;
                                    setFieldValue('any_ccj', ccj);
                                    if (ccj && values.ccjs.length === 0)
                                      pushCcj({ type: CCJType.ACTIVE, description: '' });
                                    else setFieldValue('ccjs', []);
                                  }}
                                  color="primary"
                                  size="small"
                                  name="any_ccj"
                                  aria-label="any_ccj"
                                />
                              }
                              label={
                                <CustomLabel
                                  title={t('pages.fundingGoal.inputs.any_ccj.label')}
                                  optional
                                  tooltipMessage={t('pages.fundingGoal.inputs.any_ccj.tooltipMessage')}
                                />
                              }
                              className={classes.inputLabel}
                            />
                          </Grid>

                          <Grid item>
                            <Box display="flex" flexDirection="column" alignItems="center">
                              {values.ccjs &&
                                values.ccjs.length > 0 &&
                                values.ccjs.map((ccj: CompanyCCJ, index: number) => {
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Grid container item direction="column" spacing={4} key={`ccj-${index}`}>
                                      {index > 0 && (
                                        <Grid item className={classes.dividerContainer}>
                                          <DashedDivider />
                                        </Grid>
                                      )}

                                      {index !== 0 && (
                                        <Grid item className={classes.ccjsButtonContainer}>
                                          <Button
                                            color="primary"
                                            variant="outlined"
                                            aria-label={`delete address ${index + 1}`}
                                            onClick={() => removeCcj(index)}
                                            className={classes.deleteCcjButton}
                                            id={`deleteAddressButton${index + 1}`}
                                          >
                                            {t('global.actions.delete')}
                                          </Button>
                                        </Grid>
                                      )}

                                      <Grid item>
                                        <FastField
                                          id={`ccjs.${index}.type`}
                                          aria-label="ccj type"
                                          component={CustomRadioField}
                                          options={ccjTypeOptions}
                                          name={`ccjs.${index}.type`}
                                          value={ccj.type}
                                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`ccjs.${index}.type`, event.target.value)
                                          }
                                          title={t('pages.fundingGoal.inputs.ccj_type.label')}
                                          className={clsx([commonClasses.textInput, classes.radioInput])}
                                        />
                                      </Grid>

                                      <Grid item>
                                        <FastField
                                          id={`ccjs.${index}.description`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`ccjs.${index}.description`}
                                          value={ccj.description || ''}
                                          onChange={handleChange}
                                          title={t('pages.fundingGoal.inputs.ccj_description.label')}
                                          className={clsx([commonClasses.textInput, classes.textarea])}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          variant="outlined"
                                          multiline
                                          rows={10}
                                          error={!!getCcjFieldError(touched, errors, index, 'description')}
                                          helperText={getCcjFieldError(touched, errors, index, 'description')}
                                        />
                                      </Grid>

                                      <Grid item>
                                        <Button
                                          color="primary"
                                          fullWidth
                                          onClick={() => pushCcj({ type: CCJType.ACTIVE, description: '' })}
                                          className={classes.addCcjBtn}
                                          variant="outlined"
                                          id="addAddressButton"
                                        >
                                          {t('pages.fundingGoal.buttons.ccj_add')}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                            </Box>
                          </Grid>
                        </>
                      );
                    }}
                  </FieldArray>
                )}
              </Grid>
            </Form>
          )}
        </Layout>
      )}
    </Formik>
  );
};

export default FundingGoal;
