import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      bottom: 35,
      position: 'absolute',
      minWidth: 300,
      [theme.breakpoints.up('sm')]: {
        marginRight: '-50%',
      },
    },
  }),
);
