import { parseDimension } from './utils';

export const Modal = (modalDivId: string, width?: string, height?: string): string => {
  const containerHeight = height ? parseDimension(height) : '700px';
  const containerWidth = width ? parseDimension(width) : '700px';

  return `
  <style>
    .modal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      top: 0;
      left: 0;
      display: flex;
      align-content: flex-start;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
      z-index: 2000;
    }
    .modal.open {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s;
    }
    .modal-bg {
      position: fixed;
      background: rgba(0,0,0,0.2);
      width: 100%;
      height: 100%;
    }
    .modal-container {
      border-radius: 10px;
      background: #fff;
      position: relative;
      width: 100%;
      margin: 20px 20px;
      max-width: ${containerWidth};
      height: ${containerHeight};
      overflow: hidden;
    }
    .modal-close {
      position: absolute;
      right: 15px;
      top: 15px;
      outline: none;
      appearance: none;
      color: #000;
      background: none;
      border: 0;
      font-weight: bold;
      cursor: pointer;
      font-size: 20px;
    }
  </style>
  <div class="modal" id="${modalDivId}">
    <div class="modal-bg modal-exit"></div>
    <div class="modal-container"></div>
    <button class="modal-close modal-exit">X</button>
  </div>
`;
};
