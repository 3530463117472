/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useCallback, useState, useMemo } from 'react';
import { Box, ThemeProvider, CircularProgress } from '@material-ui/core';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { PersistGate } from 'redux-persist/integration/react';
import debounce from 'lodash.debounce';

import './i18n/config';
import { getPartnerConfig as getPartnerConfigApi } from 'http/app';
import { clearApplication, updateAppStateAction } from 'store/app/actions';
import analytics from 'core/services/analytics';
import { fonts } from 'core/constants/fonts';
import { FontNames } from 'core/types';
import getTheme from 'core/theme-extended';
import AxiosInterceptors from 'core/interceptors';
import { AppRouter } from 'router';
import { store, persistor } from 'store';
import { useWebFontLoader, useIsMounted } from 'hooks';
import { history } from 'router/service';
import routes from 'router/routes';
import { noop, removeNilValues } from 'utils';
import { widgetDefaultHeight, widgetDefaultWidth } from 'core/constants';
import { WidgetProps } from './widget-sdk/types';

AxiosInterceptors.setup(store);

analytics.init();

interface AppWindow {
  xprops: WidgetProps;
}

const appWindow = (window as unknown) as AppWindow;

const DEBOUNCE_DELAY = process.env.NODE_ENV === 'test' ? 0 : 100;

const App: FC = () => {
  const [loading, setLoading] = useState(true);
  const [widgetConfig, setWidgetConfig] = useState<WidgetProps | null>(appWindow.xprops ?? null);
  const fontName = widgetConfig?.fontName || FontNames.INTER;
  // In case font name was provided, but it's not predefined
  const customFont = fonts[fontName] || fonts[FontNames.INTER];
  const fontLoading = useWebFontLoader(fontName);
  const { whenMounted } = useIsMounted();

  const setPartnerConfig = useCallback(
    async (props?: WidgetProps) => {
      let publicKey = '';
      let width: string | number | undefined;
      let height: string | number | undefined;
      let popup = false;
      const widget = !!appWindow.xprops;

      try {
        const query = new URLSearchParams(window.location.search);
        const config = props ?? widgetConfig;
        const storedPublicKey = store.getState().app.publicKey;
        publicKey = query.get('public_key') ?? config?.publicKey ?? storedPublicKey ?? '';
        const accessToken = query.get('access_token') ?? config?.accessToken;
        popup = !!config?.popup;

        if (widget) {
          width = config?.width;
          height = config?.height;
        }

        // Clear url query parameters if any
        window.history.replaceState({}, document.title, routes.home);

        if (!publicKey) throw new Error();

        const savedConfig = await getPartnerConfigApi(publicKey);

        if (widget && width === widgetDefaultWidth && savedConfig.width) {
          width = savedConfig.width as string;
        }

        if (widget && height === widgetDefaultHeight && savedConfig.height) {
          height = savedConfig.height as string;
        }

        const updatedConfig = removeNilValues({
          ...savedConfig,
          ...config,
          accessToken,
          publicKey,
          height,
          width,
          popup,
          widget,
        }) as Partial<WidgetProps> & { widget: boolean };

        whenMounted(() => setWidgetConfig(updatedConfig as any));
        store.dispatch(updateAppStateAction(updatedConfig) as any);
      } catch (error) {
        whenMounted(() => setWidgetConfig({ publicKey, width, height, popup } as any));
        store.dispatch(updateAppStateAction({ publicKey, width, height, popup, widget }) as any);
        // No or invalid key, partner not enabled, or server error
        history.push(routes.invalidKey);
      }
      whenMounted(() => setLoading(false));
    },
    [whenMounted, widgetConfig],
  );

  /**
   * This will be called twice, in order for this to work in all environments (specifically fixes
   * the issue we were having using local html files when testing on iOS or Android). That's why
   * we decided to debounce the call, so the setPartnerConfig is only executed once at startup.
   */
  const initializeWidget = useMemo(() => debounce(setPartnerConfig, DEBOUNCE_DELAY), [setPartnerConfig]);

  useEffect(() => {
    store.dispatch(clearApplication());

    if (widgetConfig !== null && widgetConfig.skipHomePage) history.replace(routes.preApply);
    else history.replace(routes.home);

    initializeWidget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appWindow.xprops?.onProps((props: WidgetProps) => {
      // Make sure we always start in a 'blank' state
      if (typeof props.opened === 'boolean') {
        store.dispatch(clearApplication());
        history.replace(props.skipHomePage ? routes.preApply : routes.home);

        if (props.opened) initializeWidget(props);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (fontLoading || loading) {
    return (
      <Box
        className="bScore-container"
        justifyContent="center"
        alignItems="center"
        width={widgetConfig?.width || '100vw'}
        height={widgetConfig?.height || '100vh'}
        data-testid="bs-app-loader"
      >
        <CircularProgress size={50} thickness={3} />
      </Box>
    );
  }

  return (
    <Box className="bScore-container" justifyContent={widgetConfig?.popup ? 'flex-start' : 'center'}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ThemeProvider
            theme={getTheme(customFont.fontFamily, widgetConfig?.primaryColor, widgetConfig?.secondaryColor)}
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <AppRouter onNotNow={widgetConfig?.onNotNow ?? noop} />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </Box>
  );
};

export default App;
