import { FC, ElementType } from 'react';
import { FormHelperText } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

import useCommonStyles from 'core/styles';

interface CustomInputErrorProps {
  message: string;
  component?: ElementType;
}

const CustomInputError: FC<CustomInputErrorProps> = ({ message, component = 'p' }) => {
  const commonClasses = useCommonStyles();

  return (
    <FormHelperText
      error
      className={commonClasses.error}
      component={component}
      role="alert"
      aria-label={`error: ${message.toLowerCase()}`}
    >
      <ErrorOutline />
      {message}
    </FormHelperText>
  );
};

export default CustomInputError;
