import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = '100%';

export default makeStyles((theme: Theme) =>
  createStyles({
    topContainer: {
      position: 'relative',
      overflow: 'hidden',
    },
    background: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      '& img': {
        width: '100%',
        height: '100%',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
        visibility: 'hidden',
      },
    },
    container: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      padding: 16,
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        paddingTop: 21,
      },
    },
    widgetContainer: {
      minHeight: 'inherit',
      height: '100%',
      width: '100%',
      paddingTop: 21,
    },
    desktopContainer: {
      right: 150,
      [theme.breakpoints.down('md')]: {
        right: 0,
      },
    },
    backButton: {
      position: 'absolute',
      top: theme.spacing(11),
      left: -8,
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        top: 10,
      },
    },
    widgetBackButton: {
      top: 10,
    },
    forwardButton: {
      right: -8,
      left: 'unset',
    },
    topRightContainer: {
      display: 'flex',
      position: 'absolute',
      top: theme.spacing(11),
      right: -8,
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        top: 10,
      },
    },
    widgetTopRightContainer: {
      top: 10,
    },
    header: {
      marginBottom: 40,
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: theme.palette.greyText.main,
      textAlign: 'center',
    },
    main: {
      flex: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      paddingTop: 40,
      paddingBottom: 20,
      width: '100%',
    },
    button: {
      fontSize: 16,
      lineHeight: '19px',
      letterSpacing: -0.384,
    },
    arrowBackIcon: {
      color: theme.palette.greyText.main,
    },
    faqButton: {
      backgroundColor: theme.palette.background.paper,
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1,
    },
    faqIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
      marginRight: theme.spacing(1),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      position: 'sticky',
      top: 0,
      right: 0,
      zIndex: 20,
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      background: 'white',
    },
  }),
);
