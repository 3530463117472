import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0px 2px 8px 2px rgba(206, 213, 219, 0.40464)',
      borderRadius: 12,
      padding: 12,
      marginBottom: theme.spacing(2),
    },
    contentContainer: {
      display: 'flex',
      paddingTop: 4,
      alignItems: 'center',
    },
    iconContainer: {
      height: 44,
      width: 44,
      borderRadius: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginRight: theme.spacing(0.5),
      transition: 'all 0.35s',
      '& svg, & img': {
        height: '55%',
        width: '55%',
      },
    },
    iconContainerExpanded: {
      height: 24,
      width: 24,
      borderRadius: 24,
      backgroundColor: theme.palette.primary.main,
      '& svg, & svg path': {
        fill: theme.palette.common.white,
      },
    },
    iconSuccessExpanded: {
      backgroundColor: '#E6F0FD',
      '& svg, & svg path': {
        fill: theme.palette.success.main,
      },
    },
    checkIcon: {
      color: theme.palette.success.main,
      position: 'absolute',
    },
    infoContainer: {
      flex: 1,
      marginLeft: theme.spacing(1),
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    titleExpanded: {
      fontWeight: 600,
    },
    description: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyText.main,
      marginTop: 2,
    },
    button: {
      width: 25,
      height: 25,
      minWidth: 25,
      marginTop: -4,
      '& svg': {
        color: theme.palette.greyText.main,
      },
    },
    collapsibleContent: {
      paddingTop: 16,
    },
    divider: {
      marginBottom: 16,
    },
    actionButtonContainer: {
      marginTop: theme.spacing(3.5),
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  }),
);
