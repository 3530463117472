import { FC } from 'react';
import { Box, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleCheckMark } from 'assets/icons/circleCheckMark.svg';
import { LenderOffer, OfferProductType, OfferStatus } from 'store/offers/types';
import { decimalToPercentage } from 'utils';
import { parseMoney } from 'utils/currency';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './OfferCard.styles';
import { CollapsibleCard } from '../../CollapsibleCard';
import { CollapsibleCardProps } from '../../CollapsibleCard/CollapsibleCard';
import OfferContent from '../OfferContent/OfferContent';

interface OfferCardProps extends Omit<CollapsibleCardProps, 'handleExpandClick'> {
  offer: LenderOffer;
  toggleCard: (rateId: string | null) => void;
  mainActionDisabled?: boolean;
  withContent?: boolean;
  detailsRequested?: boolean;
}

const OfferCard: FC<OfferCardProps> = ({
  active,
  offer,
  toggleCard,
  children,
  mainActionDisabled,
  withContent = true,
  detailsRequested = false,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleExpandClick = () => {
    if (active) {
      toggleCard(null);
    } else {
      toggleCard(offer.unique_id);
    }
  };

  const accepted = offer.status === OfferStatus.ACCEPTED && offer.selected;

  const lenderLogo = offer.lender_logo ? (
    <Box className={classes.lenderLogoContainer}>
      <img src={offer.lender_logo} alt={offer.lender_name} className={classes.lenderLogo} />
    </Box>
  ) : undefined;

  // TODO: uncomment until we migrate to iwoca v2
  // const isIwocaOffer = /iwoca/i.test(offer.lender_name);

  return accepted ? (
    <Paper className={classes.acceptedContainer}>
      <CircleCheckMark className={classes.acceptedIcon} />

      <Typography className={classes.acceptedTitle}>{t('pages.offers.card.borrowed.title')}</Typography>

      <Typography className={classes.acceptedTitleStrong}>{parseMoney(offer.loan_amount, offer.currency)}</Typography>

      <Typography className={classes.acceptedDescription}>
        {t('pages.offers.card.borrowed.description.line1')}{' '}
        {decimalToPercentage(offer.product_type !== OfferProductType.REVENUE_BASED ? offer.interest_rate : offer.sweep)}{' '}
        {offer.product_type === OfferProductType.REVENUE_BASED
          ? offer.sweep_terms
          : t('pages.offers.card.borrowed.description.line2')}{' '}
        {offer.product_type !== OfferProductType.REVENUE_BASED && (
          <>
            {t('pages.offers.card.borrowed.description.line3')} {offer.duration_in_months}{' '}
            {t('pages.offers.card.borrowed.description.line4')}
          </>
        )}
      </Typography>

      {/* {isIwocaOffer && (
        <Typography className={classes.drawdownLinkDescription}>
          {t('pages.offers.card.borrowed.drawdownLink')}
        </Typography>
      )} */}
    </Paper>
  ) : (
    <CollapsibleCard
      buttonTitle={t('pages.offers.card.buttons.select')}
      {...rest}
      active={active}
      handleExpandClick={handleExpandClick}
      titleClassName={classes.cardTitle}
      withDivider
      withHeaderChange={false}
      mainActionDisabled={mainActionDisabled}
      Icon={lenderLogo}
    >
      {withContent && <OfferContent {...offer} detailsRequested={detailsRequested} />}

      {offer.notes && (
        <Box marginTop="20px">
          <Alert severity="info">
            <AlertTitle>{t('pages.offers.card.notesTitle')}</AlertTitle>
            {offer.notes}
          </Alert>
        </Box>
      )}
    </CollapsibleCard>
  );
};

export default OfferCard;
