import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(-2),
      '& svg': {
        transform: 'scale(1.4)',
      },
    },
    inputTitle: {
      fontSize: theme.typography.pxToRem(19),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
    },
    inputDesc: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.greyText.main,
    },
    labelContainer: {
      marginLeft: theme.spacing(1.5),
    },
  }),
);
