import { Box, Button, Chip, CircularProgress, Grid, InputAdornment, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Field, Form, Formik, FormikErrors, FormikProps, FormikTouched } from 'formik';
import moment, { Moment } from 'moment';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Alert } from '@material-ui/lab';
import { AmountNumberInput } from 'components/AmountNumberInput';
import {
  CustomDatePicker,
  CustomInputError,
  CustomLabel,
  CustomNumberFormat,
  CustomRadioField,
  CustomSelect,
  CustomTextField,
} from 'components/inputs';
import { Layout } from 'components/Layout';
import { Terms } from 'components/Terms';
import {
  applicationRegionOptions,
  companyIndustryOptions,
  fundingReasonOptions,
  onlineSalesOptions,
} from 'core/constants';
import analytics from 'core/services/analytics';
import useCommonStyles from 'core/styles';
import { ApplicationRegion, AreaPhoneCode, CompanyTypes, SupportedCountryCode } from 'core/types';
import { useDebounce } from 'hooks';
import { searchCompanies as searchCompaniesApi } from 'http/onboard';
import routes from 'router/routes';
import { useAppState } from 'store/app/hooks';
import { useAuth } from 'store/auth/hooks';
import { useOnboard } from 'store/onboard/hooks';
import { Applicant, Company, CompanySearchResult } from 'store/onboard/types';
import {
  composeDirectorName,
  mapAppRegionToAreaPhoneCode,
  mapAppRegionToCountryCode,
  parseCompaniesHouseApiType,
  parseObject,
} from 'utils';
import { mapAppRegionToCurrencySymbol } from 'utils/currency';

import useStyles from './PreApply.styles';
import {
  EINNumberInput,
  FormValidation,
  FormValues,
  getFormSchema,
  getInitialValues,
  PhoneNumberInput,
  SEARCH_DEBOUNCE_DELAY,
} from './utils';

interface FormItemProps {
  values: FormValues;
  isUKCompany: boolean;
  isIrishCompany: boolean;
  isUSACompany: boolean;
  touched: FormikTouched<FormValues>;
  setValues: (values: React.SetStateAction<FormValues>, shouldValidate?: boolean) => void;
  submitCount: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: FormikErrors<FormValues>;
  handleChange: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: React.ChangeEvent<any>): void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: string | React.ChangeEvent<any>) => void;
  };
}

const PreApply: FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<CompanySearchResult[]>([]);
  const [selectedOption, setSelectedOption] = useState<CompanySearchResult | null>(null);
  const formRef = useRef<FormikProps<FormValues> | null>(null);
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY);
  const {
    company,
    applicant,
    searchingDirectors,
    searchDirectors,
    clearDirectors,
    setCompanyDetails,
    setApplicantDetails,
    verifyEmail,
  } = useOnboard();
  const { email: authEmail } = useAuth();
  const { region: appRegion, setAppRegion, skipHomePage, termsAgreed } = useAppState();

  useEffect(() => {
    analytics.track(routes.preApply);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // When user comes back after clicking "Get prequalified"
    if (company.registered_name && company.registered_number && appRegion === ApplicationRegion.UK) {
      setSelectedOption({
        title: company.registered_name,
        companyNumber: company.registered_number,
        incorporatedOn: company.formation_date as string,
        companyType: company.type as CompanyTypes,
        companyStatus: company.company_status as string,
        companyAddress: company.address,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchCompanies = useCallback(async () => {
    setLoadingOptions(true);
    try {
      const data = await searchCompaniesApi(debouncedSearchTerm);
      setOptions(data);
    } catch (error) {
      /** */
    }
    setLoadingOptions(false);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm !== '') {
      searchCompanies();
      return;
    }
    setOptions([]);
  }, [debouncedSearchTerm, searchCompanies]);

  useEffect(() => {
    if (company.directors?.length === 1) {
      const currentValues = formRef.current?.values;
      if (!currentValues) return;

      const { id, first_name, last_name } = company.directors[0];
      formRef.current?.setValues({ ...currentValues, director_id: id, first_name, last_name });
    }
  }, [company.directors]);

  const onSubmit = (values: FormValues) => {
    const isUKCompany = values.region === ApplicationRegion.UK;
    const isUSACompany = values.region === ApplicationRegion.USA;
    const isIrishCompany = values.region === ApplicationRegion.IRL;

    const onlineSalesPercentage = values.online_sales_revenue_percentage || 0;

    const companyValues: Partial<Company> = {
      registered_name: values.registered_name,
      registered_number: isUSACompany ? null : values.registered_number,
      formation_date: values.formation_date,
      funding_amount: values.funding_amount,
      funding_reasons: values.funding_reasons,
      monthly_revenue: values.monthly_revenue,
      online_sales_revenue_percentage: onlineSalesPercentage > 1 ? onlineSalesPercentage / 100 : onlineSalesPercentage,
      ein: values.ein,
      industry: values.industry,
      region: mapAppRegionToCountryCode(values.region),
    };

    if (isUKCompany && selectedOption) {
      companyValues.type = selectedOption.companyType
        ? parseCompaniesHouseApiType(selectedOption.companyType)
        : CompanyTypes.LTD;
      companyValues.company_status = selectedOption.companyStatus;

      if (selectedOption.companyAddress) {
        companyValues.address = {
          ...selectedOption.companyAddress,
          country_code: selectedOption.companyAddress.country_code ?? SupportedCountryCode.UNITED_KINGDOM,
        };
      }
    }

    let applicantValues: Partial<Applicant> = {
      id: values.director_id, // temp "id", for filtering in confirm code page
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: null,
    };

    if (isUSACompany) applicantValues.phone_number = `${AreaPhoneCode.US}-${values.phone_number}`;
    if (isIrishCompany) applicantValues.phone_number = `${AreaPhoneCode.IE}-${values.phone_number}`;

    const selectedDirector = company.directors?.find((dir) => dir.id === values.director_id);
    if (isUKCompany) {
      if (selectedDirector) {
        const props = {
          nationality: 1,
          country_of_residence: 1,
          role: 1,
          occupation: 1,
          appointed_on: 1,
          resigned_on: 1,
          addresses: 1,
        };
        applicantValues = { ...parseObject(selectedDirector, props), ...applicantValues };
        if (applicantValues.addresses && applicantValues.addresses[0]) {
          // These values are needed to pass validation checks on backend
          const address = { ...applicantValues.addresses[0] };
          address.current = true;
          address.country_code = address.country_code ?? SupportedCountryCode.UNITED_KINGDOM;
          applicantValues.addresses = [address, ...applicantValues.addresses.slice(1)];
        }
      } else {
        applicantValues.addresses = [
          {
            id: null,
            house_number: null,
            line_1: null,
            line_2: null,
            city: null,
            county: null,
            postcode: null,
            start_date: null,
            status: null,
            property_equity: null,
            country_code: SupportedCountryCode.UNITED_KINGDOM,
            current: true,
          },
        ];
      }
    }

    const registeredNumber = isUSACompany ? companyValues.ein : companyValues.registered_number;

    setCompanyDetails(companyValues);
    setApplicantDetails(applicantValues);
    verifyEmail({
      email: applicantValues.email as string,
      registeredNumber: registeredNumber as string,
      region: values.region,
      redirect: true,
    });
  };

  const handleDirectorChange = (value: string, values: FormValues, setValues: (values: FormValues) => void) => {
    const selectedDirector = company.directors?.find((dir) => dir.id === value);
    if (!selectedDirector) return;

    const updatedValues = {
      ...values,
      director_id: selectedDirector.id,
      first_name: selectedDirector.first_name,
      last_name: selectedDirector.last_name,
    };
    setValues(updatedValues);
  };

  const goBack = () => {
    history.push(routes.home);
  };

  const goToSignIn = () => {
    history.push(routes.signIn);
  };

  const initialValues = getInitialValues(company, applicant, appRegion, authEmail);

  const companyDirectorsOptions = useMemo(() => {
    if (!company.directors) return [];
    return company.directors.map((d) => ({
      value: d.id,
      label: composeDirectorName(d.last_name ?? '', d.first_name ?? ''),
    }));
  }, [company.directors]);

  const formSchema = useMemo(() => getFormSchema(companyDirectorsOptions.length > 0), [companyDirectorsOptions.length]);

  const FormItems: React.FunctionComponent<FormItemProps> = ({
    isUKCompany,
    values,
    setValues,
    isIrishCompany,
    handleChange,
    setFieldValue,
    touched,
    errors,
    isUSACompany,
    submitCount,
  }) => (
    <>
      {isUKCompany && (
        <>
          {searchingDirectors && (
            <Grid item className={classes.loadingContainer}>
              <CircularProgress size={25} />
            </Grid>
          )}

          {values.registered_name && companyDirectorsOptions.length > 0 && (
            <Grid item>
              <Field
                id="director_id"
                aria-label="directors-choice"
                component={CustomRadioField}
                options={companyDirectorsOptions}
                name="director_id"
                value={values.director_id || ''}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleDirectorChange(event.target.value, values, setValues)
                }
                title={t('pages.preApply.inputs.director.label')}
                className={commonClasses.textInput}
              />
            </Grid>
          )}
        </>
      )}

      {isIrishCompany && (
        <Grid item>
          <Field
            id="registered_number"
            fullWidth
            component={CustomTextField}
            name="registered_number"
            value={values.registered_number || ''}
            onChange={handleChange}
            title={t('pages.preApply.inputs.registered_number.label')}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t('pages.preApply.inputs.registered_number.placeholder')}
            className={commonClasses.textInput}
          />
        </Grid>
      )}

      <Grid item>
        <Field
          id="funding_amount"
          fullWidth
          component={CustomTextField}
          name="funding_amount"
          value={values.funding_amount}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue('funding_amount', Number(event.target.value))
          }
          title={t('pages.preApply.inputs.funding_amount.label')}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: AmountNumberInput,
          }}
          className={classes.fundingAmountInput}
          variant="outlined"
        />
      </Grid>

      <Grid item>
        <CustomLabel
          title={
            <>
              <span>{t('pages.preApply.inputs.funding_reasons.label.line1')}</span>{' '}
              <strong>{t('pages.preApply.inputs.funding_reasons.label.line2')}</strong>
            </>
          }
        />

        <Box className={classes.fundingReasonsContainer}>
          {fundingReasonOptions.map((option) => {
            const alreadySelected = values.funding_reasons.find((v) => v === option.value);
            const firstSelected = values.funding_reasons[0] === option.value;
            const color = () => {
              if (alreadySelected && firstSelected) {
                return 'primary';
              }

              if (alreadySelected && !firstSelected) {
                return 'secondary';
              }

              return 'default';
            };
            return (
              <Chip
                id={option.value}
                key={option.value}
                color={color()}
                label={option.label}
                onClick={() => {
                  if (alreadySelected) {
                    setFieldValue(
                      'funding_reasons',
                      values.funding_reasons.filter((v) => v !== option.value),
                    );
                  } else {
                    setFieldValue('funding_reasons', [...values.funding_reasons, option.value]);
                  }
                }}
                className={classes.fundingReasonOption}
              />
            );
          })}
        </Box>

        {touched.funding_reasons && errors.funding_reasons && (
          <CustomInputError message={errors.funding_reasons as string} />
        )}
      </Grid>

      <Grid item>
        <Field
          id="first_name"
          fullWidth
          component={CustomTextField}
          name="first_name"
          value={values.first_name || ''}
          onChange={handleChange}
          title={t('pages.preApply.inputs.first_name.label')}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t('pages.preApply.inputs.first_name.placeholder')}
          className={commonClasses.textInput}
        />
      </Grid>

      <Grid item>
        <Field
          id="last_name"
          fullWidth
          component={CustomTextField}
          name="last_name"
          value={values.last_name || ''}
          onChange={handleChange}
          title={t('pages.preApply.inputs.last_name.label')}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t('pages.preApply.inputs.last_name.placeholder')}
          className={commonClasses.textInput}
        />
      </Grid>

      <Grid item>
        <Field
          id="email"
          fullWidth
          component={CustomTextField}
          name="email"
          value={values.email || ''}
          onChange={handleChange}
          title={t('pages.preApply.inputs.email.label')}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={t('pages.preApply.inputs.email.placeholder')}
          className={commonClasses.textInput}
        />
      </Grid>

      {!isUKCompany && (
        <Grid item>
          <CustomDatePicker
            id="formation_date"
            name="formation_date"
            label={t('pages.preApply.inputs.formation_date.label')}
            value={values.formation_date ? moment(values.formation_date).toDate() : null}
            onChange={(date: Moment | null) => setFieldValue('formation_date', date?.toDate() ?? null)}
            clearable
            error={submitCount > 0 ? errors.formation_date : undefined}
            disableFuture
          />
        </Grid>
      )}

      <Grid item>
        <Field
          id="monthly_revenue"
          fullWidth
          component={CustomTextField}
          name="monthly_revenue"
          value={values.monthly_revenue}
          onChange={handleChange}
          title={t('pages.preApply.inputs.monthly_revenue.label')}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{mapAppRegionToCurrencySymbol(values.region)}</InputAdornment>
            ),
            inputComponent: CustomNumberFormat,
          }}
          className={commonClasses.textInput}
        />
      </Grid>

      {!isUKCompany && (
        <Grid item>
          <Field
            id="phone_number"
            fullWidth
            component={CustomTextField}
            name="phone_number"
            value={values.phone_number}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue('phone_number', event.target.value?.trim())
            }
            title={t('pages.preApply.inputs.phone_number.label')}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{mapAppRegionToAreaPhoneCode(values.region)}</InputAdornment>
              ),
              inputComponent: PhoneNumberInput,
            }}
            className={commonClasses.textInput}
          />
        </Grid>
      )}

      {isUSACompany && (
        <>
          <Grid item>
            <Field
              id="ein"
              fullWidth
              component={CustomTextField}
              name="ein"
              value={values.ein}
              onChange={handleChange}
              title={t('pages.preApply.inputs.ein.label')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: EINNumberInput,
              }}
              className={commonClasses.textInput}
            />
          </Grid>

          <Grid item>
            <Field
              id="industry"
              fullWidth
              component={CustomSelect}
              options={companyIndustryOptions}
              name="industry"
              value={values.industry ?? ''}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('industry', event.target.value)}
              title={t('pages.preApply.inputs.industry.label')}
              className={commonClasses.textInput}
              optional
            />
          </Grid>
        </>
      )}

      <Grid item>
        <CustomLabel title={t('pages.fundingGoal.inputs.online_sales_revenue_percentage.label')} />

        <Box className={classes.fundingReasonsContainer}>
          {onlineSalesOptions.map((option) => {
            return (
              <Chip
                key={option.label}
                id={option.label}
                label={option.label}
                onClick={() => setFieldValue('online_sales_revenue_percentage', option.value)}
                color={values.online_sales_revenue_percentage === option.value ? 'primary' : 'default'}
              />
            );
          })}
        </Box>

        {touched.online_sales_revenue_percentage && errors.online_sales_revenue_percentage && (
          <CustomInputError message={errors.online_sales_revenue_percentage as string} />
        )}
      </Grid>
    </>
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={formSchema}
      innerRef={formRef}
      validateOnChange={false}
    >
      {({ values, errors, touched, submitCount, handleChange, setFieldValue, handleSubmit, setValues }) => {
        const alreadyActiveApplication = /active application/i.test(errors.registered_number ?? '');
        // const notActive = Boolean(errors.company_status);
        const entity = alreadyActiveApplication ? 'company' : 'email';
        const isUKCompany = values.region === ApplicationRegion.UK;
        const isUSACompany = values.region === ApplicationRegion.USA;
        const isIrishCompany = values.region === ApplicationRegion.IRL;

        return (
          <Layout
            headerTitle={t('pages.preApply.header')}
            onMainAction={handleSubmit}
            mainButtonTitle={t('pages.preApply.buttons.main')}
            MainActionMessage={!alreadyActiveApplication && skipHomePage ? <Terms /> : undefined}
            onGoBack={goBack}
            showSidebar={false}
            mainButtonDisabled={alreadyActiveApplication || (!termsAgreed && skipHomePage)}
            secondaryButtonTitle={t('pages.signIn.buttons.main')}
            withBackButton={!skipHomePage}
          >
            <Typography variant="h1" className={commonClasses.title2}>
              {t('pages.preApply.title')}
            </Typography>

            <Typography className={commonClasses.subtitle2}>{t('pages.preApply.description')}</Typography>

            <Form noValidate className={commonClasses.form}>
              <FormValidation />

              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <CustomLabel title={<span>{t('pages.preApply.inputs.region.label')}</span>} />

                  <Box className={classes.fundingReasonsContainer}>
                    {applicationRegionOptions.map((option) => {
                      return (
                        <Chip
                          id={option.value}
                          key={option.value}
                          color={values.region === option.value ? 'primary' : 'default'}
                          label={option.label}
                          onClick={() => {
                            setFieldValue('region', option.value);
                            setAppRegion(option.value);
                          }}
                          className={classes.fundingReasonOption}
                        />
                      );
                    })}
                  </Box>

                  {touched.region && errors.region && <CustomInputError message={errors.region as string} />}
                </Grid>

                <Grid item>
                  {isUKCompany ? (
                    <>
                      <Autocomplete
                        id="registered_name"
                        value={selectedOption}
                        open={autoCompleteOpen}
                        onOpen={() => setAutoCompleteOpen(true)}
                        onClose={() => setAutoCompleteOpen(false)}
                        onChange={(e, value) => {
                          if (typeof value === 'string') return;
                          setSelectedOption(value);
                          if (value?.companyNumber) searchDirectors(value.companyNumber);
                          else clearDirectors();
                          setValues({
                            ...values,
                            director_id: null,
                            first_name: null,
                            last_name: null,
                            registered_name: value?.title ?? null,
                            registered_number: value?.companyNumber ?? null,
                            formation_date: value?.incorporatedOn ?? null,
                            company_status: value?.companyStatus,
                          });
                        }}
                        onInputChange={(e, value) => setSearchTerm(value)}
                        options={options}
                        loading={loadingOptions}
                        getOptionSelected={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        renderInput={(props) => (
                          <Field
                            {...props}
                            name="registered_name"
                            component={CustomTextField}
                            title={t('pages.preApply.inputs.registered_name.label')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder={t('pages.preApply.inputs.registered_name.placeholder')}
                            // show error as soon as validation fails
                            // error={alreadyActiveApplication || notActive}
                          />
                        )}
                        className={commonClasses.textInput}
                        popupIcon={<ExpandMore className={commonClasses.popupIcon} />}
                        freeSolo={debouncedSearchTerm === '' && options.length === 0}
                      />
                      {alreadyActiveApplication && (
                        <Box marginBottom="20px" marginTop="10px">
                          <Alert severity="info">
                            {t('pages.preApply.alertInfo', { entity })}
                            <Box marginTop="10px">
                              <Button variant="outlined" color="primary" onClick={goToSignIn} id="goSignInButtton">
                                {t('pages.signIn.buttons.main')}
                              </Button>
                            </Box>
                          </Alert>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Field
                      id="registered_name"
                      fullWidth
                      component={CustomTextField}
                      name="registered_name"
                      value={values.registered_name || ''}
                      onChange={handleChange}
                      title={t('pages.preApply.inputs.registered_name.label')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder={t('pages.preApply.inputs.registered_name.placeholder')}
                      className={commonClasses.textInput}
                    />
                  )}
                </Grid>

                {isUKCompany && !alreadyActiveApplication && (
                  <FormItems
                    isUKCompany={isUKCompany}
                    isIrishCompany={isIrishCompany}
                    setValues={setValues}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    isUSACompany={isUSACompany}
                    submitCount={submitCount}
                  />
                )}

                {(isIrishCompany || isUSACompany) && (
                  <FormItems
                    isUKCompany={isUKCompany}
                    isIrishCompany={isIrishCompany}
                    setValues={setValues}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    errors={errors}
                    isUSACompany={isUSACompany}
                    submitCount={submitCount}
                  />
                )}
              </Grid>
            </Form>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default PreApply;
