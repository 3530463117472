import { FC, useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'store/auth/hooks';
import { MessageAlert, AlertDialog } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import { Loader } from 'components/Loader';
import { usePrevious } from 'hooks';
import analytics from 'core/services/analytics';
import { useOnboard } from 'store/onboard/hooks';
import { composeDirectorName } from 'utils';
import { platformSupportEmail } from 'core/constants';
import ScrollToTop from './ScrollToTop';
import {
  Home,
  CompanyInfo,
  ApplicantInfo,
  FundingGoal,
  FundingResult,
  CustomiseGoal,
  AdditionalInfo,
  Offers,
  CreditAgreement,
  BankDetails,
  Finish,
  SignIn,
  ConfirmCode,
  AcceptedOffer,
  InvalidKey,
  Rejected,
  Kyc,
  Logout,
  PreApply,
  UserApps,
} from '../pages';
import routes from './routes';
import { useAppState } from '../store/app/hooks';
import { history } from './service';
import useStyles from './styles';

const AppRouter: FC<{ onNotNow(): void }> = ({ onNotNow }) => {
  const [errorOpened, setErrorOpened] = useState(false);
  const { getApplication, error: appError, loading: appLoading, application_id } = useAppState();
  const { accessToken } = useAuth();
  const { t } = useTranslation();
  const classes = useStyles();
  const { error: globalError, getFaqs } = useGlobalState();
  const { applicant, company } = useOnboard();
  const prevApplicantId = usePrevious(applicant.id);

  useEffect(() => {
    getFaqs();
  }, [getFaqs]);

  useEffect(() => {
    if (prevApplicantId !== applicant.id && applicant.id) {
      analytics.identify(applicant.id);
      analytics.people.set({
        $name: composeDirectorName(applicant.first_name ?? '', applicant.last_name ?? ''),
        $applicantId: applicant.id,
        $companyId: company.id,
        $applicationId: application_id,
        $email: applicant.email,
      });
    }
  }, [applicant, application_id, company, prevApplicantId]);

  useEffect(() => {
    if (accessToken) {
      getApplication();
    }
  }, [accessToken, getApplication]);

  /**
   * Global error is meant to handle server (500) or network issues,
   * but app error is also important, because if the application state
   * is not restored the widget will work with issues
   */
  const error = globalError || appError;

  useEffect(() => {
    if (error) {
      setErrorOpened(true);
      return;
    }
    setErrorOpened(false);
  }, [error]);

  const handleConfirm = () => {
    window.open(`mailto:${platformSupportEmail}?subject=${t('global.email.supportSubject')}`);
  };

  const handleCancel = () => {
    setErrorOpened(false);
  };

  return (
    <Router history={history}>
      <ScrollToTop />

      {!appLoading && (
        <Switch>
          <Route path={routes.invalidKey} component={InvalidKey} />
          <Route exact path={routes.home} component={() => <Home onNotNow={onNotNow} />} />

          <Route path={routes.signIn} component={SignIn} />
          <Route path={routes.confirmCode} component={ConfirmCode} />
          <Route path={routes.userApps} component={UserApps} />
          <Route path={routes.preApply} component={PreApply} />

          <Route path={routes.companyInfo} component={CompanyInfo} />
          <Route path={routes.applicantInfo} component={ApplicantInfo} />
          <Route path={routes.fundingGoal} component={FundingGoal} />

          <Route path={routes.fundingResult} component={FundingResult} />
          <Route path={routes.additionalInfo} component={AdditionalInfo} />
          <Route path={routes.kyc} component={Kyc} />
          <Route path={routes.customiseGoal} component={CustomiseGoal} />

          <Route exact path={routes.offers} component={() => <Offers onNotNow={onNotNow} />} />
          <Route path={routes.acceptedOffer} component={AcceptedOffer} />

          <Route path={routes.detailsRequested} component={AdditionalInfo} />
          <Route path={routes.logout} component={Logout} />

          {/* NOTE: Unused routes - left just for future use/reference */}
          <Route path={routes.creditAgreement} component={CreditAgreement} />
          <Route path={routes.bankDetails} component={BankDetails} />
          <Route path={routes.rejected} component={Rejected} />
          <Route path={routes.finish} component={Finish} />
        </Switch>
      )}

      {appLoading && <Loader visible />}

      {error && !errorOpened && (
        <MessageAlert
          open
          message={t('global.globalErrorMessage')}
          autoHideDuration={null}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          containerClassName={classes.alertContainer}
        />
      )}

      <AlertDialog
        open={errorOpened}
        dialogContentTitle={t('global.globalError')}
        dialogContentText={t('global.globalErrorMessage')}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        confirmText={t('global.globalErrorAction')}
      />
    </Router>
  );
};

export default AppRouter;
