import { FC } from 'react';
import { Box, InputLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CalendarToday } from '@material-ui/icons';
import { FormikErrors } from 'formik';
import moment from 'moment';

import useStyles from './CustomDatePicker.styles';
import { CustomLabel } from '../CustomLabel';
import { CustomInputError } from '../CustomInputError';

interface CustomDatePickerProps {
  id: string;
  name: string;
  label: string;
  value: Date | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (date: any) => void;
  tooltipMessage?: string;
  error?: FormikErrors<Date>;
  clearable?: boolean;
  optional?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  tooltipMessage,
  error,
  clearable = false,
  optional = false,
  disableFuture = false,
  disablePast = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <InputLabel htmlFor={id}>
        <CustomLabel title={label} tooltipMessage={tooltipMessage} optional={optional} />
      </InputLabel>

      <KeyboardDatePicker
        value={value}
        onChange={onChange}
        fullWidth
        clearable={clearable}
        disableFuture={disableFuture}
        format="DD/MM/YYYY"
        margin="normal"
        name={name}
        openTo="year"
        keyboardIcon={<CalendarToday />}
        placeholder="dd/mm/yyyy"
        maxDateMessage="Date can not be in the future"
        helperText={value !== null ? moment(value).format('Do MMMM YYYY') : 'dd/mm/yyyy'}
        disablePast={disablePast}
      />

      {error && <CustomInputError message={error as string} />}
    </Box>
  );
};

export default CustomDatePicker;
