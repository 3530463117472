/* eslint-disable func-names */
import * as yup from 'yup';

import { Address } from '../../../store/onboard/types';

yup.addMethod<yup.ArraySchema<yup.AnySchema<Partial<Address>>>>(
  yup.array,
  'uniqueCurrent',
  function (message, mapper = (a: Partial<Address>) => a) {
    return this.test('uniqueCurrent', message, function (list) {
      const currentList = list || [];
      if (currentList.filter(mapper).length !== 1) {
        return this.createError({ path: 'addresses.0.current', message });
      }
      return true;
    });
  },
);

yup.addMethod<yup.ArraySchema<yup.AnySchema<Partial<Address>>>>(
  yup.array,
  'oneCurrent',
  function (message, mapper = (a: Partial<Address>) => a) {
    return this.test('oneCurrent', message, function (list) {
      const currentList = list || [];
      if (!currentList.some(mapper)) {
        return this.createError({ path: 'addresses.0.current', message });
      }
      return true;
    });
  },
);

declare module 'yup' {
  interface ArraySchema<T> {
    uniqueCurrent(this: ArraySchema<T>, ...ignoredKeys: unknown[]): ArraySchema<T>;
    oneCurrent(this: ArraySchema<T>, ...ignoredKeys: unknown[]): ArraySchema<T>;
  }
}

export default yup;
