import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { setError as setGlobalError } from 'store/global/actions';
import { createApplication } from 'store/onboard/actions';
import { ApplicationRegion } from 'core/types';
import { AppState } from './types';
import {
  updateAppState,
  getApplication,
  clearApplication,
  setError,
  setAppRegion,
  showPolicy,
  showTerms,
  agreeTerms,
} from './actions';

const defaultCaptchaEnabled = process.env.REACT_APP_DEFAULT_CAPTCHA_ENABLED || 'true';
const initialCaptchaEnabled = defaultCaptchaEnabled === 'true';

export const initialState: AppState = {
  widget: false,
  publicKey: null,
  height: '100%',
  width: '100%',
  logoUrl: undefined,
  letterLogoUrl: undefined,
  partnerName: undefined,
  captchaEnabled: initialCaptchaEnabled,
  kycEnabled: false,
  popup: false,
  exitEnabled: true,
  hostDomain: null,
  faqEnabled: true,
  homeTitle: undefined,
  homeSubTitle: undefined,
  skipHomePage: false,
  customCalendlyLink: undefined,

  loading: false,
  error: false,
  status: null,
  track: null,
  rejection: null,
  details_requested: [],
  application_id: null,
  region: ApplicationRegion.UK,

  termsVisible: false,
  policyVisible: false,
  termsAgreed: undefined,
};

const appStore = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
    builder.addCase(
      updateAppState,
      (
        state,
        {
          payload: {
            widget,
            publicKey,
            height,
            width,
            logoUrl,
            letterLogoUrl,
            partnerName,
            captchaEnabled,
            kycEnabled,
            popup,
            exitEnabled,
            hostDomain,
            faqEnabled,
            homeTitle,
            homeSubTitle,
            skipHomePage,
            customCalendlyLink,
          },
        },
      ) => {
        if (typeof widget === 'boolean') state.widget = widget;
        if (typeof captchaEnabled === 'boolean') state.captchaEnabled = captchaEnabled;
        if (typeof kycEnabled === 'boolean') state.kycEnabled = kycEnabled;
        if (typeof exitEnabled === 'boolean') state.exitEnabled = exitEnabled;
        if (publicKey) state.publicKey = publicKey;
        if (height) state.height = height;
        if (width) state.width = width;
        if (logoUrl) state.logoUrl = logoUrl;
        if (letterLogoUrl) state.letterLogoUrl = letterLogoUrl;
        if (partnerName) state.partnerName = partnerName;
        if (hostDomain) state.hostDomain = hostDomain;
        if (typeof faqEnabled === 'boolean') state.faqEnabled = faqEnabled;
        if (homeTitle) state.homeTitle = homeTitle;
        if (homeSubTitle) state.homeSubTitle = homeSubTitle;
        if (skipHomePage) state.skipHomePage = skipHomePage;
        if (customCalendlyLink) state.customCalendlyLink = customCalendlyLink;

        state.popup = !!popup;
      },
    );

    builder.addCase(getApplication.pending, (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addCase(getApplication.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.status = payload.status;
      if (payload.application_rejection) {
        state.rejection = payload.application_rejection;
      }
      if (payload.details_requested) {
        state.details_requested = payload.details_requested;
      }
      state.application_id = payload.application_id;
      state.region = payload.region;
    });

    builder.addCase(getApplication.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message || true;
    });

    builder.addCase(clearApplication, (state) => ({ ...initialState, publicKey: state.publicKey }));

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setGlobalError, (state, { payload }) => {
      if (!payload) {
        state.error = payload;
      }
    });

    builder.addCase(createApplication.fulfilled, (state, { payload }) => {
      state.region = payload.region;
    });

    builder.addCase(setAppRegion, (state, { payload }) => {
      state.region = payload;
    });

    builder.addCase(showPolicy, (state, { payload }) => {
      state.policyVisible = payload;
    });

    builder.addCase(showTerms, (state, { payload }) => {
      state.termsVisible = payload;
    });

    builder.addCase(agreeTerms, (state, { payload }) => {
      state.termsAgreed = payload;
    });
  },
});

export default appStore.reducer;
