import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator, usePrevious } from '../../hooks';
import { RootState } from '../types';
import { OnboardHookReturn } from './types';
import {
  patchCompany,
  patchApplicant,
  prequalifyCompany,
  clearError as clearErrorAction,
  saveDocument,
  removeDocument,
  setDocAlert,
  gatherOffers,
  setCompanyDetails,
  createApplication,
  searchDirectors,
  getCompanyInfo,
  validateReCaptcha,
  verifyEmail,
  setApplicantDetails,
  clearOnboardDetails,
  setError,
  clearDirectors,
  setHideOptionalFields,
  setSuccess,
} from './actions';

export const onboardLoadingSelector = (state: RootState): boolean => state.onboard.loading;

export const onboardErrorSelector = (state: RootState): boolean | string => state.onboard.error;

export const useOnboard = (onSuccess?: () => void, onError?: () => void, noError = false): OnboardHookReturn => {
  const { loading, error, ...rest } = useSelector((state: RootState) => state.onboard, shallowEqual);
  const prevLoading = usePrevious(loading);

  const clearError = useActionCreator(clearErrorAction);

  useEffect(() => {
    if (prevLoading && !loading) {
      if (!error && onSuccess) {
        onSuccess();
      } else if (error && onError) {
        if (noError) clearError();
        onError();
      }
    }
  }, [prevLoading, loading, error, onSuccess, onError, clearError, noError]);

  return {
    loading,
    error,
    ...rest,
    clearError,
    patchCompany: useActionCreator(patchCompany),
    patchApplicant: useActionCreator(patchApplicant),
    prequalifyCompany: useActionCreator(prequalifyCompany),
    saveDocument: useActionCreator(saveDocument),
    removeDocument: useActionCreator(removeDocument),
    setDocAlert: useActionCreator(setDocAlert),
    gatherOffers: useActionCreator(gatherOffers),
    setCompanyDetails: useActionCreator(setCompanyDetails),
    createApplication: useActionCreator(createApplication),
    searchDirectors: useActionCreator(searchDirectors),
    getCompanyInfo: useActionCreator(getCompanyInfo),
    validateReCaptcha: useActionCreator(validateReCaptcha),
    verifyEmail: useActionCreator(verifyEmail),
    setApplicantDetails: useActionCreator(setApplicantDetails),
    clearOnboardDetails: useActionCreator(clearOnboardDetails),
    setError: useActionCreator(setError),
    clearDirectors: useActionCreator(clearDirectors),
    setHideOptionalFields: useActionCreator(setHideOptionalFields),
    setSuccess: useActionCreator(setSuccess),
  };
};
