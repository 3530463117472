import { createSlice, ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';
import { CompanyDocumentType } from 'core/types';

import { signIn } from 'store/auth/actions';
import { getApplication } from 'store/app/actions';
import { clearApplication } from '../app/actions';
import {
  patchCompany,
  patchApplicant,
  prequalifyCompany,
  clearError,
  saveDocument,
  removeDocument,
  setDocAlert,
  gatherOffers,
  searchDirectors,
  setCompanyDetails,
  getCompanyInfo,
  validateReCaptcha,
  verifyEmail,
  setApplicantDetails,
  clearOnboardDetails,
  setError,
  clearDirectors,
  createApplication,
  setHideOptionalFields,
  setSuccess,
} from './actions';
import { OnboardState } from './types';

export const initialState: OnboardState = {
  loading: false,
  error: false,
  success: false,

  company: {
    id: null,
    trading_name: null,
    registered_name: null,
    registered_number: null,
    type: null,
    region: null,
    website: null,
    formation_date: null,
    trading_from_date: null,
    sic_codes: [],
    address: null,

    vat_registered: false,
    vat_number: null,
    vat_registration_date: null,

    funding_amount: null,
    funding_reasons: [],
    funding_date: null,
    monthly_revenue: null,
    year_to_date_revenue: null,
    online_sales_revenue_percentage: null,
    repeat_customers_revenue_percentage: null,

    ein: null,
    industry: null,

    any_ccj: false,
    ccjs: [],

    directors: [],
    application_id: null,
  },

  applicant: {
    id: null,
    first_name: null,
    last_name: null,
    email: null,
    area_code: null,
    phone_number: null,
    date_of_birth: null,
    addresses: [],
  },

  prequalifyResult: null,

  additionalInfo: {
    [CompanyDocumentType.BANK_STATEMENT]: [],
    [CompanyDocumentType.PAYMENT_DATA]: [],
    [CompanyDocumentType.MANAGEMENT_ACCOUNTS]: [],
    [CompanyDocumentType.FILED_ACCOUNTS]: [],
    [CompanyDocumentType.VAT_RETURN]: [],
    [CompanyDocumentType.IDENTITY_DOCUMENT]: [],
  },

  documentUploadSuccess: false,
  searchingDirectors: false,
  hideOptionalFields: false,
};

const onboardStore = createSlice({
  name: 'onboard',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<OnboardState>) => {
    builder.addCase(patchCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = { ...state.company, ...payload };
    });

    builder.addCase(patchApplicant.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.applicant = { ...state.applicant, ...payload };
      }
    });

    builder.addCase(prequalifyCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.prequalifyResult = payload.prequalifyResult;
        state.company = { ...state.company, ...payload.company };
      }
    });

    builder.addCase(clearError, (state) => {
      state.error = false;
    });

    builder.addCase(saveDocument, (state, { payload: { documents, type } }) => {
      const currentSavedDocument = state.additionalInfo[type];

      state.additionalInfo[type] = [
        ...currentSavedDocument.filter((item) => documents.every((doc) => !item.name.endsWith(doc.name))),
        ...documents,
      ];

      state.documentUploadSuccess = true;
    });

    builder.addCase(removeDocument, (state, { payload: { documentId, type } }) => {
      const currentSavedDocument = state.additionalInfo[type];
      state.additionalInfo[type] = currentSavedDocument.filter((doc) => doc.id !== documentId);
    });

    builder.addCase(setDocAlert, (state, { payload }) => {
      state.documentUploadSuccess = payload;
    });

    builder.addCase(gatherOffers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company.funding_amount = payload;
    });

    builder.addCase(searchDirectors.pending, (state) => {
      state.searchingDirectors = true;
      state.error = false;
    });

    builder.addCase(searchDirectors.rejected, (state, { error }) => {
      state.searchingDirectors = false;
      state.error = error?.message || true;
    });

    builder.addCase(searchDirectors.fulfilled, (state, { payload }) => {
      state.searchingDirectors = false;
      state.company.directors = payload;
    });

    builder.addCase(clearDirectors, (state) => {
      state.company.directors = [];
    });

    builder.addCase(setCompanyDetails, (state, { payload }) => {
      state.company = { ...state.company, ...payload };
    });

    builder.addCase(getApplication.fulfilled, (state, { payload }) => {
      state.company = {
        ...payload.company,
        funding_date: payload.funding_date ?? null,
        sic_codes: payload.company.sic_codes ?? [],
        // If not present, preserve these values from the pre-prequalify step
        funding_amount: payload.funding_amount ?? state.company.funding_amount,
        funding_reasons: payload.funding_reasons ?? state.company.funding_reasons,
        monthly_revenue: payload.company.monthly_revenue ?? state.company.monthly_revenue,
        any_ccj: !!payload.company.ccjs?.length,
      };
      state.applicant = payload.applicant;

      if (payload.credit_limit) {
        state.prequalifyResult = {
          approved: true,
          credit_limit: payload.credit_limit,
        };
      }

      state.additionalInfo = { ...state.additionalInfo, ...payload.additionalInfo };
    });

    builder.addCase(clearApplication, () => initialState);

    builder.addCase(createApplication.fulfilled, (state, { payload }) => {
      // state.loading = false; - Triggers pre qualify action, do NOT toggle loading state
      state.company = { ...state.company, ...payload.company };
      state.applicant = { ...state.applicant, ...payload.applicant };
    });

    builder.addCase(getCompanyInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.company.sic_codes = payload.sic_codes || [];
      }
    });

    builder.addCase(validateReCaptcha.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(verifyEmail.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(setApplicantDetails, (state, { payload }) => {
      state.applicant = { ...state.applicant, ...payload };
    });

    builder.addCase(clearOnboardDetails, (state) => {
      state.company = initialState.company;
      state.applicant = initialState.applicant;
    });

    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setHideOptionalFields, (state, { payload }) => {
      state.hideOptionalFields = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(signIn.fulfilled, () => initialState);

    builder.addMatcher(
      isAnyOf(
        patchCompany.pending,
        patchApplicant.pending,
        prequalifyCompany.pending,
        gatherOffers.pending,
        getCompanyInfo.pending,
        validateReCaptcha.pending,
        verifyEmail.pending,
        createApplication.pending,
      ),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(
        patchCompany.rejected,
        patchApplicant.rejected,
        prequalifyCompany.rejected,
        gatherOffers.rejected,
        getCompanyInfo.rejected,
        validateReCaptcha.rejected,
        verifyEmail.rejected,
        createApplication.rejected,
      ),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );
  },
});

export default onboardStore.reducer;
