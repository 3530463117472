import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SettingName } from 'core/types';

import { getSettingByName as getSettingByNameApi } from 'http/settings';

export const SET_ERROR = 'global/SET_ERROR';
export const GET_FAQS = 'global/GET_FAQS';
export const SET_FAQS_ERROR = 'global/SET_FAQS_ERROR';

export const setError = createAction<boolean | string>(SET_ERROR);

export const getFaqs = createAsyncThunk<string>(GET_FAQS, async () => {
  const setting = await getSettingByNameApi(SettingName.FAQS);
  return setting?.data.content ?? '';
});

export const setFaqsError = createAction<boolean | string>(SET_FAQS_ERROR);
