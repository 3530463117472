import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import clsx from 'clsx';

import { Layout } from 'components/Layout';
import useCommonStyles from 'core/styles';
import { CustomTextField } from 'components/inputs';
import { useAuth } from 'store/auth/hooks';
import routes from 'router/routes';
import { LoadingButton } from 'components/buttons';
import analytics from 'core/services/analytics';
import useStyles from './SignIn.styles';

const SignIn: FC = () => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { loading, initAuth, error, clearError } = useAuth();

  useEffect(() => {
    analytics.track(routes.signIn);
  }, []);

  const formSchema = yup.object({
    email: yup.string().email(t('pages.signIn.inputs.email.error')).required(t('pages.signIn.inputs.email.required')),
  });

  const initialValues = {
    email: '',
  };

  const onSubmit = (values: typeof initialValues) => {
    initAuth(values.email);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
      {({ handleChange, values, handleSubmit }) => (
        <Layout error={error} clearError={clearError} showSidebar={false}>
          <Box className={classes.container}>
            <Typography className={commonClasses.title}>{t('pages.signIn.title')}</Typography>

            <Typography className={commonClasses.subtitle}>{t('pages.signIn.description')}</Typography>

            <Form noValidate className={commonClasses.form}>
              <Grid container direction="column" spacing={4}>
                <Grid item className={classes.inputContainer}>
                  <Field
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    value={values.email || ''}
                    onChange={handleChange}
                    title=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={clsx([commonClasses.textInput, classes.textInput])}
                  />
                </Grid>
              </Grid>
            </Form>

            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => handleSubmit()}
              loading={loading}
              id="signInButton"
            >
              {t('pages.signIn.buttons.main')}
            </LoadingButton>
          </Box>
        </Layout>
      )}
    </Formik>
  );
};

export default SignIn;
