import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    termsContainer: {
      width: '90%',
      marginLeft: '5%',
      marginRight: '5%',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
    termsText: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.greyText.main,
    },
    termsLink: {
      fontWeight: 700,
      textDecoration: 'underline',
    },
  }),
);
