import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Business } from '@material-ui/icons';
import clsx from 'clsx';
import moment from 'moment';

import { BaseApplicationData } from 'core/types';
import useCommonStyles from 'core/styles';
import useStyles from './AppButton.styles';

interface AppButtonProps {
  application: BaseApplicationData;
  selected: boolean;
  setSelected: () => void;
}

const AppButton: FC<AppButtonProps> = ({ application, selected, setSelected }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();

  return (
    <button
      type="button"
      className={clsx([classes.container, selected && classes.activeContainer])}
      onClick={setSelected}
    >
      <Box className={classes.iconContainer}>
        <Business />
      </Box>

      <Box className={classes.infoContainer}>
        <Typography className={clsx([classes.title, selected && [commonClasses.whiteText, commonClasses.strongText]])}>
          {application.company_name}
        </Typography>
        <Typography
          className={clsx([classes.description, selected && [commonClasses.whiteText, commonClasses.strongText]])}
        >
          {moment(application.creation_date).format('DD MMMM YYYY')}
        </Typography>
      </Box>
    </button>
  );
};

export default AppButton;
