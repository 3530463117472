import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(56),
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(36),
        lineHeight: theme.typography.pxToRem(50),
      },
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: 500,
      color: theme.palette.greyText.main,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(21.5),
        lineHeight: theme.typography.pxToRem(32),
      },
    },
    title2: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(36),
      fontWeight: 700,
      color: theme.palette.primary.main,
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    subtitle2: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 400,
      color: theme.palette.greyText.main,
      textAlign: 'center',
    },
    error: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: 700,
      marginTop: 4,
      color: theme.palette.error.main,
      '& svg': {
        fontSize: theme.typography.pxToRem(14),
        marginBottom: -3,
        marginRight: 6,
        color: theme.palette.error.main,
      },
    },
    strongText: {
      fontWeight: 700,
    },
    primaryText: {
      color: theme.palette.primary.main,
    },
    whiteText: {
      color: `${theme.palette.common.white} !important`,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(7),
      '& .MuiGrid-item': {
        paddingTop: 14,
        paddingBottom: 14,
      },
    },
    textInput: {
      margin: theme.spacing(0),
      minWidth: '100%',
      textAlign: 'left',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.common.black,
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.common.black,
      },
      '& .Mui-error': {
        color: theme.palette.error.main,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
      '& .Mui-checked + .MuiFormControlLabel-label': {
        fontWeight: 700,
      },
      '&.MuiFormControl-marginNormal': {
        marginTop: 0,
      },
    },
    popupIcon: {
      height: 20,
      width: 20,
      color: '#687C8D',
      pointerEvents: 'none',
    },
    documentUploadPadding: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    chip: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
      marginLeft: theme.spacing(0.5),
      height: 22,
      padding: '1px 4px',
      borderRadius: 4,
      backgroundColor: theme.palette.chipGreyBackground.main,
      '& span': {
        padding: 0,
      },
    },
    mb40: {
      marginBottom: 40,
    },
    mt40: {
      marginTop: 40,
    },
    mb20: {
      marginBottom: 20,
    },
    mt20: {
      marginTop: 20,
    },
  }),
);
