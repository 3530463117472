import { FC, useMemo, useEffect, useCallback } from 'react';
import * as yup from 'yup';
import i18n from 'i18next';
import memoize from 'lodash.memoize';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';

import { checkForActiveApplication as checkForActiveApplicationApi } from 'http/onboard';
import { parseMoney } from 'utils/currency';
import { ApplicationRegion, CompanyIndustry, FundingReasons } from 'core/types';
import { maxFundingAmount, minFundingAmount } from 'core/constants';
import { CustomNumberFormat } from 'components/inputs';
import { Applicant, Company } from 'store/onboard/types';
import { useIsMounted } from 'hooks';

export interface FormValues {
  region: ApplicationRegion;
  funding_amount: number | null;
  funding_reasons: FundingReasons[];
  registered_name: string | null;
  director_id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  formation_date: string | null;
  registered_number: string | null;
  monthly_revenue: number | null;
  company_status?: string | null;
  online_sales_revenue_percentage: number | null;
  phone_number: string | null;
  ein: string | null;
  industry: CompanyIndustry | null;
}

export const SEARCH_DEBOUNCE_DELAY = process.env.NODE_ENV === 'test' ? 0 : 500; // miliseconds

export const VALIDATION_DEBOUNCE_DELAY = process.env.NODE_ENV === 'test' ? 0 : 350; // miliseconds

export const USA_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{4})$/;

export const IRL_PHONE_NUMBER_REGEX = /^(\d{3})-(\d{3})-(\d{2,4})$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="###-###-####" type="tel" useFormattedValue />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EINNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="##-#######" type="tel" useFormattedValue />
);

const validateCompanyNumber = memoize(async (value: string | undefined) => {
  if (!value) return true;
  try {
    await checkForActiveApplicationApi({ registered_number: value });
    return true;
  } catch (error) {
    return false;
  }
});

export const getFormSchema = (withDirectors: boolean): yup.AnyObjectSchema =>
  yup.object({
    region: yup
      .string()
      .oneOf(Object.values(ApplicationRegion))
      .required(i18n.t('pages.preApply.inputs.region.required')),
    funding_amount: yup
      .number()
      .min(minFundingAmount, ({ min }) => i18n.t('pages.preApply.inputs.funding_amount.min', { min: parseMoney(min) }))
      .max(maxFundingAmount, ({ max }) => i18n.t('pages.preApply.inputs.funding_amount.max', { max: parseMoney(max) }))
      .required(i18n.t('pages.preApply.inputs.funding_amount.required'))
      .nullable(),
    funding_reasons: yup
      .array(yup.string())
      .min(1, i18n.t('pages.preApply.inputs.funding_reasons.error'))
      .required(i18n.t('pages.preApply.inputs.funding_reasons.required'))
      .nullable(),
    registered_name: yup.string().required(i18n.t('pages.preApply.inputs.registered_name.required')).nullable(),
    director_id: withDirectors
      ? yup
          .string()
          .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
            region === ApplicationRegion.UK
              ? schema.required(i18n.t('pages.preApply.inputs.director.required')).nullable()
              : schema.nullable(),
          )
      : yup.string().nullable(),
    first_name: yup.string().required(i18n.t('pages.preApply.inputs.first_name.required')).nullable(),
    last_name: yup.string().required(i18n.t('pages.preApply.inputs.last_name.required')).nullable(),
    email: yup
      .string()
      .email(i18n.t('pages.preApply.inputs.email.error'))
      .required(i18n.t('pages.preApply.inputs.email.required'))
      .nullable(),
    formation_date: yup.string().required(i18n.t('pages.preApply.inputs.formation_date.required')).nullable(),
    registered_number: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        [ApplicationRegion.UK, ApplicationRegion.IRL].includes(region)
          ? schema
              .required(i18n.t('pages.preApply.inputs.registered_number.required'))
              .test(
                'isCompanyAvailable',
                i18n.t('pages.preApply.inputs.registered_number.error'),
                validateCompanyNumber,
              )
              .nullable()
          : schema.nullable(),
      ),
    monthly_revenue: yup
      .number()
      .min(1, i18n.t('pages.preApply.inputs.monthly_revenue.error'))
      .required(i18n.t('pages.preApply.inputs.monthly_revenue.required'))
      .nullable(),
    company_status: yup.string().equals(['active'], i18n.t('pages.preApply.inputs.company_status.error')),
    online_sales_revenue_percentage: yup
      .number()
      .required(i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.required'))
      .min(0, i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.error'))
      .max(100, i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.error'))
      .nullable(),
    phone_number: yup.string().when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
      region === ApplicationRegion.UK
        ? schema.nullable()
        : schema
            .matches(
              region === ApplicationRegion.USA ? USA_PHONE_NUMBER_REGEX : IRL_PHONE_NUMBER_REGEX,
              i18n.t('pages.preApply.inputs.phone_number.error'),
            )
            .required(i18n.t('pages.preApply.inputs.phone_number.required'))
            .nullable(),
    ),
    ein: yup
      .string()
      .when('region', (region: ApplicationRegion, schema: yup.StringSchema) =>
        region !== ApplicationRegion.USA
          ? schema.nullable()
          : schema
              .min(9, i18n.t('pages.preApply.inputs.ein.error'))
              .required(i18n.t('pages.preApply.inputs.ein.required'))
              .nullable(),
      ),
    industry: yup.string().nullable(),
  });

export const getInitialValues = (
  company: Company,
  applicant: Applicant,
  region: ApplicationRegion,
  authEmail: string | null,
): FormValues => ({
  region,
  funding_amount: company.funding_amount ?? 0,
  funding_reasons: company.funding_reasons ?? [],
  registered_name: company.registered_name,
  director_id: applicant.id,
  first_name: applicant.first_name,
  last_name: applicant.last_name,
  email: applicant.email || authEmail,
  formation_date: company.formation_date,
  registered_number: company.registered_number,
  monthly_revenue: company.monthly_revenue,
  company_status: company.company_status,
  online_sales_revenue_percentage: company.online_sales_revenue_percentage
    ? company.online_sales_revenue_percentage * 100
    : null,
  phone_number: applicant.phone_number?.replace(/\+.*?-/, '') || null,
  ein: company.ein?.replace('-', '') || null,
  industry: null,
});

export const FormValidation: FC = () => {
  const { values, validateForm } = useFormikContext();
  const { whenMounted } = useIsMounted();

  const validateWhenMounted = useCallback(() => {
    whenMounted(() => {
      validateForm();
    });
  }, [validateForm, whenMounted]);

  const debouncedValidate = useMemo(() => debounce(validateWhenMounted, VALIDATION_DEBOUNCE_DELAY), [
    validateWhenMounted,
  ]);

  useEffect(() => {
    debouncedValidate();
  }, [values, debouncedValidate]);

  return null;
};
