import moment from 'moment';
import omitBy from 'lodash.omitby';

import { ApplicationRegion, AreaPhoneCode, CompanyDocumentType, CompanyTypes, SupportedCountryCode } from 'core/types';
import { MonthlyRepaymentType, OfferProductType } from 'store/offers/types';
import { RejectionReasonTag } from 'store/app/types';

/**
 * No operation function
 */
export const noop = (): void => {};

/**
 *
 * @param timeMs delay in miliseconds
 */
export const waitFor = (timeMs: number): Promise<void> =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), timeMs));

/**
 *
 * @param decimal
 * @returns
 */
export const decimalToPercentage = (decimal?: number): string => {
  return `${((decimal || 0) * 100).toFixed(1)}%`;
};

/**
 *
 * @returns timeDiff
 */
export const timeDiffCalc = (date1: string | Date, date2: string | Date, diff: string): number => {
  const d1 = typeof date1 === 'string' ? moment(date1) : moment(date1.toISOString());
  const d2 = typeof date2 === 'string' ? moment(date2) : moment(date2.toISOString());
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d1.diff(d2, diff);
};

/**
 *
 * @param str
 * @returns
 */
export const getDateFromBirthDate = (str: string): Date => {
  return moment(str).toDate();
};

/**
 *
 * @param date
 * @returns
 */
export const getTime = (date?: Date): number => {
  return date != null ? date.getTime() : 0;
};

/**
 *
 * @param a
 * @param b
 * @returns
 */
export const sortByDate = (a: { date?: string }, b: { date?: string }): number => {
  if (!a.date || !b.date) return 0;
  return getTime(new Date(b.date)) - getTime(new Date(a.date));
};

/**
 *
 * @param dateOnly - 'YYYY-MM-DD' format
 * @returns date object
 */
export const getDateFromDateOnly = (dateOnly: string): Date => {
  const [year, month, day] = dateOnly.split('-');
  // month range is from 0 to 11
  return new Date(Number(year), Math.max(Number(month) - 1, 0), Number(day));
};

/**
 *
 * @param targetObject object we want to parse
 * @param options object with properties we want as keys
 * @returns parsed object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseObject = (targetObject: Record<string, any>, options: Record<string, number>): Record<string, any> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Object.entries(targetObject).reduce((result: Record<string, any>, [key, value]) => {
    if (options[key] && value) result[key] = value;
    return result;
  }, {});

/**
 *
 * @param docType
 * @returns
 */
export const getRequiredDocTranslationKey = (docType: CompanyDocumentType): string => {
  switch (docType) {
    case CompanyDocumentType.BANK_STATEMENT:
      return 'pages.additionalInfo.documentUploads.bankDetails.validationError';
    case CompanyDocumentType.PAYMENT_DATA:
      return 'pages.additionalInfo.documentUploads.paymentData.validationError';
    case CompanyDocumentType.MANAGEMENT_ACCOUNTS:
      return 'pages.additionalInfo.documentUploads.managementAccounts.validationError';
    case CompanyDocumentType.FILED_ACCOUNTS:
      return 'pages.additionalInfo.documentUploads.filedAccounts.validationError';
    case CompanyDocumentType.VAT_RETURN:
      return 'pages.additionalInfo.documentUploads.additionalInfo.validationError.vat';
    case CompanyDocumentType.IDENTITY_DOCUMENT:
      return 'pages.additionalInfo.documentUploads.additionalInfo.validationError.identity';
    default:
      return 'pages.additionalInfo.documentUploads.defaultValidationError';
  }
};

/**
 *
 * @param productType
 * @returns product type label
 */
export const mapOfferProductTypeToLabel = (productType: OfferProductType): string => {
  switch (productType) {
    case OfferProductType.CREDIT_LINE:
      return 'Credit Line';
    case OfferProductType.REVENUE_BASED:
      return 'Revenue Based Finance';
    case OfferProductType.REVOLVING_CREDIT_FACILITY:
      return 'Revolving Credit Facility';
    case OfferProductType.TERM_LOAN:
      return 'Term Loan';
    default:
      return 'Unknown Product Type';
  }
};

/**
 *
 * @param monthlyRepaymentType
 * @returns interest type label
 */
export const mapOfferInterestTypeToLabel = (monthlyRepaymentType: MonthlyRepaymentType): string => {
  switch (monthlyRepaymentType) {
    case MonthlyRepaymentType.INTEREST_ONLY:
      return 'Interest only';
    case MonthlyRepaymentType.INTEREST_AND_CAPITAL:
      return 'Capital and interest';
    default:
      return 'Unkown Interest Type';
  }
};

/**
 *
 * @param productType
 * @returns trasnslation key
 */
export const mapOfferProductTypeToTransKey = (productType: OfferProductType): string => {
  switch (productType) {
    case OfferProductType.REVENUE_BASED:
      return 'revenueBased';
    case OfferProductType.CREDIT_LINE:
      return 'creditLine';
    case OfferProductType.REVOLVING_CREDIT_FACILITY:
      return 'revolvingCredit';
    case OfferProductType.TERM_LOAN:
      return 'termLoan';
    default:
      return 'unkown';
  }
};

export const mapOfferInterestTypeToTransKey = (monthlyRepaymentType: MonthlyRepaymentType): string => {
  switch (monthlyRepaymentType) {
    case MonthlyRepaymentType.INTEREST_ONLY:
      return 'interestOnly';
    case MonthlyRepaymentType.INTEREST_AND_CAPITAL:
      return 'interestCapital';
    default:
      return 'unkown';
  }
};

/**
 *
 * @param lastName
 * @param firstName
 * @returns director name
 */
export const composeDirectorName = (lastName?: string, firstName?: string): string => {
  let directorName = '';
  if (lastName) directorName += lastName.trim();
  if (firstName) directorName += `, ${firstName.trim()}`;
  return directorName;
};

/**
 *
 * @param type
 * @returns
 */
export const parseCompaniesHouseApiType = (type: string): CompanyTypes => {
  if (!(Object.values(CompanyTypes) as string[])?.includes(type)) {
    return CompanyTypes.OTHER;
  }
  return type as CompanyTypes;
};

/**
 *
 * @param phoneNumber
 * @returns
 */
export const parsePhoneNumber = (phoneNumber: string): string => {
  const cleanNum = phoneNumber.toString().replace(/^\+(44|1)|(\D)/g, '');
  // regexp depends on "phoneNumberRegExp" from ApplicantInfo.tsx
  const match = cleanNum.match(/^(\d{3})(\d{4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  return cleanNum;
};

/**
 *
 * @param rejectionReasonTag
 * @returns
 */
export const mapRejectionReasonTagToLabel = (rejectionReasonTag: RejectionReasonTag): string => {
  switch (rejectionReasonTag) {
    case RejectionReasonTag.DISSOLVED:
      return 'Dissolved';
    case RejectionReasonTag.FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF:
      return 'First Gazette Notice for Compulsory strike-off';
    case RejectionReasonTag.OVERDUE_CONFIRMATION_STATEMENTS:
      return 'Overdue Confirmation Statements';
    case RejectionReasonTag.OVERDUE_FILED_ACCOUNTS:
      return 'Overdue Filed Sccounts';
    case RejectionReasonTag.LOW_TURNOVER:
      return 'Low Turnover';
    case RejectionReasonTag.LOW_MONTHLY_TURNOVER:
      return 'Low Monthly Turnover';
    case RejectionReasonTag.POOR_PERSONAL_CREDIT:
      return 'Poor Personal Credit';
    case RejectionReasonTag.POOR_BUSINESS_CREDIT:
      return 'Poor Business Credit';
    case RejectionReasonTag.DEFAULTS:
      return 'Defaults';
    case RejectionReasonTag.CCJS_COURT_JUDGEMENTS:
      return 'CCJs/Court Judgements';
    case RejectionReasonTag.LOW_BALANCES_BANK_STATEMENTS:
      return 'Low Balances in the Bank Statements';
    case RejectionReasonTag.OTHER:
      return 'Other';
    default:
      return rejectionReasonTag as string;
  }
};

/**
 *
 * @param docType
 * @returns
 */
export const mapCompanyDocumentTypeToLabel = (docType: CompanyDocumentType): string => {
  switch (docType) {
    case CompanyDocumentType.BANK_STATEMENT:
      return 'Bank Statements';
    case CompanyDocumentType.FILED_ACCOUNTS:
      return 'Filed Accounts';
    case CompanyDocumentType.MANAGEMENT_ACCOUNTS:
      return 'Management Accounts';
    case CompanyDocumentType.PAYMENT_DATA:
      return 'Payment Data';
    case CompanyDocumentType.VAT_RETURN:
      return 'Vat Return';
    case CompanyDocumentType.IDENTITY_DOCUMENT:
      return 'Identity Document';
    default:
      return 'Unknown document type';
  }
};

/**
 *
 * @param text
 * @returns
 */
export const toTitleCase = (text: string): string => {
  return text.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
};

/**
 *
 * @param region application region
 * @returns supported country code
 */
export const mapAppRegionToCountryCode = (region: ApplicationRegion): SupportedCountryCode => {
  switch (region) {
    case ApplicationRegion.UK:
      return SupportedCountryCode.UNITED_KINGDOM;
    case ApplicationRegion.USA:
      return SupportedCountryCode.USA;
    case ApplicationRegion.IRL:
      return SupportedCountryCode.IRELAND;
    default:
      return SupportedCountryCode.UNITED_KINGDOM;
  }
};

/**
 *
 * @param code country code
 * @returns application region
 */
export const mapCountryCodeToAppRegion = (code: SupportedCountryCode): ApplicationRegion => {
  switch (code) {
    case SupportedCountryCode.UNITED_KINGDOM:
      return ApplicationRegion.UK;
    case SupportedCountryCode.USA:
      return ApplicationRegion.USA;
    case SupportedCountryCode.IRELAND:
      return ApplicationRegion.IRL;
    default:
      return ApplicationRegion.UK;
  }
};

/**
 *
 * @param region
 * @returns
 */
export const mapApplicationRegionToLabel = (region: ApplicationRegion): string => {
  switch (region) {
    case ApplicationRegion.UK:
      return 'United Kingdom';
    case ApplicationRegion.USA:
      return 'USA';
    case ApplicationRegion.IRL:
      return 'Ireland';
    default:
      return 'Unknown application region';
  }
};

export const mapAppRegionToAreaPhoneCode = (region: ApplicationRegion): AreaPhoneCode => {
  switch (region) {
    case ApplicationRegion.UK:
      return AreaPhoneCode.GB;
    case ApplicationRegion.USA:
      return AreaPhoneCode.US;
    case ApplicationRegion.IRL:
      return AreaPhoneCode.IE;
    default:
      return AreaPhoneCode.GB;
  }
};

export const isNil = (value: unknown): boolean => {
  return value === undefined || value === null || value === '';
};

export const removeNilValues = (object: Record<string, unknown>): Record<string, unknown> => {
  return omitBy(object, isNil);
};
