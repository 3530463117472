import { FC } from 'react';
import { Box, Button, ButtonProps, CircularProgress } from '@material-ui/core';

import clsx from 'clsx';
import useStyles from './LoadingButton.styles';

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  className?: string;
}

const LoadingButton: FC<LoadingButtonProps> = ({ children, loading, disabled, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx([classes.container, className])}>
      <Button {...rest} disabled={loading || disabled}>
        {children}
      </Button>

      {loading && <CircularProgress className={classes.circularProgress} size={25} thickness={5} />}
    </Box>
  );
};

export default LoadingButton;
