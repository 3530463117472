import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    appBar: {},
    rightContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing(3),
    },
    date: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.common.white,

      '& span': {
        fontWeight: 700,
        marginRight: 12,
      },
    },
    imgContainer: {
      width: 113,
      height: 32,

      '& > img': {
        width: 'auto',
        maxHeight: '100%',
      },
    },
    bsLetterLogo: {
      width: 113,
      height: 32,
    },
  }),
);
