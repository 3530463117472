import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0px 2px 8px 2px rgba(206, 213, 219, 0.40464)',
      borderRadius: 12,
      padding: 12,
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      height: 'fit-content',
      width: '100%',
      border: 'none',
      backgroundColor: theme.palette.common.white,
    },
    activeContainer: {
      backgroundColor: '#6CC198',
    },
    iconContainer: {
      height: 44,
      width: 44,
      borderRadius: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginRight: theme.spacing(0.5),
      backgroundColor: '#E6F0FD',
      transition: 'all 0.35s',
      '& svg, & img': {
        height: '55%',
        width: '55%',
      },
    },
    infoContainer: {
      flex: 1,
      marginLeft: theme.spacing(1),
      textAlign: 'left',
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    description: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyText.main,
      marginTop: 2,
    },
  }),
);
