import { FC, forwardRef } from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import useStyles from './MessageAlert.styles';
import { MessageAlertStatus } from '../../../core/types';

const Alert = forwardRef((props: AlertProps, ref) => <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />);

interface MessageAlertProps {
  open: boolean;
  message?: string;
  status?: MessageAlertStatus;
  handleClose?: () => void;
  autoHideDuration?: number | null;
  anchorOrigin?: SnackbarOrigin;
  containerClassName?: string;
}

const MessageAlert: FC<MessageAlertProps> = ({
  open,
  message,
  status = MessageAlertStatus.ERROR,
  handleClose,
  autoHideDuration = 10000,
  anchorOrigin,
  containerClassName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose && handleClose}
      className={clsx([classes.container, containerClassName])}
      anchorOrigin={anchorOrigin}
      id="alertMessageSnackbar"
    >
      <Alert onClose={handleClose && handleClose} severity={status} id="alertMessageAlert">
        {message || t('global.error')}
      </Alert>
    </Snackbar>
  );
};

export default MessageAlert;
