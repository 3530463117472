import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    fundingAmountInput: {
      '& .MuiOutlinedInput-root': {
        height: 54,
        borderRadius: 12,
        marginTop: theme.spacing(1),
      },
      '& input': {
        fontSize: theme.typography.pxToRem(28),
        lineHeight: theme.typography.pxToRem(36),
        textAlign: 'center',
        fontWeight: 700,
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    inputContainer: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '0px !important',
      },
    },
    inputLabel: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
    sliderContainer: {
      paddingTop: '0px !important',
    },
    successAlertContainer: {
      marginBottom: 75,
      [theme.breakpoints.down(576)]: {
        marginBottom: 65,
      },
      [theme.breakpoints.down(404)]: {
        marginBottom: 55,
      },
    },
  }),
);
