import { configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from './reducers';
import { Actions, RootState } from './types';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  whitelist: ['auth', 'app'],
};

const configureAppStore = (
  preloadedState?: RootState,
): { store: EnhancedStore<RootState, Actions>; persistor: Persistor } => {
  const devMiddlewares = [];

  if (process.env.NODE_ENV === `development`) {
    // eslint-disable-next-line
    const { logger } = require(`redux-logger`);

    devMiddlewares.push(logger);
  }

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      ...devMiddlewares,
    ],
    preloadedState,
    enhancers: [],
  });

  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(persistedReducer));
  }

  return { store, persistor };
};

export const { store, persistor } = configureAppStore();
