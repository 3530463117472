import { AxiosRequestConfig } from 'axios';

import { ApplicationRegion } from 'core/types';
import { apiCall } from '../index';
import {
  Applicant,
  Company,
  CompanyInfo,
  CompanySearchResult,
  CreateApplication,
  CreateApplicationResponse,
  PrequalificationResult,
} from '../../store/onboard/types';

export const searchCompanies = async (term: string): Promise<CompanySearchResult[]> =>
  apiCall({ method: 'get', url: '/search/companies', params: { term } });

export const patchCompany = async (id: string, company: Partial<Company>): Promise<null> =>
  apiCall({ method: 'put', url: `/companies/${id}`, data: company });

export const patchApplicant = async (
  companyId: string,
  applicantId: string,
  applicantData: Partial<Applicant>,
): Promise<Partial<Applicant>> =>
  apiCall({ method: 'put', url: `/companies/${companyId}/people/${applicantId}`, data: applicantData });

export const prequalifyCompany = async (
  companyId: string,
  company: Partial<Company>,
  authToken?: string,
): Promise<PrequalificationResult> => {
  const axiosConfig: AxiosRequestConfig = {
    method: 'post',
    url: `/companies/${companyId}/prequalification`,
    data: company,
  };
  if (authToken) {
    axiosConfig.headers = {
      Authorization: `Bearer ${authToken}`,
    };
  }
  return apiCall(axiosConfig);
};

export const gatherOffers = async (companyId: string, fundingAmount: number): Promise<null> =>
  apiCall({ method: 'post', url: `/companies/${companyId}/offers`, data: { funding_amount: fundingAmount } });

export const searchDirectors = async (companyNumber: string): Promise<Applicant[]> =>
  apiCall({ method: 'get', url: '/search/directors', params: { company_number: companyNumber } });

export const createApplication = async (
  payload: Omit<CreateApplication, 'online_sales_revenue_percentage'>,
): Promise<CreateApplicationResponse> => apiCall({ method: 'post', url: '/applications', data: payload });

export const getCompanyInfo = async (companyNumber: string): Promise<CompanyInfo> =>
  apiCall({ method: 'get', url: '/search/company', params: { company_number: companyNumber } });

export const validateReCaptcha = async (reCaptcha: string): Promise<null> =>
  apiCall({ method: 'post', url: 'applications/recaptcha', data: { reCaptcha } });

export const verifyEmail = async (email: string, registered_number: string, region: ApplicationRegion): Promise<null> =>
  apiCall({ method: 'post', url: 'applications/verify', data: { email, registered_number, region } });

export const checkForActiveApplication = async ({
  registered_number,
  email,
}: {
  registered_number?: string;
  email?: string;
}): Promise<null> => apiCall({ method: 'get', url: 'applications/check', params: { registered_number, email } });
