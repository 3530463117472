import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    smallText: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.greyText.main,
      marginBottom: 4,
    },
    bsLetterLogo: {
      width: 80,
      height: 'auto',
    },
  }),
);
