import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      flexDirection: 'column-reverse',
      marginLeft: 0,
      marginRight: 0,
    },
  }),
);
