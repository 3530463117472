import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';

const useWebFontLoader = (fontName: string): boolean => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    WebFont.load({
      google: { families: [`${fontName}:300,400,600,700`] },
      classes: false,
      active: () => setLoading(false),
      inactive: () => setLoading(false), // Font could not be loaded, but we don't want to block ui
      timeout: 2000,
    });
  }, [fontName]);

  return loading;
};

export default useWebFontLoader;
