import { FC, SyntheticEvent } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { AlertDialog, MessageAlert } from 'components/alerts';
import { useGlobalState } from 'store/global/hooks';
import useStyles from './FaqsModal.styles';

interface FaqsModalProps {
  open: boolean;
  toggleOpen: () => void;
}

const FaqsModal: FC<FaqsModalProps> = ({ open, toggleOpen }) => {
  const {
    error: globalError,
    faqs: { loading, error, content },
    setFaqsError,
  } = useGlobalState();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setFaqsError(false);
  };

  return (
    <AlertDialog open={open} handleCancel={toggleOpen} cancelText={t('global.actions.close')}>
      <Box className={classes.container}>
        {/*  eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content }} className={classes.faqsContent} />

        {loading && (
          <Box className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </Box>
        )}
      </Box>

      {!globalError && (
        <MessageAlert
          open={!!error}
          handleClose={handleClose}
          message={typeof error === 'string' ? error : undefined}
        />
      )}
    </AlertDialog>
  );
};

export default FaqsModal;
