import { createMuiTheme, Theme, darken, lighten } from '@material-ui/core/styles';

const primaryMainColor = '#007AFF';
const secondaryMainColor = '#DFEFFF';
const greyTextColor = '#66718D';
const blackTextColor = '#1F1F1F';
const errorMainColor = '#DA1E28';
const dividerColor = '#CED5DB';
const grayIconColor = '#D3D6DF';

const getTheme = (fontFamily: string, primaryColor?: string, secondaryColor?: string): Theme => {
  const primaryMain = primaryColor || primaryMainColor;
  const secondaryMain = secondaryColor || secondaryMainColor;

  return createMuiTheme({
    palette: {
      primary: {
        main: primaryMain,
      },
      secondary: {
        main: secondaryMain,
        dark: darken(secondaryMain, 0.1),
        contrastText: lighten(primaryMain, 0.1),
      },
      error: {
        main: errorMainColor,
      },
      common: {
        black: blackTextColor,
      },
      customGrey: {
        main: '#95A4B1',
      },
      customBlack: {
        main: '#29373F',
      },
      lightGrey: {
        main: '#EEEFF0',
      },
      mediumGrey: {
        main: '#ABAFB2',
      },
      greyText: {
        main: greyTextColor,
      },
      greyIcon: {
        main: grayIconColor,
      },
      chipGreyBackground: {
        main: '#e3e8ee',
      },
      divider: dividerColor,
    },
    typography: {
      fontFamily,
    },
    overrides: {
      MuiTypography: {
        root: {
          color: blackTextColor,
        },
      },
      MuiInput: {
        root: {
          fontWeight: 700,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          borderRadius: 12,
          fontSize: 16,
          lineHeight: 24,
          height: 46,
          overflow: 'hidden',
        },
        label: {
          maxHeight: '100%',
        },
        containedPrimary: {
          minHeight: 46,
        },
        containedSecondary: {
          minHeight: 46,
        },
        outlined: {
          minHeight: 46,
        },
      },
      MuiCheckbox: {
        root: {
          color: grayIconColor,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: '#F6F7F9',
          color: greyTextColor,
        },
        colorSecondary: {
          color: blackTextColor,
          fontWeight: 700,
        },
      },
    },
  });
};

export default getTheme;
