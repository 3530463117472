/* eslint-disable func-names */
import * as yup from 'yup';
import { AnyObject, Maybe } from 'yup/lib/types';

yup.addMethod<yup.StringSchema>(yup.string, 'siCodes', function (message) {
  return this.test('siCodes', message, function (value: string | undefined) {
    const sicCodes = value?.split(', ').filter(Boolean) || [];

    for (let index = 0; index < sicCodes.length; index += 1) {
      const element = sicCodes[index];
      if (element.length < 3 || element.length > 6) {
        return false;
      }
    }

    return true;
  });
});

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    siCodes(message?: string): StringSchema<TType, TContext>;
  }
}

export default yup;
