import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      '& > button:last-child': {
        marginTop: theme.spacing(2.5),
      },
    },
    contentDescription: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      marginBottom: theme.spacing(3),
    },
    circleCheckMark: {
      width: 60,
      height: 60,
    },
    connectedMessage: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      textAlign: 'center',
      color: theme.palette.primary.main,
      marginTop: 16,
    },
    successContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  }),
);
