import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    agreeButton: {
      width: '100%',
      alignSelf: 'center',
      marginTop: 60,
    },
    downloadButton: {
      width: '100%',
      alignSelf: 'center',
      marginTop: 20,
      marginBottom: 30,
    },
    divider: {
      marginTop: 30,
      backgroundColor: '#CED5DB',
    },
    lenderContainer: {
      marginTop: 16,
    },
    lenderTitle: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.primary.main,
      marginBottom: 16,
    },
    lenderInfo: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    lenderAlias: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.greyText.main,
      marginTop: '16px !important',
    },
  }),
);
