import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 2, // above the back button on the page component (coming from Layout)
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    image: {
      width: 224,
      height: 224,
      borderRadius: 224,
      marginBottom: 24,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '24px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      letterSpacing: -0.48,
      color: theme.palette.common.black,
      marginTop: 12,
      marginBottom: 12,
    },
    description: {
      fontSize: 14,
      lineHeight: '18px',
      textAlign: 'center',
      letterSpacing: -0.336,
      color: theme.palette.customGrey.main,
      whiteSpace: 'pre-line',
      width: '75%',
    },
    circleRoot: {
      position: 'absolute',
      top: 0,
    },
    circle: {
      stroke: 'url(#linearColors)',
    },
  }),
);
