import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      marginTop: 10,
    },
  }),
);

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 2,
    },
    inputLabel: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyText.main,
    },
    tooltipIcon: {
      fontSize: theme.typography.pxToRem(17),
      color: theme.palette.greyText.main,
    },
  }),
);
