import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { GlobalState } from './types';
import { setError, getFaqs, setFaqsError } from './actions';
import { clearApplication } from '../app/actions';
import { createApplication } from '../onboard/actions';
import { getLeadApplications, reApply } from '../auth/actions';

export const initialState: GlobalState = {
  error: false,
  logout: false,
  faqs: {
    loading: false,
    content: '',
    error: false,
  },
};

const globalStore = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GlobalState>) => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(clearApplication, (state, { payload }) => {
      state.logout = !!payload;
    });

    builder.addCase(createApplication.fulfilled, (state) => {
      state.logout = false;
    });

    builder.addCase(getLeadApplications.fulfilled, (state, { payload }) => {
      if (payload?.accessToken) state.logout = false;
    });

    builder.addCase(reApply.fulfilled, (state, { payload }) => {
      if (payload) state.logout = false;
    });

    builder.addCase(getFaqs.pending, (state) => {
      state.faqs.loading = true;
      state.faqs.error = false;
    });

    builder.addCase(getFaqs.rejected, (state, { error }) => {
      state.faqs.loading = false;
      state.faqs.error = error.message ?? true;
    });

    builder.addCase(getFaqs.fulfilled, (state, { payload }) => {
      state.faqs.loading = false;
      state.faqs.content = payload;
    });

    builder.addCase(setFaqsError, (state, { payload }) => {
      state.faqs.error = payload;
    });
  },
});

export default globalStore.reducer;
