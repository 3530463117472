import { FC } from 'react';
import { useAppState } from 'store/app/hooks';
import { mapAppRegionToCurrencySymbol } from 'utils/currency';

import { CustomNumberFormat } from '../inputs/CustomNumberFormat';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AmountNumberInput: FC = (props: any) => {
  const { region } = useAppState();

  return (
    <CustomNumberFormat
      {...props}
      prefix={mapAppRegionToCurrencySymbol(region ?? undefined)}
      decimalScale={0}
      fixedDecimalScale
    />
  );
};

export default AmountNumberInput;
