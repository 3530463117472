import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, InputAdornment } from '@material-ui/core';
import { Formik, Form, FieldArray, FastField } from 'formik';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { areaCodeOptions, residentialStatusOptions, currentAddressOptions } from 'core/constants';
import useCommonStyles from 'core/styles';
import {
  CustomSelect,
  CustomTextField,
  CustomDatePicker,
  CustomNumberFormat,
  CustomRadioField,
} from 'components/inputs';
import { Layout } from 'components/Layout';
import routes from 'router/routes';
import { useOnboard } from 'store/onboard/hooks';
import { useAppState } from 'store/app/hooks';

import { Address, Applicant } from 'store/onboard/types';
import { ReactComponent as DashedDivider } from 'assets/icons/dashedDivider.svg';
import analytics from 'core/services/analytics';
import { AddressStatus } from 'core/types';
import { HideOptionalFields } from 'components/HideOptionalFields';
import { useAuth } from 'store/auth/hooks';
import GooglePlacesAutocompleteField from 'components/GoogleAutoCompletePlaces';
import { mapAppRegionToCountryCode } from 'utils';
import useStyles from './ApplicantInfo.styles';
import {
  formSchema,
  FormValues,
  getInitialValues,
  isAddressAgeValid,
  FormAddress,
  PhoneNumberInput,
  getAddressFieldError,
  initialAddress,
} from './utils';
import { SEARCH_DEBOUNCE_DELAY } from '../PreApply/utils';

const ApplicantInfo: FC = () => {
  const [addressFieldsVisible, setAddressFieldsVisible] = useState<boolean>(false);
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const history = useHistory();
  const { applicant, patchApplicant, setSuccess } = useOnboard();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { adminEditMode } = useAuth();
  const { region: appRegion } = useAppState();

  const region = mapAppRegionToCountryCode(appRegion);

  useEffect(() => {
    analytics.track(routes.applicantInfo);

    return () => setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = getInitialValues(applicant);

  const onSubmit = ({ addresses, ...values }: FormValues) => {
    if (!applicant.id) return;

    const validAddressAge = isAddressAgeValid(addresses);

    if (validAddressAge) {
      const parsedAddress: Partial<Address>[] = addresses.map((addr: FormAddress) => ({
        ...addr,
        start_date: addr.start_date?.toISOString(),
        property_equity: Number(addr.property_equity),
      }));
      const applicantValues: Partial<Applicant> = {
        ...values,
        date_of_birth: moment(values.date_of_birth).format('YYYY-MM-DD'),
        addresses: parsedAddress as Address[],
        applicant: true,
        phone_number: `${values.area_code}-${values.phone_number}`,
      };

      patchApplicant({ id: applicant.id, data: applicantValues });
    }
  };

  const goBack = () => {
    history.push(routes.companyInfo);
  };

  const onGoForward = () => {
    history.push(routes.fundingGoal);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema}>
      {({ handleChange, values, errors, touched, setFieldValue, handleSubmit, setFieldError, setFieldTouched }) => {
        const validateAddressAge = () => {
          if (!isAddressAgeValid(values.addresses)) {
            setFieldError('addresses.0.start_date', t('pages.applicantInfo.inputs.start_date.required'));
          }
        };
        const onHandleSubmit = () => {
          validateAddressAge();
          handleSubmit();
        };

        const hasValue = values?.addresses?.some((item) => {
          return Object.values(item).some((value) => {
            return value !== null && value !== undefined && value !== '';
          });
        });
        return (
          <Layout
            headerTitle={t('pages.applicantInfo.header')}
            onMainAction={onHandleSubmit}
            onGoBack={goBack}
            onGoForward={adminEditMode ? onGoForward : undefined}
          >
            <Typography variant="h1" className={commonClasses.title2}>
              {t('pages.applicantInfo.title')}
            </Typography>

            <Typography className={commonClasses.subtitle2}>{t('pages.applicantInfo.description')}</Typography>

            <HideOptionalFields />

            <Form noValidate className={commonClasses.form}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <FastField
                    id="first_name"
                    fullWidth
                    component={CustomTextField}
                    autoFocus
                    name="first_name"
                    value={values.first_name || ''}
                    onChange={handleChange}
                    title={t('pages.applicantInfo.inputs.first_name.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.applicantInfo.inputs.first_name.placeholder')}
                    className={commonClasses.textInput}
                  />
                </Grid>

                <Grid item>
                  <FastField
                    id="last_name"
                    fullWidth
                    component={CustomTextField}
                    name="last_name"
                    value={values.last_name || ''}
                    onChange={handleChange}
                    title={t('pages.applicantInfo.inputs.last_name.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.applicantInfo.inputs.last_name.placeholder')}
                    className={commonClasses.textInput}
                  />
                </Grid>

                <Grid item>
                  <FastField
                    id="email"
                    fullWidth
                    component={CustomTextField}
                    name="email"
                    value={values.email || ''}
                    onChange={handleChange}
                    title={t('pages.applicantInfo.inputs.email.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.applicantInfo.inputs.email.placeholder')}
                    className={commonClasses.textInput}
                    disabled
                  />
                </Grid>

                <Grid item>
                  <FastField
                    id="area_code"
                    fullWidth
                    component={CustomSelect}
                    options={areaCodeOptions}
                    name="area_code"
                    value={values.area_code}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setFieldValue('area_code', event.target.value)}
                    title={t('pages.applicantInfo.inputs.area_code.label')}
                    className={commonClasses.textInput}
                  />
                </Grid>

                <Grid item>
                  <FastField
                    id="phone_number"
                    fullWidth
                    component={CustomTextField}
                    name="phone_number"
                    value={values.phone_number || ''}
                    onChange={handleChange}
                    title={t('pages.applicantInfo.inputs.phone_number.label')}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.applicantInfo.inputs.phone_number.placeholder')}
                    className={commonClasses.textInput}
                    InputProps={{
                      inputComponent: PhoneNumberInput,
                    }}
                  />
                </Grid>

                <Grid item>
                  <CustomDatePicker
                    id="date_of_birth"
                    name="date_of_birth"
                    label={t('pages.applicantInfo.inputs.date_of_birth.label')}
                    value={values.date_of_birth}
                    onChange={(date: Moment | null) => {
                      const validDate = date?.isValid() ? date.toDate() : null;
                      setFieldValue('date_of_birth', validDate ? validDate.toISOString() : null);
                      setTimeout(() => setFieldTouched('date_of_birth', true));
                    }}
                    error={touched.date_of_birth && errors.date_of_birth}
                  />
                </Grid>

                <Grid item>
                  <FieldArray name="addresses">
                    {({ remove: removeAddress, push: pushAddress }) => {
                      return (
                        <Box display="flex" flexDirection="column" alignItems="center">
                          {values.addresses &&
                            values.addresses.length > 0 &&
                            values.addresses.map((address: FormAddress, index: number) => {
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid container item direction="column" spacing={4} key={`address-${index}`}>
                                  {index > 0 && (
                                    <Grid item className={classes.dividerContainer}>
                                      <DashedDivider />
                                    </Grid>
                                  )}

                                  <Grid item className={classes.personalAddressContainer}>
                                    <Typography className={classes.addressNumberTitle}>
                                      {t('pages.applicantInfo.address.personal', { number: index + 1 })}
                                    </Typography>

                                    {index !== 0 && (
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        aria-label={`delete address ${index + 1}`}
                                        onClick={() => removeAddress(index)}
                                        className={classes.deleteAddressButton}
                                        id={`deleteAddressButton${index + 1}`}
                                      >
                                        {t('global.actions.delete')}
                                      </Button>
                                    )}
                                  </Grid>

                                  <Grid item>
                                    <FastField
                                      id={`addresses.${index}.full`}
                                      fullWidth
                                      component={GooglePlacesAutocompleteField}
                                      name={`addresses.${index}.full`}
                                      value={address.full || ''}
                                      // onChange={(value: { value: string; offset: string }[]) => {

                                      onChange={(addresses?: Partial<Address> | null) => {
                                        setAddressFieldsVisible(true);
                                        setFieldValue(`addresses.${index}.full`, addresses?.full || '');
                                        setFieldValue(`addresses.${index}.house_number`, addresses?.house_number || '');
                                        setFieldValue(`addresses.${index}.line_1`, addresses?.line_1 || '');
                                        setFieldValue(`addresses.${index}.line_2`, addresses?.line_2 || '');
                                        setFieldValue(`addresses.${index}.city`, addresses?.city || '');
                                        setFieldValue(`addresses.${index}.county`, addresses?.county || '');
                                        setFieldValue(`addresses.${index}.postcode`, addresses?.postcode || '');
                                      }}
                                      region={region}
                                      language={language}
                                      debounce={SEARCH_DEBOUNCE_DELAY}
                                      title={t('pages.applicantInfo.inputs.search.label')}
                                      error={!!getAddressFieldError(touched, errors, index, 'search')}
                                      helperText={getAddressFieldError(touched, errors, index, 'search')}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      placeholder={t('pages.applicantInfo.inputs.search.placeholder')}
                                      caption={t('pages.applicantInfo.inputs.search.helper')}
                                      captionOnClick={() => setAddressFieldsVisible(true)}
                                      className={commonClasses.textInput}
                                      disabled={addressFieldsVisible}
                                    />
                                  </Grid>

                                  {(hasValue || addressFieldsVisible) && (
                                    <>
                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.house_number`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.house_number`}
                                          value={address.house_number || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.house_number.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'house_number')}
                                          helperText={getAddressFieldError(touched, errors, index, 'house_number')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.house_number.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>

                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.line_1`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.line_1`}
                                          value={address.line_1 || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.line_1.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'line_1')}
                                          helperText={getAddressFieldError(touched, errors, index, 'line_1')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.line_1.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>

                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.line_2`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.line_2`}
                                          value={address.line_2 || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.line_2.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'line_2')}
                                          helperText={getAddressFieldError(touched, errors, index, 'line_2')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.line_2.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.city`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.city`}
                                          value={address.city || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.city.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'city')}
                                          helperText={getAddressFieldError(touched, errors, index, 'city')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.city.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.county`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.county`}
                                          value={address.county || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.county.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'county')}
                                          helperText={getAddressFieldError(touched, errors, index, 'county')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.county.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.postcode`}
                                          fullWidth
                                          component={CustomTextField}
                                          name={`addresses.${index}.postcode`}
                                          value={address.postcode || ''}
                                          onChange={handleChange}
                                          title={t('pages.applicantInfo.inputs.postcode.label')}
                                          error={!!getAddressFieldError(touched, errors, index, 'postcode')}
                                          helperText={getAddressFieldError(touched, errors, index, 'postcode')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder={t('pages.applicantInfo.inputs.postcode.placeholder')}
                                          className={commonClasses.textInput}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <FastField
                                          id={`addresses.${index}.current`}
                                          aria-label="current-address"
                                          component={CustomRadioField}
                                          options={currentAddressOptions}
                                          name={`addresses.${index}.current`}
                                          value={address.current}
                                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                            setFieldValue(`addresses.${index}.current`, event.target.value === 'true')
                                          }
                                          title={t('pages.applicantInfo.inputs.current.label')}
                                          className={commonClasses.textInput}
                                          error={!!getAddressFieldError(touched, errors, 0, 'current')}
                                          helperText={getAddressFieldError(touched, errors, 0, 'current')}
                                        />
                                      </Grid>
                                      <Grid item>
                                        {/* Property equity depends on status - use Field */}
                                        <FastField
                                          id={`addresses.${index}.status`}
                                          fullWidth
                                          component={CustomSelect}
                                          options={residentialStatusOptions}
                                          name={`addresses.${index}.status`}
                                          value={address.status || ''}
                                          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                                            setFieldValue(`addresses.${index}.status`, event.target.value)
                                          }
                                          title={t('pages.applicantInfo.inputs.status.label')}
                                          className={commonClasses.textInput}
                                          error={!!getAddressFieldError(touched, errors, index, 'status')}
                                          helperText={getAddressFieldError(touched, errors, index, 'status')}
                                        />
                                      </Grid>
                                      {address.status?.includes(AddressStatus.OWNER_WITH_MORTGAGE) && (
                                        <Grid item>
                                          <FastField
                                            id={`addresses.${index}.property_equity`}
                                            fullWidth
                                            component={CustomTextField}
                                            name={`addresses.${index}.property_equity`}
                                            value={address.property_equity}
                                            onChange={handleChange}
                                            title={t('pages.applicantInfo.inputs.property_equity.label')}
                                            error={!!getAddressFieldError(touched, errors, index, 'property_equity')}
                                            helperText={getAddressFieldError(touched, errors, index, 'property_equity')}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            InputProps={{
                                              startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                              inputComponent: CustomNumberFormat as any,
                                            }}
                                            className={commonClasses.textInput}
                                          />
                                        </Grid>
                                      )}
                                      <Grid item>
                                        <CustomDatePicker
                                          id={`addresses.${index}.start_date`}
                                          name={`addresses.${index}.start_date`}
                                          label={t('pages.applicantInfo.inputs.start_date.label')}
                                          value={address.start_date}
                                          onChange={(date: Moment) =>
                                            setFieldValue(`addresses.${index}.start_date`, date.toDate())
                                          }
                                          error={getAddressFieldError(touched, errors, index, 'start_date')}
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              );
                            })}

                          <Button
                            color="primary"
                            fullWidth
                            onClick={() => pushAddress(initialAddress)}
                            className={classes.addAddressBtn}
                            variant="outlined"
                            id="addAddressButton"
                          >
                            {t('pages.applicantInfo.address.add')}
                          </Button>
                        </Box>
                      );
                    }}
                  </FieldArray>
                </Grid>
              </Grid>
            </Form>
          </Layout>
        );
      }}
    </Formik>
  );
};

export default ApplicantInfo;
