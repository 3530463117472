import { FundingReasons, Document, CompanyDocumentType, ApplicationRegion } from 'core/types';
import { Applicant, Company } from 'store/onboard/types';

export interface AppState {
  widget: boolean;
  publicKey: string | null;
  height?: string | number;
  width?: string | number;
  logoUrl?: string;
  letterLogoUrl?: string;
  partnerName?: string;
  captchaEnabled?: boolean;
  kycEnabled?: boolean;
  popup?: boolean;
  exitEnabled?: boolean;
  hostDomain: string | null;
  faqEnabled?: boolean;
  homeTitle?: string;
  homeSubTitle?: string;
  skipHomePage?: boolean;
  customCalendlyLink?: string;

  loading: boolean;
  error: boolean | string;
  status: ApplicationStatus | null;
  track: Track | null;
  rejection: ApplicationRejection | null;
  details_requested: ApplicationDetailsRequested[];
  application_id: string | null;
  region: ApplicationRegion;

  termsVisible?: boolean;
  policyVisible?: boolean;
  termsAgreed?: boolean;
}

export interface AppHookReturn extends AppState {
  updateAppState: (update: Partial<AppState> & { accessToken?: string }) => void;
  getApplication: () => void;
  setError: (err: boolean | string) => void;
  clearApplication: (logout?: boolean) => void;
  setAppRegion: (appRegion: ApplicationRegion) => void;
  showTerms: (visible: boolean) => void;
  showPolicy: (visible: boolean) => void;
  agreeTerms: (agreed: boolean) => void;
}

export enum ApplicationStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  PREQUALIFIED = 'PREQUALIFIED',
  REJECTED = 'REJECTED',
  OFFERS_REQUESTED = 'OFFERS_REQUESTED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  FUNDS_DISBURSED = 'FUNDS_DISBURSED',
  CLOSED = 'CLOSED',
  ADDITIONAL_DETAILS_REQUESTED = 'ADDITIONAL_DETAILS_REQUESTED',
}

export interface Track {
  visitedScreens: string[];
  lastVisitedScreen?: string;
}

export interface ApplicationRejection {
  unmet_criteria?: Record<string, string[]>;
  rejection_reason?: string;
  rejected_at: string;
  rejection_reason_tags?: RejectionReasonTag[];
}

export interface Application {
  application_id: string;
  partner_id: string;
  status: ApplicationStatus;
  active: boolean;
  credit_limit?: number;
  funding_reasons?: FundingReasons[];
  funding_date?: string;
  funding_amount?: number;
  details_requested?: ApplicationDetailsRequested[];
  application_rejection?: ApplicationRejection;
  company: Company;
  applicant: Applicant;
  region: ApplicationRegion;
}

export interface ApplicationWithAdditionalInfo extends Application {
  additionalInfo: Record<string, Document[]>;
}

export interface ApplicationDetailsRequested {
  offer_id?: string;
  document_types: CompanyDocumentType[];
  notes?: string;
  resolved: boolean;
}

export enum RejectionReasonTag {
  DISSOLVED = 'DISSOLVED',
  FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF = 'FIRST_GAZETTE_NOTICE_COMPULSORY_STRIKE_OFF',
  OVERDUE_CONFIRMATION_STATEMENTS = 'OVERDUE_CONFIRMATION_STATEMENTS',
  OVERDUE_FILED_ACCOUNTS = 'OVERDUE_FILED_ACCOUNTS',
  LOW_TURNOVER = 'LOW_TURNOVER',
  LOW_MONTHLY_TURNOVER = 'LOW_MONTHLY_TURNOVER',
  POOR_PERSONAL_CREDIT = 'POOR_PERSONAL_CREDIT',
  POOR_BUSINESS_CREDIT = 'POOR_BUSINESS_CREDIT',
  DEFAULTS = 'DEFAULTS',
  CCJS_COURT_JUDGEMENTS = 'CCJS_COURT_JUDGEMENTS',
  LOW_BALANCES_BANK_STATEMENTS = 'LOW_BALANCES_BANK_STATEMENTS',
  OTHER = 'OTHER',
}
