import { FC, useCallback, useState } from 'react';
import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';
import clsx from 'clsx';
import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { CustomInputError } from 'components/inputs';
import { useOffers } from 'store/offers/hooks';
import useStyles from './FileDrop.styles';
import { CompanyDocumentType, Document, DocUploadResponse, FileErrors } from '../../core/types';
import FileDropInfo from './FileDropInfo';

// 20MB -> in bytes
const maxFileSize = 20000000;

interface AllowedFiletype {
  name: string;
  mimeType: string;
  extension: string;
}

interface FileDropProps {
  allowedFileTypes: Array<AllowedFiletype>;
  onUpload(documents: Document[]): void;
  onReject(rejections: FileRejection[]): void;
  uploadMessage: string;
  uploadDocument(data: FormData): Promise<DocUploadResponse | null>;
  documentType: CompanyDocumentType;
  error?: boolean | string;
  multiple?: boolean;
  uploadedDocs: Document[];
}

const FileDrop: FC<FileDropProps> = ({
  allowedFileTypes,
  onUpload,
  onReject,
  uploadMessage,
  uploadDocument,
  documentType,
  error,
  multiple,
  uploadedDocs,
}) => {
  const [state, setState] = useState({
    loading: false,
    duplicates: [] as string[],
    showDuplicates: false,
    acceptedFiles: [] as File[],
    fileRejections: [] as FileRejection[],
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { selectedOfferId } = useOffers();
  const toggleDuplicateModal = () => setState((prev) => ({ ...prev, showDuplicates: !prev.showDuplicates }));

  const continueUpload = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const Data = new FormData();

      Data.append('type', documentType);
      // There's a request for additional details for a specific offer
      if (selectedOfferId) Data.append('offer_id', selectedOfferId);

      const files: { [key: string]: File } = {};
      acceptedFiles.forEach((file) => {
        Data.append('file', file);
        files[file.name] = file;
      });
      if (!fileRejections.length) {
        setState((prev) => ({ ...prev, loading: true }));
        try {
          const res: DocUploadResponse | null = await uploadDocument(Data);
          if (res) {
            const uploadedDocuments = res.companyDocs.map(({ name, id, type }) => ({ name, id, type }));
            onUpload(uploadedDocuments);
          }
          if (res?.errors.length) throw new Error(JSON.stringify(res.errors));
        } catch (err) {
          const errors = JSON.parse((err as Error).message) as FileErrors;
          errors.forEach(({ file, error: fileError }) => {
            fileRejections.push({
              file: files[file],
              errors: [
                {
                  code: 'too-many-files', // TODO: use actual error code
                  message: fileError,
                },
              ],
            });
          });
        }
        setState((prev) => ({ ...prev, loading: false }));
      }

      // Don't check if array has any element, we want to reset rejections on every upload op
      onReject(fileRejections);
    },
    [documentType, selectedOfferId, onReject, uploadDocument, onUpload],
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const duplicates = uploadedDocs.reduce((acc: string[], doc) => {
        acceptedFiles.forEach((file) => {
          if (doc.name.replace(/^.+\//, '') === file.name) {
            acc.push(doc.name);
          }
        });
        return acc;
      }, []);
      if (duplicates.length) {
        setState((prev) => ({ ...prev, duplicates, loading: false, acceptedFiles, fileRejections }));
        return toggleDuplicateModal();
      }
      return continueUpload(acceptedFiles, fileRejections);
    },
    [continueUpload, uploadedDocs],
  );

  const { loading, showDuplicates, duplicates, acceptedFiles: accFiles, fileRejections: rejFiles } = state;
  return (
    <>
      <Dropzone
        onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
        maxSize={maxFileSize}
        accept={allowedFileTypes.map((fileType) => fileType.mimeType)}
        disabled={loading}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box {...getRootProps()} className={clsx([classes.outer, error && classes.outerError])}>
                  <Box className={loading ? clsx([classes.inner, classes.disabled]) : classes.inner}>
                    <input
                      {...getInputProps()}
                      id={`dropDocumentInput-${documentType}`}
                      data-testid={`dropDocumentInput-${documentType}`}
                    />

                    <Typography className={classes.uploadMessage}>{uploadMessage}</Typography>
                  </Box>

                  {loading && <CircularProgress className={classes.progress} />}
                </Box>

                {error && <CustomInputError message={typeof error === 'string' ? error : t('global.error')} />}
              </Grid>
            </Grid>
          </section>
        )}
      </Dropzone>
      <FileDropInfo
        clearDuplicates={() => setState((prev) => ({ ...prev, duplicates: [] }))}
        duplicates={duplicates}
        handleClose={toggleDuplicateModal}
        isOpen={showDuplicates}
        continueUpload={() => continueUpload(accFiles, rejFiles)}
      />
    </>
  );
};

export default FileDrop;
