import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStepLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiStepLabel-active': {
        fontWeight: 700,
        color: theme.palette.common.black,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: theme.palette.greyText.main,
    },
    disabled: {
      opacity: 0.5,
    },
  }),
);

export const useStepIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 16,
      height: 16,
      borderRadius: '50%',
      border: `2px solid ${theme.palette.greyText.main}`,
      opacity: 0.5,
    },
    circleActive: {
      border: `2px solid ${theme.palette.common.black}`,
      opacity: 1,
    },
    completed: {
      color: theme.palette.primary.main,
      fontSize: 25,
      marginLeft: -2,
      marginRight: -3,
    },
  }),
);

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 300,
      padding: 40,
      paddingTop: 180,
      position: 'absolute',
      top: 0,
      right: -450,
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 700,
      color: theme.palette.greyText.main,
      marginLeft: theme.spacing(8),
    },
  }),
);
