import { createSlice, ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';

import { patchApplicant, createApplication } from 'store/onboard/actions';
import { clearApplication, updateAppState } from 'store/app/actions';
import { setError as setGlobalError } from 'store/global/actions';
import { AuthState } from './types';
import {
  clearError,
  initAuth,
  setVerified,
  getLeadApplications,
  reApply,
  signIn,
  setActiveApplication,
} from './actions';

export const initialState: AuthState = {
  loading: false,
  error: false,
  accessToken: null,
  email: null,
  verified: false,
  leadApplications: null,
  adminEditMode: false,
  widgetToken: null,
};

const authStore = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(clearError, (state) => {
      state.error = false;
    });

    builder.addCase(initAuth.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.email = payload;
    });

    builder.addCase(patchApplicant.fulfilled, (state, { payload }) => {
      if (payload?.email) {
        state.email = payload.email;
      }
    });

    builder.addCase(clearApplication, () => initialState);

    builder.addCase(setGlobalError, (state, { payload }) => {
      if (!payload) {
        state.error = payload;
      }
    });

    builder.addCase(createApplication.fulfilled, (state, { payload }) => {
      if (payload.applicant.email) {
        state.email = payload.applicant.email;
      }
      state.accessToken = payload.access_token;
      state.leadApplications = null;
      state.widgetToken = null;
    });

    builder.addCase(updateAppState, (state, { payload }) => {
      if (payload.accessToken) {
        state.accessToken = payload.accessToken;
      }
      state.adminEditMode = !!payload.adminEditMode;
    });

    builder.addCase(setVerified, (state) => {
      state.verified = true;
    });

    builder.addCase(getLeadApplications.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        const { leadApplications, accessToken, widgetToken } = payload;
        if (leadApplications) state.leadApplications = leadApplications;
        if (accessToken) state.accessToken = accessToken;
        if (widgetToken) state.widgetToken = widgetToken;
      }
    });

    builder.addCase(reApply.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accessToken = payload;
    });

    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accessToken = payload;
    });

    builder.addCase(setActiveApplication, (state, { payload }) => {
      if (state.leadApplications) {
        state.leadApplications.active.push(payload);
      }
    });

    builder.addMatcher(
      isAnyOf(initAuth.pending, getLeadApplications.pending, reApply.pending, signIn.pending),
      (state) => {
        state.loading = true;
        state.error = false;
      },
    );

    builder.addMatcher(
      isAnyOf(initAuth.rejected, getLeadApplications.rejected, reApply.rejected, signIn.rejected),
      (state, { error }) => {
        state.loading = false;
        state.error = error?.message || true;
      },
    );
  },
});

export default authStore.reducer;
