import { FC } from 'react';
import moment from 'moment';
import { FormikErrors, FormikTouched } from 'formik';
import i18n from 'i18next';

import { CustomNumberFormat } from 'components/inputs';
import { getDateFromBirthDate, parsePhoneNumber, timeDiffCalc } from 'utils';
import { Address } from 'store/onboard/types';
import { AddressStatus, AreaPhoneCode, SupportedCountryCode } from 'core/types';
import yup from './yup-extended';
import { Applicant } from '../../../store/onboard/types';

const today = new Date();
const eighteenYearsAgo = moment().subtract(18, 'years').endOf('day').toDate();
const phoneNumberRegExp = /^(\d){3}-(\d){4}-(\d){4}$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PhoneNumberInput: FC = (props: any) => (
  <CustomNumberFormat {...props} format="###-####-####" type="tel" useFormattedValue />
);

export const isAddressAgeValid = (addresses: Partial<FormAddress>[] | null): boolean => {
  if (!addresses) return true;

  let isValid = false;
  addresses.forEach((address) => {
    if (address.start_date) {
      const timeDiff = timeDiffCalc(today, address.start_date, 'years');
      if (timeDiff >= 3) {
        isValid = true;
      }
    }
  });
  return isValid;
};

export const getAddressFieldError = (
  touched: FormikTouched<FormValues>,
  errors: FormikErrors<FormValues>,
  index: number,
  fieldName: string,
): string | undefined => {
  return (
    touched.addresses &&
    touched.addresses[index] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    touched.addresses[index][fieldName] &&
    errors.addresses &&
    typeof errors.addresses !== 'string' &&
    (errors.addresses[index] as Partial<Address>) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (errors.addresses[index] as Partial<Address>)[fieldName]
  );
};

export const initialAddress = {
  house_number: null,
  line_1: null,
  line_2: null,
  city: null,
  county: null,
  country_code: SupportedCountryCode.UNITED_KINGDOM,
  postcode: null,
  current: false,
  start_date: null,
  status: '',
  property_equity: null,
};

export type FormAddress = Omit<Partial<Address>, 'start_date'> & { start_date: Date | null };

export interface FormValues {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  area_code: string | null;
  phone_number: string | null;
  date_of_birth: Date | null;
  addresses: FormAddress[];
}

export const formSchema = yup.object({
  first_name: yup.string().required(i18n.t('pages.applicantInfo.inputs.first_name.required')).nullable(),
  last_name: yup.string().required(i18n.t('pages.applicantInfo.inputs.last_name.required')).nullable(),
  email: yup
    .string()
    .email(i18n.t('pages.applicantInfo.inputs.email.error'))
    .required(i18n.t('pages.applicantInfo.inputs.email.required'))
    .nullable(),
  area_code: yup.string().required(i18n.t('pages.applicantInfo.inputs.area_code.required')).nullable(),
  phone_number: yup
    .string()
    .matches(phoneNumberRegExp, i18n.t('pages.applicantInfo.inputs.phone_number.valid'))
    .required(i18n.t('pages.applicantInfo.inputs.phone_number.required'))
    .nullable(),
  date_of_birth: yup
    .date()
    .max(eighteenYearsAgo, ({ max }) =>
      i18n.t('pages.applicantInfo.inputs.date_of_birth.error', { date: moment(max).format('DD MMMM YYYY') }),
    )
    .required(i18n.t('pages.applicantInfo.inputs.date_of_birth.required'))
    .nullable(),
  addresses: yup
    .array(
      yup.object({
        house_number: yup.string().required(i18n.t('pages.applicantInfo.inputs.house_number.required')).nullable(),
        line_1: yup.string().required(i18n.t('pages.applicantInfo.inputs.line_1.required')).nullable(),
        line_2: yup.string().nullable(),
        city: yup.string().nullable(),
        county: yup.string().nullable(),
        country_code: yup.string().required(i18n.t('pages.applicantInfo.inputs.country_code.required')).nullable(),
        postcode: yup.string().required(i18n.t('pages.applicantInfo.inputs.postcode.required')).nullable(),
        current: yup.boolean(),
        start_date: yup.string().required(i18n.t('pages.applicantInfo.inputs.start_date.required')).nullable(),
        status: yup.string().required(i18n.t('pages.applicantInfo.inputs.status.required')).nullable(),
        property_equity: yup
          .number()
          .when('status', {
            is: (val: string | null) => val?.includes(AddressStatus.OWNER_WITH_MORTGAGE),
            then: yup
              .number()
              .min(0, i18n.t('pages.applicantInfo.inputs.property_equity.error'))
              .required(i18n.t('pages.applicantInfo.inputs.property_equity.required'))
              .nullable(),
          })
          .nullable(),
      }),
    )
    .oneCurrent(i18n.t('pages.applicantInfo.inputs.current.required'), (a: Partial<Address>) => a.current)
    .uniqueCurrent(i18n.t('pages.applicantInfo.inputs.current.error'), (a: Partial<Address>) => a.current),
});

export const getInitialValues = (applicant: Applicant): FormValues => ({
  first_name: applicant.first_name,
  last_name: applicant.last_name,
  email: applicant.email,
  area_code: applicant.area_code || AreaPhoneCode.GB,
  phone_number: applicant.phone_number ? parsePhoneNumber(applicant.phone_number) : null,
  date_of_birth: applicant.date_of_birth ? getDateFromBirthDate(applicant.date_of_birth) : null,
  addresses:
    applicant.addresses?.length > 0
      ? applicant.addresses.map((addr) => ({
          ...addr,
          start_date: addr.start_date ? new Date(addr.start_date) : null,
          current: !!addr.current,
          country_code: addr.country_code ? addr.country_code : SupportedCountryCode.UNITED_KINGDOM,
        }))
      : [initialAddress],
});
