import { FC } from 'react';
import { RadioGroup, Box, FormControlLabel, Radio } from '@material-ui/core';
import { FieldProps } from 'formik';

import { CustomLabel } from '../CustomLabel';
import { CustomInputError } from '../CustomInputError';
import useStyles from './CustomRadioField.styles';

interface CustomRadioFieldProps extends FieldProps {
  title: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

const CustomRadioField: FC<CustomRadioFieldProps> = ({
  form,
  field,
  title,
  options,
  tooltipMessage,
  error,
  helperText,
  optional = false,
  ...rest
}) => {
  const classes = useStyles();
  const hasError = !!(form.touched[field.name] && form.errors[field.name]) || error;

  return (
    <Box>
      <FormControlLabel
        control={
          <RadioGroup {...rest}>
            {options.map((option) => (
              <FormControlLabel
                key={`${option.value}`}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        }
        label={<CustomLabel title={title} tooltipMessage={tooltipMessage} optional={optional} />}
        className={classes.container}
      />

      {hasError && <CustomInputError message={(form.errors[field.name] || helperText) as string} />}
    </Box>
  );
};

export default CustomRadioField;
