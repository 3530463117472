import { FC } from 'react';
import { InputLabel, NativeSelect, Box } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { FieldProps } from 'formik';

import { CustomLabel } from '../CustomLabel';
import useStyles from './CustomSelect.styles';
import { CustomInputError } from '../CustomInputError';

interface CustomSelectProps extends FieldProps {
  id: string;
  title: string;
  placeholder?: string;
  options: { label: string; value: string }[];
  tooltipMessage?: string;
  error?: boolean;
  helperText?: string;
  optional?: boolean;
}

const CustomSelect: FC<CustomSelectProps> = ({
  form,
  field,
  title,
  options,
  tooltipMessage,
  error,
  helperText,
  optional = false,
  ...rest
}) => {
  const classes = useStyles();

  const hasError = error || (form.touched[field.name] && !!form.errors[field.name]);

  return (
    <Box className={classes.selectContainer}>
      <InputLabel htmlFor={rest.id}>
        <CustomLabel title={title} tooltipMessage={tooltipMessage} optional={optional} />
      </InputLabel>

      <NativeSelect IconComponent={() => <ExpandMore className={classes.expandIcon} />} error={hasError} {...rest}>
        {options.map(({ label, value }) => {
          return (
            <option key={`option${value}`} value={value}>
              {label}
            </option>
          );
        })}
      </NativeSelect>

      {hasError && <CustomInputError message={(form.errors[field.name] || helperText) as string} />}
    </Box>
  );
};

export default CustomSelect;
