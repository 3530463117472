import { FC } from 'react';
import { Typography } from '@material-ui/core';

import { ReactComponent as BSLetterBlack } from 'assets/icons/BusinessScorelogo-thumbnail.svg';
import useStyles from './PoweredBy.styles';

const PoweredBy: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.smallText}>
        <span>Powered by</span>
      </Typography>
      <BSLetterBlack className={classes.bsLetterLogo} />;
    </>
  );
};

export default PoweredBy;
