import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      flex: 1,
    },
    gridContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    codeInputContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: 'fit-content',
      position: 'relative',
    },
    codeInputLabel: {
      fontSize: 16,
      fontWeight: 600,
      textAlign: 'center',
      marginBottom: 20,
    },
    codeInput: {
      display: 'flex !important',
      alignItems: 'center',
      justifyContent: 'space-around',
      width: '100%',
      maxWidth: 280,
      '& input': {
        width: 40,
        height: 54,
        borderRadius: 8,
        borderColor: theme.palette.greyIcon.main,
        padding: 0,
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: 700,
        marginLeft: 6,
        marginRight: 6,
        borderStyle: 'solid',
        borderWidth: 1,
        outline: 'none',
        '&:focus, &:not([value=""]):not(:focus)': {
          borderColor: theme.palette.primary.main,
          borderWidth: 2,
        },
      },
    },
    codeInputError: {
      '& input': {
        borderColor: theme.palette.error.main,
      },
    },
    codeError: {
      marginTop: 15,
      marginLeft: -14,
      alignSelf: 'flex-start',
    },
    loaderContainer: {
      position: 'absolute',
    },
    button: {
      width: 285,
      height: 46,
      marginTop: theme.spacing(7),
    },
  }),
);
