import { FC } from 'react';
import { Typography, Button, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Layout } from 'components/Layout';
import { noop } from 'utils';
import routes from 'router/routes';
import useCommonClasses from 'core/styles';
import useStyles from './CreditAgreement.styles';

const CreditAgreement: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { state } = useLocation<{ offerId: string }>();
  const commonClasses = useCommonClasses();

  const onNext = () => {
    push({
      pathname: routes.bankDetails,
      state: { offerId: state.offerId },
    });
  };

  return (
    <Layout headerTitle={t('pages.creditAgreement.header')} onMainAction={onNext}>
      <Typography className={commonClasses.title2}>{t('pages.creditAgreement.title')}</Typography>

      <Typography className={commonClasses.subtitle2}>{t('pages.creditAgreement.description')}</Typography>

      <Button
        onClick={noop}
        className={classes.agreeButton}
        variant="contained"
        color="primary"
        disableElevation
        fullWidth
      >
        {t('pages.creditAgreement.buttons.main')}
      </Button>

      <Button
        onClick={noop}
        className={classes.downloadButton}
        variant="outlined"
        color="primary"
        disableElevation
        fullWidth
      >
        {t('pages.creditAgreement.buttons.secondary')}
      </Button>

      <Divider className={classes.divider} />

      <Box className={classes.lenderContainer}>
        <Typography className={classes.lenderTitle}>{t('pages.creditAgreement.sections.lender.title')}</Typography>

        <Typography className={classes.lenderInfo}>{t('pages.creditAgreement.sections.lender.info')}</Typography>

        <Typography className={classes.lenderAlias}>{t('pages.creditAgreement.sections.lender.referredTo')}</Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box className={classes.lenderContainer} style={{ marginBottom: 24 }}>
        <Typography className={classes.lenderTitle}>{t('pages.creditAgreement.sections.recipient.title')}</Typography>

        <Typography className={classes.lenderInfo}>{t('pages.creditAgreement.sections.recipient.info')}</Typography>

        <Typography className={classes.lenderAlias}>
          {t('pages.creditAgreement.sections.recipient.referredTo')}
        </Typography>
      </Box>
    </Layout>
  );
};

export default CreditAgreement;
