import { combineReducers } from '@reduxjs/toolkit';

import onboardReducer, { initialState as onboardInitialState } from './onboard/reducers';
import offersReducer, { initialState as offersInitialState } from './offers/reducers';
import appReducer, { initialState as appInitialState } from './app/reducers';
import authReducer, { initialState as authInitialState } from './auth/reducers';
import globalReducer, { initialState as globalInitialState } from './global/reducers';

export const rootInitialState = {
  onboard: onboardInitialState,
  offers: offersInitialState,
  app: appInitialState,
  auth: authInitialState,
  global: globalInitialState,
};

const rootReducer = combineReducers({
  onboard: onboardReducer,
  offers: offersReducer,
  app: appReducer,
  auth: authReducer,
  global: globalReducer,
});

export default rootReducer;
