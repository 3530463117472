import { useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { getOffers, clearError, acceptOffer, setSelectedOfferId } from './actions';
import { RootState } from '../types';
import { OffersHookReturn } from './types';

export const useOffers = (): OffersHookReturn => {
  const offersState = useSelector((state: RootState) => state.offers);

  return {
    ...offersState,
    getOffers: useActionCreator(getOffers),
    clearError: useActionCreator(clearError),
    acceptOffer: useActionCreator(acceptOffer),
    setSelectedOfferId: useActionCreator(setSelectedOfferId),
  };
};
