import { FontOptions } from '../types';

export const fonts: FontOptions = {
  Inter: {
    url: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap',
    fontFamily: 'Inter, Roboto, sans-serif',
  },
  Rubik: {
    url: 'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap',
    fontFamily: 'Rubik, Roboto, sans-serif',
  },
  Itim: {
    url: 'https://fonts.googleapis.com/css2?family=Itim&display=swap',
    fontFamily: 'Itim, Roboto, cursive',
  },
  Raleway: {
    url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&display=swap',
    fontFamily: 'Raleway, Roboto, sans-serif',
  },
  Lato: {
    url: 'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap',
    fontFamily: 'Lato, Roboto, sans-serif',
  },
  Nunito: {
    url: 'https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap',
    fontFamily: 'Nunito, Roboto, sans-serif',
  },
  Antonio: {
    url: 'https://fonts.googleapis.com/css2?family=Antonio:wght@300;400;600;700&display=swap',
    fontFamily: 'Antonio, Roboto, sans-serif',
  },
};
