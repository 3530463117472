import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    demo: {},
    deleteAvatar: {
      height: '20px',
      width: '20px',
    },
    deleteIcon: {
      fontSize: '10px',
    },
    sectionTitle: {
      textAlign: 'center',
      fontSize: 14,
      lineHeight: '17px',
      letterSpacing: -0.336,
      color: theme.palette.customGrey.main,
      fontWeight: 600,
      marginBottom: 5,
    },
    fileTitle: {
      marginTop: 0,
      marginBottom: 0,

      '& span': {
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: -0.336,
      },
    },
  }),
);
