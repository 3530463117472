import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0px 2px 8px 2px rgba(206, 213, 219, 0.40464)',
      borderRadius: 12,
      padding: 12,
      marginBottom: theme.spacing(2),
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    iconContainer: {
      height: 24,
      width: 24,
      borderRadius: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(1.5),
      backgroundColor: theme.palette.primary.main,
      '& svg, & img': {
        height: '55%',
        width: '55%',
      },
      '& svg, & svg path': {
        fill: theme.palette.common.white,
      },
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: 600,
    },
    content: {
      marginTop: theme.spacing(2),
    },
    button: {
      '& .MuiButton-startIcon': {
        width: '100%',
        maxWidth: 88,
        height: 'auto',
        marginLeft: -44,
      },
    },
    list: {
      paddingTop: theme.spacing(3),
    },
    listItem: {
      height: 56,
    },
    listItemIcon: {
      marginRight: theme.spacing(2),
      '& svg, & img': {
        width: '100%',
        maxWidth: 44,
        height: 'auto',
      },
    },
    listItemText: {
      '& .MuiListItemText-primary': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
      },
      '& .MuiListItemText-secondary': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(18),
        color: theme.palette.greyText.main,
      },
    },
  }),
);
