import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import analytics from 'core/services/analytics';
import { history } from 'router/service';
import routes from 'router/routes';
import { useActionCreator } from 'hooks';
import { RootState } from '../types';
import { AppHookReturn } from './types';
import {
  updateAppStateAction,
  getApplication,
  setError,
  clearApplication as clearApplicationAction,
  setAppRegion,
  showPolicy,
  showTerms,
  agreeTerms,
} from './actions';

export const useAppState = (): AppHookReturn => {
  const appState = useSelector((state: RootState) => state.app, shallowEqual);
  const dispatch = useDispatch();

  const clearApplication = useCallback(
    (logout?: boolean) => {
      dispatch(clearApplicationAction(logout));
      if (logout) {
        history.push(routes.logout);
        analytics.reset();
      }
    },
    [dispatch],
  );

  return {
    ...appState,
    updateAppState: useActionCreator(updateAppStateAction),
    getApplication: useActionCreator(getApplication),
    setError: useActionCreator(setError),
    clearApplication,
    setAppRegion: useActionCreator(setAppRegion),
    showPolicy: useActionCreator(showPolicy),
    showTerms: useActionCreator(showTerms),
    agreeTerms: useActionCreator(agreeTerms),
  };
};
