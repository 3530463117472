import { ChangeEvent, FC } from 'react';
import { Grid, Slider, SliderProps, Input, Box, Tooltip, Typography, InputLabel } from '@material-ui/core';

import useStyles, { useSliderStyles } from './CustomSliderField.styles';
import { CustomLabel } from '../CustomLabel';

interface ValueLabelComponentProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

interface CustomSliderFieldProps extends Omit<SliderProps, 'onChange'> {
  id: string;
  title: string;
  onChange: (value: number) => void;
  tooltipMessage?: string;
  optional?: boolean;
}

const CustomSliderField: FC<CustomSliderFieldProps> = ({
  id,
  title,
  onChange,
  step,
  min,
  max,
  value,
  tooltipMessage,
  optional = false,
  ...rest
}) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(Number(event.target.value));
  };

  return (
    <Box>
      <InputLabel htmlFor={`${id}_input`}>
        <CustomLabel
          title={title}
          containerStyle={{ paddingBottom: '5px' }}
          tooltipMessage={tooltipMessage}
          optional={optional}
        />
      </InputLabel>

      <Grid container spacing={3} alignItems="center">
        {/* <Grid item>%</Grid> */}

        <Grid item xs={6}>
          <Slider
            id={id}
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
            value={value}
            ValueLabelComponent={ValueLabelComponent}
            classes={sliderClasses}
            {...rest}
          />
        </Grid>

        <Grid item xs={2}>
          <Box className={classes.inputContainer}>
            <Input
              value={value}
              onChange={handleInputChange}
              inputProps={{
                step,
                min,
                max,
                type: 'number',
              }}
              margin="dense"
              className={classes.input}
              id={`${id}_input`}
            />
            <Typography>%</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomSliderField;
