import { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// import { useHistory, useLocation } from 'react-router-dom';

import useCommonStyles from 'core/styles';
import { CustomTextField, CustomNumberFormat } from 'components/inputs';
import { Layout } from 'components/Layout';
// import routes from 'router/routes';
import { Loader } from 'components/Loader';
// import { useOffers } from 'store/offers/hooks';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AccountNumberInput = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} type="tel" format="########" />
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SortCodeInput = (props: any) => (
  <CustomNumberFormat {...props} thousandSeparator={false} format="##-##-##" type="tel" useFormattedValue />
);

const BankDetails: FC = () => {
  const [loading, setLoading] = useState(false);
  const commonClasses = useCommonStyles();
  // const { borrowOffer } = useOffers();
  const { t } = useTranslation();
  // const { state } = useLocation<{ offerId: string }>();

  const companyInfoSchema = yup.object({
    company_name: yup.string().required(t('pages.bankDetails.inputs.company_name.required')).nullable(),
    account_number: yup
      .string()
      // UK Bank Account (7-8 Digits)
      .matches(/^(\d){7,8}$/, t('pages.bankDetails.inputs.account_number.error'))
      .required(t('pages.bankDetails.inputs.account_number.required'))
      .nullable(),
    sort_code: yup
      .string()
      // UK Bank Sort Code regex
      .matches(/^(\d){2}-(\d){2}-(\d){2}$/, t('pages.bankDetails.inputs.sort_code.error'))
      .required(t('pages.bankDetails.inputs.sort_code.required'))
      .nullable(),
  });

  const initialValues = {
    company_name: null,
    account_number: null,
    sort_code: null,
  };

  const onSubmit = (/* values: typeof initialValues */) => {
    setLoading(true);
    // borrowOffer(state.offerId as string);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={companyInfoSchema}>
      {({ handleChange, values, handleSubmit }) => (
        <Layout
          headerTitle={t('pages.bankDetails.title')}
          onMainAction={handleSubmit}
          mainButtonTitle={t('pages.bankDetails.buttons.main')}
          loading={loading}
          Loader={
            <Loader
              visible
              title={t('pages.bankDetails.loader.title')}
              description={t('pages.bankDetails.loader.description')}
            />
          }
        >
          {!loading && (
            <Form noValidate className={commonClasses.form}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Field
                    id="company_name"
                    fullWidth
                    component={CustomTextField}
                    autoFocus
                    name="company_name"
                    value={values.company_name || ''}
                    onChange={handleChange}
                    title={t('pages.bankDetails.inputs.company_name.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.bankDetails.inputs.company_name.placeholder')}
                    className={commonClasses.textInput}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="account_number"
                    fullWidth
                    component={CustomTextField}
                    name="account_number"
                    value={values.account_number || ''}
                    onChange={handleChange}
                    title={t('pages.bankDetails.inputs.account_number.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.bankDetails.inputs.account_number.placeholder')}
                    className={commonClasses.textInput}
                    InputProps={{
                      inputComponent: AccountNumberInput,
                    }}
                  />
                </Grid>

                <Grid item>
                  <Field
                    id="sort_code"
                    fullWidth
                    component={CustomTextField}
                    name="sort_code"
                    value={values.sort_code || ''}
                    onChange={handleChange}
                    title={t('pages.bankDetails.inputs.sort_code.label')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={t('pages.bankDetails.inputs.sort_code.placeholder')}
                    className={commonClasses.textInput}
                    InputProps={{
                      inputComponent: SortCodeInput,
                    }}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Layout>
      )}
    </Formik>
  );
};

export default BankDetails;
