import routes from 'router/routes';

export const businessScore = {
  phoneNumber: '020 331 812 63',
  website: 'scorethebusiness.com',
  websiteTerms: 'https://www.scorethebusiness.com/terms',
  websitePolicy: 'https://www.scorethebusiness.com/privacy',

  calendly: {
    brokers: 'https://calendly.com/admins-/funding-call',
    kyc: 'https://calendly.com/admins-/funding-call',
  },
} as const;

export const appSteps = [
  { step: 0, label: 'Get Started', route: routes.home },
  { step: 1, label: 'Company Information', route: routes.companyInfo },
  { step: 2, label: 'Applicant Information', route: routes.applicantInfo },
  { step: 3, label: 'Funding Goals', route: routes.fundingGoal },
  { step: 5, label: 'Additional Information', route: routes.customiseGoal },
  { step: 6, label: 'Your Offers', route: routes.offers },
  // { step: 7, label: 'Credit Agreement', route: routes.creditAgreement },
  // { step: 8, label: 'Confirm Bank Details', route: routes.bankDetails },
  // { step: 9, label: 'Funding Completed', route: routes.finish },
];

export const widgetDefaultWidth = '100%';

export const widgetDefaultHeight = '450px';

export const platformSupportEmail = 'platform-support@scorethebusiness.com';
