import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from '@reduxjs/toolkit';

import { setError } from 'store/global/actions';
import { RootState } from 'store/types';
import http from 'http/index';
import { isNetWorkError, accept5xx } from 'http/utils';
import { IAxiosRetryConfig } from 'axios-retry';

interface RetryConfig extends IAxiosRetryConfig {
  retryCount: number;
}

export default {
  setup: (store: Store<RootState>): void => {
    const addAuthorizationToken = (config: AxiosRequestConfig) => {
      const {
        app: { publicKey, hostDomain },
        auth: { accessToken },
      } = store.getState();

      if (publicKey && config.url !== 'partner/widget/config') {
        config.headers['x-bs-pk'] = publicKey;
      }

      if (config.url !== 'auth/companies/jwt' && accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      config.headers['x-bs-origin'] = hostDomain ?? window.location.hostname;

      return config;
    };

    const clearGlobalErrors = (response: AxiosResponse) => {
      const appError = store.getState().global.error;
      if (appError) store.dispatch(setError(false));
      return response;
    };

    const checkForGlobalError = (error: AxiosError) => {
      const retryConfig = error.config['axios-retry'] as RetryConfig | undefined;
      const currentRetryAttempt: number = retryConfig?.retryCount || 0;
      const responseStatusCode = error.response?.status;

      if (
        (isNetWorkError(error) || (responseStatusCode && accept5xx(responseStatusCode))) &&
        currentRetryAttempt === 1
      ) {
        store.dispatch(setError(true));
      } else {
        store.dispatch(setError(false));
      }

      return Promise.reject(error);
    };

    http.interceptors.request.use(addAuthorizationToken);
    http.interceptors.response.use(clearGlobalErrors, checkForGlobalError);
  },
};
