import { FC, useState } from 'react';
import { Box, Fade, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'store/auth/hooks';
import { useAppState } from 'store/app/hooks';
import useCommonStyles from 'core/styles';

const AppsMenu: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { leadApplications, signIn } = useAuth();
  const { application_id: currentApplicationId } = useAppState();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (appId: string) => {
    if (currentApplicationId !== appId) {
      signIn(appId);
    }

    handleClose();
  };

  if (!currentApplicationId || !leadApplications || leadApplications.active.length <= 1) {
    return null;
  }

  return (
    <Box>
      <IconButton aria-label="more" aria-controls="apps-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        id="apps-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 240,
        }}
        PaperProps={{ style: { width: 240 } }}
        TransitionComponent={Fade}
      >
        <MenuItem divider dense disabled>
          <Typography variant="caption" className={commonClasses.subtitle2}>
            {t('menu.companies')}
          </Typography>
        </MenuItem>
        {leadApplications.active.map((app) => (
          <MenuItem
            key={app.application_id}
            selected={currentApplicationId === app.application_id}
            onClick={() => handleSelect(app.application_id)}
          >
            <span className={commonClasses.subtitle2}>{app.company_name}</span>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AppsMenu;
