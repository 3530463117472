import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      color: theme.palette.primary.light,
      borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
  }),
);
