import { FormikTouched, FormikErrors } from 'formik';
import i18n from 'i18next';
import * as yup from 'yup';
import moment from 'moment';

import { FundingReasons } from 'core/types';
import { CCJType, CompanyCCJ } from 'store/onboard/types';
import { parseMoney } from 'utils/currency';
import { fundingReasonOptions, minFundingAmount } from 'core/constants';
import { Company } from '../../../store/onboard/types';

export interface FormValues {
  funding_amount: number | null;
  funding_reasons: FundingReasons[];
  funding_date: Date | null;
  monthly_revenue: number | null;
  year_to_date_revenue: number | null;
  online_sales_revenue_percentage: number | null;
  repeat_customers_revenue_percentage: number;
  any_ccj: boolean;
  ccjs: CompanyCCJ[];
}

const today = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);

export const getCcjFieldError = (
  touched: FormikTouched<FormValues>,
  errors: FormikErrors<FormValues>,
  index: number,
  fieldName: string,
): string | undefined => {
  return (
    touched.ccjs &&
    touched.ccjs[index] &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    touched.ccjs[index][fieldName] &&
    errors.ccjs &&
    typeof errors.ccjs !== 'string' &&
    (errors.ccjs[index] as Partial<CompanyCCJ>) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (errors.ccjs[index] as Partial<CompanyCCJ>)[fieldName]
  );
};

export const getFormSchema = (creditLimit: number): yup.AnyObjectSchema =>
  yup.object({
    funding_amount: yup
      .number()
      .min(minFundingAmount, ({ min }) =>
        i18n.t('pages.fundingGoal.inputs.funding_amount.min', { min: parseMoney(min) }),
      )
      .max(creditLimit, ({ max }) => i18n.t('pages.fundingGoal.inputs.funding_amount.max', { max: parseMoney(max) }))
      .required(i18n.t('pages.fundingGoal.inputs.funding_amount.required'))
      .nullable(),
    funding_reasons: yup
      .array(yup.string())
      .min(1, i18n.t('pages.fundingGoal.inputs.funding_reasons.error'))
      .required(i18n.t('pages.fundingGoal.inputs.funding_reasons.required'))
      .nullable(),
    funding_date: yup
      .date()
      .min(yesterday, ({ min }) =>
        i18n.t('pages.fundingGoal.inputs.funding_date.error', { date: moment(min).format('DD MMMM YYYY') }),
      )
      .nullable(),
    monthly_revenue: yup
      .number()
      .min(1, i18n.t('pages.fundingGoal.inputs.monthly_revenue.error'))
      .required(i18n.t('pages.fundingGoal.inputs.monthly_revenue.required'))
      .nullable(),
    year_to_date_revenue: yup.number().min(1, i18n.t('pages.fundingGoal.inputs.year_to_date_revenue.error')).nullable(),
    online_sales_revenue_percentage: yup
      .number()
      .required(i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.required'))
      .min(0, i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.error'))
      .max(100, i18n.t('pages.fundingGoal.inputs.online_sales_revenue_percentage.error'))
      .nullable(),
    repeat_customers_revenue_percentage: yup
      .number()
      .min(0, i18n.t('pages.fundingGoal.inputs.repeat_customers_revenue_percentage.error'))
      .max(100, i18n.t('pages.fundingGoal.inputs.repeat_customers_revenue_percentage.error'))
      .nullable(),
    any_ccj: yup.boolean(),
    ccjs: yup.array(
      yup.object({
        type: yup
          .string()
          .oneOf([CCJType.ACTIVE, CCJType.HISTORIC])
          .required(i18n.t('pages.fundingGoal.inputs.ccj_type.required')),
        description: yup.string().required(i18n.t('pages.fundingGoal.inputs.ccj_description.required')),
      }),
    ),
  });

export const getInitialValues = (company: Company, creditLimit: number): FormValues => {
  const fundingAmount = company.funding_amount ?? 0;
  const yearToDateRevenue = company.year_to_date_revenue || (company.monthly_revenue || 0) * 12 || null;

  return {
    funding_amount: fundingAmount > creditLimit ? creditLimit : fundingAmount,
    funding_reasons: fundingReasonOptions
      .filter((o) => company.funding_reasons && company.funding_reasons?.indexOf(o.value) >= 0)
      .map(({ value }) => value),
    funding_date: company.funding_date ? moment(company.funding_date).toDate() : null,
    monthly_revenue: company.monthly_revenue,
    year_to_date_revenue: yearToDateRevenue,
    online_sales_revenue_percentage:
      typeof company.online_sales_revenue_percentage === 'number'
        ? company.online_sales_revenue_percentage * 100
        : null,
    repeat_customers_revenue_percentage: (company.repeat_customers_revenue_percentage || 0) * 100,
    any_ccj: !!company.any_ccj,
    ccjs: company.ccjs || [],
  };
};
