/* eslint-disable @typescript-eslint/no-explicit-any */
import zoid from 'zoid';

import { Modal } from './Modal';

const defaultWidth = '100%';
const defaultHeight = '450px';

const ZoidComponent = zoid.create({
  tag: 'bussiness-score-widget',
  dimensions: {
    width: defaultWidth,
    height: defaultHeight,
  },
  autoResize: {
    width: false,
    height: true,
  },
  url: process.env.REACT_APP_WIDGET_URL,
  props: {
    opened: {
      type: 'boolean',
      required: false,
    },
  },
});

interface BusinessScoreWidgetParams {
  elementId: string;
  publicKey: string;
  popup?: boolean;
  width?: string;
  height?: string;
  fontName?: string;
  logoUrl?: string;
  letterLogoUrl?: string;
  primaryColor?: string;
  secondaryColor?: string;
  partnerName?: string;
  captchaEnabled?: boolean;
  accessToken?: string;
  kycEnabled?: boolean;
  exitEnabled?: boolean;
  faqEnabled?: boolean;
  homeTitle?: string;
  homeSubTitle?: string;
  skipHomePage?: boolean;
  customCalendlyLink?: string;
  onClose?: () => void;
}

type WidgetUpdateParams = Omit<BusinessScoreWidgetParams, 'elementId'>;

const win = window as any;

interface ZoidInstance {
  render(element: string | HTMLElement): Promise<void>;
  updateProps(props: Record<string, unknown>): void;
}

let onWidgetClose: (() => void) | undefined;

win.BusinessScoreWidget = ({
  elementId,
  popup,
  publicKey,
  width,
  height,
  fontName,
  logoUrl,
  letterLogoUrl,
  primaryColor,
  secondaryColor,
  partnerName,
  captchaEnabled,
  accessToken,
  kycEnabled,
  exitEnabled,
  faqEnabled,
  homeTitle,
  homeSubTitle,
  skipHomePage,
  customCalendlyLink,
  onClose,
}: BusinessScoreWidgetParams) => {
  const modalId = `modal-${Math.random()}`;

  let rendered = false;
  let shadowRoot: ShadowRoot;
  let modal: HTMLElement | null;
  let zoidInstance: ZoidInstance | null;
  onWidgetClose = onClose;

  const renderInlineWidget = () => {
    if (rendered) {
      win.document.getElementById(elementId).style.display = 'block';
    } else {
      zoidInstance?.render(`#${elementId}`);
    }
  };

  const showPopup = () => {
    modal?.classList.add('open');
  };

  const hidePopup = (triggerOnClose = true) => {
    modal?.classList.remove('open');
    zoidInstance?.updateProps({ opened: false });
    if (triggerOnClose && onWidgetClose) onWidgetClose();
  };

  const renderPopupWidget = () => {
    if (rendered) {
      showPopup();
    } else {
      const container = win.document.getElementById(elementId);
      shadowRoot = container.attachShadow({ mode: 'closed' });
      shadowRoot.innerHTML = Modal(modalId, width || defaultWidth, height || defaultHeight);

      modal = shadowRoot.getElementById(modalId);

      if (!modal) {
        throw new Error('Something went wrong');
      }

      const modalContainer = modal.getElementsByClassName('modal-container').item(0);

      showPopup();
      const exits = modal.querySelectorAll('.modal-exit');
      exits.forEach((exit: Element) => {
        exit.addEventListener('click', (event) => {
          event.preventDefault();
          hidePopup();
        });
      });
      zoidInstance?.render(modalContainer as HTMLElement);
    }
  };

  const closeInlineWidget = (triggerOnClose = true) => {
    win.document.getElementById(elementId).style.display = 'none';
    zoidInstance?.updateProps({ opened: false });
    if (triggerOnClose && onWidgetClose) onWidgetClose();
  };

  const hide = (triggerOnClose = true) => {
    if (!rendered) {
      return;
    }

    if (popup) {
      hidePopup(triggerOnClose);
    } else {
      closeInlineWidget(triggerOnClose);
    }
  };

  const show = () => {
    if (popup) {
      renderPopupWidget();
    } else {
      renderInlineWidget();
    }

    rendered = true;
    zoidInstance?.updateProps({ opened: true });
  };

  const update = (params: WidgetUpdateParams) => {
    onWidgetClose = params.onClose;
    zoidInstance?.updateProps({ ...params });
  };

  zoidInstance = ZoidComponent({
    popup,
    publicKey,
    width: width ?? defaultWidth,
    height: height ?? defaultHeight,
    onNotNow: () => hide(),
    fontName,
    logoUrl,
    letterLogoUrl,
    primaryColor,
    secondaryColor,
    partnerName,
    captchaEnabled,
    accessToken,
    kycEnabled,
    exitEnabled,
    faqEnabled,
    homeTitle,
    homeSubTitle,
    skipHomePage,
    customCalendlyLink,
    hostDomain: `${window.location.protocol}//${window.location.host}`,
  });

  return {
    show: () => show(),
    hide: (triggerOnClose?: boolean) => hide(triggerOnClose),
    update: (params: WidgetUpdateParams) => update(params),
  };
};
