import { FC } from 'react';
import { Box, Typography, Tooltip, useMediaQuery, TooltipProps, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@material-ui/icons';

import { LenderOffer, OfferProductType } from 'store/offers/types';
import {
  decimalToPercentage,
  mapOfferInterestTypeToLabel,
  mapOfferInterestTypeToTransKey,
  mapOfferProductTypeToLabel,
  mapOfferProductTypeToTransKey,
} from 'utils';
import { parseMoney } from 'utils/currency';
import useStyles from './OfferContent.styles';

const CustomTooltip: FC<TooltipProps> = ({ title, children }) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Tooltip
      title={title}
      arrow
      enterTouchDelay={0}
      leaveDelay={3000}
      disableFocusListener={isMobile}
      disableHoverListener={isMobile}
      disableTouchListener={isMobile}
    >
      {children}
    </Tooltip>
  );
};

type OfferContentProps = LenderOffer & { detailsRequested: boolean };

const OfferContent: FC<OfferContentProps> = ({ detailsRequested, ...offer }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getInterestTypeTooltipContent = () => {
    if (offer.product_type === OfferProductType.REVENUE_BASED) return '';
    const transKey = mapOfferInterestTypeToTransKey(offer.monthly_repayment_type);
    return t(`pages.offers.tooltip.interestType.${transKey}`);
  };

  const getProductTypeTooltipMessage = () => {
    const transKey = mapOfferProductTypeToTransKey(offer.product_type);
    return t(`pages.offers.tooltip.productType.${transKey}`);
  };

  const productTypeTooltipMessage = getProductTypeTooltipMessage();
  const interestTypeTooltipMessage = getInterestTypeTooltipContent();

  if (detailsRequested) {
    return (
      <Grid container className={classes.offerSection}>
        <Grid item xs={5}>
          <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.productType')}</Typography>
        </Grid>
        <Grid item xs={7} className={classes.offerSectionValueContainer}>
          <CustomTooltip title={productTypeTooltipMessage}>
            <Typography className={classes.offerSectionValue}>
              <span className={classes.highlightedValue}>
                {mapOfferProductTypeToLabel(offer.product_type)} <InfoOutlined />
              </span>
            </Typography>
          </CustomTooltip>
          <Box className={classes.tooltipMessageContainer}>
            <Typography className={classes.tooltipMessage}>
              <InfoOutlined /> {productTypeTooltipMessage}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Typography className={classes.title}>{t('pages.offers.card.title')}</Typography>

      <Grid container className={classes.offerSection}>
        <Grid item xs={5}>
          <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.fundingAmount')}</Typography>
        </Grid>
        <Grid item xs={7} className={classes.offerSectionValueContainer}>
          <Typography className={classes.offerSectionValue}>{parseMoney(offer.loan_amount, offer.currency)}</Typography>
        </Grid>
      </Grid>

      {offer.product_type === OfferProductType.REVENUE_BASED && (
        <Grid container className={classes.offerSection}>
          <Grid item xs={5}>
            <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.repayment')}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.offerSectionValueContainer}>
            <Typography className={classes.offerSectionValue}>
              {decimalToPercentage(offer.sweep)} {offer.sweep_terms}
            </Typography>
          </Grid>
        </Grid>
      )}

      {offer.product_type !== OfferProductType.REVENUE_BASED && (
        <>
          <Grid container className={classes.offerSection}>
            <Grid item xs={5}>
              <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.term')}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.offerSectionValueContainer}>
              <Typography className={classes.offerSectionValue}>
                {offer.duration_in_months} {t('pages.offers.card.months')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.offerSection}>
            <Grid item xs={5}>
              <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.interestRate')}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.offerSectionValueContainer}>
              <Typography className={classes.offerSectionValue}>{decimalToPercentage(offer.interest_rate)}</Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.offerSection}>
            <Grid item xs={5}>
              <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.monthlyRepayment')}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.offerSectionValueContainer}>
              <Typography className={classes.offerSectionValue}>
                {parseMoney(offer.monthly_repayment, offer.currency)}{' '}
                <CustomTooltip title={interestTypeTooltipMessage}>
                  <span className={classes.highlightedValue}>
                    {mapOfferInterestTypeToLabel(offer.monthly_repayment_type)} <InfoOutlined />
                  </span>
                </CustomTooltip>
              </Typography>

              <Box className={classes.tooltipMessageContainer}>
                <Typography className={classes.tooltipMessage}>
                  <InfoOutlined /> {interestTypeTooltipMessage}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Grid container className={classes.offerSection}>
        <Grid item xs={5}>
          <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.totalRepayable')}</Typography>
        </Grid>
        <Grid item xs={7} className={classes.offerSectionValueContainer}>
          <Typography className={classes.offerSectionValue}>
            {parseMoney(offer.total_repayment, offer.currency)}
          </Typography>
        </Grid>
      </Grid>

      {offer.valid_until && (
        <Grid container className={classes.offerSection}>
          <Grid item xs={5}>
            <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.validUntil')}</Typography>
          </Grid>
          <Grid item xs={7} className={classes.offerSectionValueContainer}>
            <Typography className={classes.offerSectionValue}>
              {new Date(offer.valid_until).toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.offerSection}>
        <Grid item xs={5}>
          <Typography className={classes.offerSectionTitle}>{t('pages.offers.card.productType')}</Typography>
        </Grid>
        <Grid item xs={7} className={classes.offerSectionValueContainer}>
          <CustomTooltip title={productTypeTooltipMessage}>
            <Typography className={classes.offerSectionValue}>
              <span className={classes.highlightedValue}>
                {mapOfferProductTypeToLabel(offer.product_type)} <InfoOutlined />
              </span>
            </Typography>
          </CustomTooltip>
          <Box className={classes.tooltipMessageContainer}>
            <Typography className={classes.tooltipMessage}>
              <InfoOutlined /> {productTypeTooltipMessage}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferContent;
