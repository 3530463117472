import { ApplicationRegion, VerificationSessionStatus } from 'core/types';
import { createMemoryHistory } from 'history';
import { Application, ApplicationStatus } from 'store/app/types';

import routes from './routes';

export const history = createMemoryHistory();

export const restoreNavSession = (application: Application, kycEnabled: boolean): void => {
  const { status, details_requested, company, applicant, region: appRegion } = application;

  if (status === ApplicationStatus.ADDITIONAL_DETAILS_REQUESTED && details_requested && details_requested.length > 0) {
    for (let index = 0; index < details_requested.length; index += 1) {
      const detailsRequest = details_requested[index];
      if (!detailsRequest.offer_id && !detailsRequest.resolved) {
        // Application level request
        history.push(routes.detailsRequested);
        return;
      }
    }
  }

  switch (status) {
    case ApplicationStatus.NEW: {
      if (appRegion !== ApplicationRegion.UK) history.push(routes.fundingGoal);
      else if (!application.credit_limit) history.push(routes.companyInfo);
      else if (applicant.phone_number) history.push(routes.fundingGoal);
      else if (application.credit_limit) history.push(routes.fundingResult);
      return;
    }
    case ApplicationStatus.PENDING:
    case ApplicationStatus.PREQUALIFIED: {
      if (appRegion !== ApplicationRegion.UK) {
        history.push(routes.customiseGoal);
      } else if (company.year_to_date_revenue) {
        const verifiedIdentity = applicant.kyc_identity?.session_status === VerificationSessionStatus.VERIFIED;
        const nextRoute = kycEnabled && !verifiedIdentity ? routes.kyc : routes.customiseGoal;
        history.push(nextRoute);
      } else if (applicant.phone_number) {
        history.push(routes.fundingGoal);
      } else if (application.credit_limit) {
        history.push(routes.fundingResult);
      }
      return;
    }
    case ApplicationStatus.OFFERS_REQUESTED:
    case ApplicationStatus.OFFER_ACCEPTED:
    case ApplicationStatus.FUNDS_DISBURSED:
      history.push(routes.offers);
      return;
    case ApplicationStatus.REJECTED:
      history.push(routes.rejected);
      return;
    case ApplicationStatus.CLOSED:
      history.push(routes.home);
      return;
    default:
      history.push(routes.home);
  }
};
