import { Application } from 'store/app/types';
import { apiCall } from 'http/index';

export const getApplication = async (): Promise<Application> => apiCall({ method: 'get', url: 'applications/me' });

export const getPartnerConfig = async (publicKey: string): Promise<Record<string, unknown>> => {
  const data = await apiCall<{ configuration: Record<string, unknown> } | null>({
    method: 'get',
    url: 'partner/widget/config',
    headers: { 'x-bs-pk': publicKey },
  });
  return data?.configuration ?? {};
};
