import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { RequestConfig, RequestError } from './types';

import { isNetWorkError, accept5xx, accept20x, getErrorMessage } from './utils';

const baseURL = process.env.REACT_APP_BACKEND_URL;

const http = axios.create({ baseURL });

axiosRetry(http, {
  retries: 1,
  retryCondition: (error: AxiosError) => {
    const responseStatusCode = error.response?.status;
    if (isNetWorkError(error) || (responseStatusCode && accept5xx(responseStatusCode))) {
      return true;
    }
    return false;
  },
});

export const apiCall = async <T>(config: RequestConfig): Promise<T> => {
  try {
    const { getRawData, ...rest } = config;

    const { status, data } = await http(rest);

    if (!accept20x(status)) {
      throw new Error(data?.message);
    }

    if (getRawData) return data;

    return data.data;
  } catch (error) {
    const message = getErrorMessage(error as RequestError);
    throw new Error(message);
  }
};

export default http;
