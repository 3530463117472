import { createSlice, ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';

import { signIn } from 'store/auth/actions';
import { clearApplication } from 'store/app/actions';
import { OffersState } from './types';
import { getOffers, clearError, acceptOffer, setSelectedOfferId } from './actions';

export const initialState: OffersState = {
  loading: false,
  error: false,
  offers: [],
  selectedOfferId: null,
};

const offersStore = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<OffersState>) => {
    builder.addCase(getOffers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.offers = payload;
    });

    builder.addCase(clearError, (state) => {
      state.error = false;
    });

    builder.addCase(acceptOffer.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(clearApplication, () => initialState);

    builder.addCase(setSelectedOfferId, (state, { payload }) => {
      state.selectedOfferId = payload;
    });

    builder.addCase(signIn.fulfilled, () => initialState);

    builder.addMatcher(isAnyOf(getOffers.pending, acceptOffer.pending), (state) => {
      state.loading = true;
      state.error = false;
    });

    builder.addMatcher(isAnyOf(getOffers.rejected, acceptOffer.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error?.message || true;
    });
  },
});

export default offersStore.reducer;
