import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { BaseApplicationData, LeadApplicationsPayload } from 'core/types';
import {
  initAuth as initAuthApi,
  getLeadApplications as getLeadApplicationsApi,
  signIn as signInApi,
  reApply as reApplyApi,
} from 'http/auth';
import routes from 'router/routes';
import { history } from 'router/service';
import { AuthState } from './types';

export const INIT_AUTH = 'auth/INIT_AUTH';
export const SIGN_IN = 'auth/SIGN_IN';
export const CLEAR_ERROR = 'auth/CLEAR_ERROR';
export const SET_VERIFIED = 'auth/SET_VERIFIED';
export const GET_LEAD_APPLICATIONS = 'auth/GET_LEAD_APPLICATIONS';
export const RE_APPLY = 'auth/RE_APPLY';
export const SET_ACTIVE_APPLICATION = 'auth/SET_ACTIVE_APPLICATION';

export const initAuth = createAsyncThunk<string, string>(INIT_AUTH, async (email) => {
  try {
    await initAuthApi(email);
    history.push(routes.confirmCode);
  } catch (error) {
    if (/user with given email does not exist/i.test((error as Error).message ?? '')) {
      history.push(routes.preApply);
    } else {
      throw error;
    }
  }
  return email;
});

export const signIn = createAsyncThunk<string | null, string, { state: { auth: AuthState } }>(
  SIGN_IN,
  async (applicationId, { getState }) => {
    const {
      auth: { widgetToken },
    } = getState();

    if (!widgetToken) return null;

    const accessToken = await signInApi(applicationId, widgetToken);
    return accessToken;
  },
);

export const clearError = createAction(CLEAR_ERROR);

export const setVerified = createAction(SET_VERIFIED);

export const getLeadApplications = createAsyncThunk<
  LeadApplicationsPayload | null,
  string,
  { state: { auth: AuthState } }
>(GET_LEAD_APPLICATIONS, async (authCode, { getState }) => {
  const {
    auth: { email },
  } = getState();

  if (!email) {
    history.push(routes.preApply);
    return null;
  }

  const { widget_token, ...leadApplications } = await getLeadApplicationsApi(email, authCode);

  if (leadApplications.active.length === 1) {
    const accessToken = await signInApi(leadApplications.active[0].application_id, widget_token);
    return { accessToken };
  }

  if (leadApplications.active.length > 1 || leadApplications.inactive.length > 0) {
    history.push(routes.userApps);
  } else {
    history.push(routes.preApply);
  }

  return { leadApplications, widgetToken: widget_token };
});

export const setActiveApplication = createAction<BaseApplicationData>(SET_ACTIVE_APPLICATION);

export const reApply = createAsyncThunk<string | null, string, { state: { auth: AuthState } }>(
  RE_APPLY,
  async (applicationId, { getState, dispatch }) => {
    try {
      const {
        auth: { widgetToken, leadApplications },
      } = getState();

      if (!widgetToken) return null;

      const { access_token, application_id, company } = await reApplyApi(applicationId, widgetToken);

      history.push(routes.companyInfo);

      if (leadApplications && leadApplications.active.length > 0) {
        dispatch(
          setActiveApplication({
            application_id,
            company_name: company.registered_name as string,
            creation_date: new Date().toISOString(),
          }),
        );
      }

      return access_token;
    } catch (error) {
      if (/invalid/i.test((error as Error).message ?? '')) {
        const message = i18n.t('pages.reApply.invalidCode');
        throw new Error(message);
      }
      throw error;
    }
  },
);
