import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import i18n from 'i18next';

import {
  patchCompany as patchCompanyApi,
  patchApplicant as patchApplicantApi,
  prequalifyCompany as prequalifyCompanyApi,
  gatherOffers as gatherOffersApi,
  searchDirectors as searchDirectorsApi,
  createApplication as createApplicationApi,
  getCompanyInfo as getCompanyInfoApi,
  validateReCaptcha as validateReCaptchaApi,
  verifyEmail as verifyEmailApi,
} from 'http/onboard';
import { initAuth as initAuthApi } from 'http/auth';
import { AuthState } from 'store/auth/types';
import { history } from 'router/service';
import routes from 'router/routes';
import { AppState } from 'store/app/types';
import { ApplicationRegion, VerificationSessionStatus } from 'core/types';
import {
  Company,
  Applicant,
  RemoveDocumentData,
  GatherOffersData,
  OnboardState,
  PrequalifyCompanyResult,
  CreateApplication,
  CreateApplicationResponse,
  CompanyInfo,
  DocumentsUploadData,
} from './types';

export const PATCH_COMPANY = 'onboard/PATCH_COMPANY';
export const PATCH_APPLICANT = 'onboard/PATCH_APPLICANT';
export const PREQUALIFY_COMPANY = 'onboard/PREQUALIFY_COMPANY';
export const CLEAR_ERROR = 'onboard/CLEAR_ERROR';
export const SAVE_DOCUMENT = 'onboard/SAVE_DOCUMENT';
export const REMOVE_DOCUMENT = 'onboard/REMOVE_DOCUMENT';
export const SET_DOC_ALERT = 'onboard/SET_DOC_ALERT';
export const GATHER_OFFERS = 'onboard/GATTER_OFFERS';
export const GET_DIRECTORS = 'onboard/GET_DIRECTORS';
export const SET_COMPANY_DETAILS = 'onboard/SET_COMPANY_DETAILS';
export const CREATE_APPLICATION = 'onboard/CREATE_APPLICATION';
export const GET_COMPANY_INFO = 'onboard/GET_COMPANY_INFO';
export const VALIDATE_RE_CAPTCHA = 'onboard/VALIDATE_RE_CAPTCHA';
export const VERIFY_EMAIL = 'onboard/VERIFY_EMAIL';
export const SET_APPLICANT_DETAILS = 'onboard/SET_APPLICANT_DETAILS';
export const CLEAR_ONBOARD_DETAILS = 'onboard/CLEAR_ONBOARD_DETAILS';
export const SET_ERROR = 'onboard/SET_ERROR';
export const CLEAR_DIRECTORS = 'onboard/CLEAR_DIRECTORS';
export const SET_WITH_OPTIONAL_FIELDS = 'onboard/SET_WITH_OPTIONAL_FIELDS';
export const SET_SUCCESS = 'onboard/SET_SUCCESS';

const adminSuccessMessage = i18n.t('onboard.adminSuccessMessage');

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const searchDirectors = createAsyncThunk<Applicant[], string>(GET_DIRECTORS, async (companyNumber) => {
  // Catch error - avoids rejected case, instead return empty array
  try {
    const directors = await searchDirectorsApi(companyNumber);
    return directors.map((dir, idx) => ({ ...dir, id: `${Math.random().toString(36).substr(2, 9)}-${idx}` }));
  } catch (error) {
    return [];
  }
});

export const patchCompany = createAsyncThunk<
  Partial<Company>,
  { id: string; data: Partial<Company> },
  { state: { auth: AuthState } }
>(PATCH_COMPANY, async ({ id, data }, { getState, dispatch }) => {
  await patchCompanyApi(id, data);

  if (getState().auth.adminEditMode) {
    dispatch(setSuccess(adminSuccessMessage));
  } else {
    history.push(routes.applicantInfo);
  }

  return data;
});

export const patchApplicant = createAsyncThunk<
  Partial<Applicant> | null,
  { id: string; data: Partial<Applicant> },
  { state: { onboard: OnboardState; auth: AuthState } }
>(PATCH_APPLICANT, async ({ id, data }, { getState, dispatch }) => {
  const {
    onboard: { company },
    auth: { accessToken, adminEditMode },
  } = getState();

  if (company.id && data.email) {
    const applicant = await patchApplicantApi(company.id, id, data);

    if (!accessToken) {
      // Init authentication
      await initAuthApi(data.email);
    }

    if (adminEditMode) {
      dispatch(setSuccess(adminSuccessMessage));
    } else {
      history.push(routes.fundingGoal);
    }

    return applicant;
  }

  return null;
});

export const prequalifyCompany = createAsyncThunk<
  PrequalifyCompanyResult | null,
  { companyInfo: Partial<Company>; authToken?: string },
  { state: { onboard: OnboardState; app: AppState; auth: AuthState } }
>(PREQUALIFY_COMPANY, async ({ companyInfo, authToken }, { getState, dispatch }) => {
  const {
    onboard: { company, prequalifyResult, applicant },
    app: { kycEnabled },
    auth: { adminEditMode },
  } = getState();
  const companyId = companyInfo.id ?? company.id;

  if (companyId) {
    const result = await prequalifyCompanyApi(companyId, companyInfo, authToken);

    if (adminEditMode) {
      dispatch(setSuccess(adminSuccessMessage));

      return { prequalifyResult: result, company: companyInfo };
    }

    if (prequalifyResult?.credit_limit === result.credit_limit) {
      const verifiedIdentity = applicant.kyc_identity?.session_status === VerificationSessionStatus.VERIFIED;
      const nextRoute = kycEnabled && !verifiedIdentity ? routes.kyc : routes.customiseGoal;
      history.push(nextRoute);
    } else if (prequalifyResult?.credit_limit) {
      setTimeout(() => history.push(routes.fundingResult), 1000);
    } else {
      history.push(routes.fundingResult);
    }

    return { prequalifyResult: result, company: companyInfo };
  }

  return null;
});

export const clearError = createAction(CLEAR_ERROR);

export const saveDocument = createAction<DocumentsUploadData>(SAVE_DOCUMENT);

export const removeDocument = createAction<RemoveDocumentData>(REMOVE_DOCUMENT);

export const setDocAlert = createAction<boolean>(SET_DOC_ALERT);

export const gatherOffers = createAsyncThunk<
  number,
  GatherOffersData,
  { state: { onboard: OnboardState; auth: AuthState } }
>(GATHER_OFFERS, async ({ funding_amount }, { getState, dispatch }) => {
  const {
    onboard: { company },
    auth: { adminEditMode },
  } = getState();

  if (company.id) {
    await gatherOffersApi(company.id, funding_amount);

    if (adminEditMode) {
      dispatch(setSuccess(adminSuccessMessage));
    } else {
      setTimeout(() => history.push(routes.offers), 1000);
    }
  }

  return funding_amount;
});

export const setCompanyDetails = createAction<Partial<Company>>(SET_COMPANY_DETAILS);

export const setApplicantDetails = createAction<Partial<Applicant>>(SET_APPLICANT_DETAILS);

export const clearOnboardDetails = createAction(CLEAR_ONBOARD_DETAILS);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createApplication = createAsyncThunk<CreateApplicationResponse, CreateApplication, { dispatch: any }>(
  CREATE_APPLICATION,
  async ({ online_sales_revenue_percentage, ...data }, { dispatch }) => {
    const res = await createApplicationApi(data);
    if (data.company.funding_amount && data.company.funding_reasons && data.company.monthly_revenue) {
      dispatch(
        prequalifyCompany({
          companyInfo: {
            id: res.company.id,
            funding_amount: Number(data.company.funding_amount),
            funding_reasons: data.company.funding_reasons,
            monthly_revenue: Number(data.company.monthly_revenue),
            any_ccj: false,
            online_sales_revenue_percentage,
          },
          authToken: res.access_token,
        }),
      );
    }
    return res;
  },
);

export const getCompanyInfo = createAsyncThunk<CompanyInfo | null, string>(GET_COMPANY_INFO, async (companyNumber) => {
  // Catch error - avoids rejected case, instead return null
  try {
    const result = await getCompanyInfoApi(companyNumber);
    history.push(routes.companyInfo);
    return result;
  } catch (error) {
    return null;
  }
});

export const validateReCaptcha = createAsyncThunk<void, string>(VALIDATE_RE_CAPTCHA, async (reCaptcha) => {
  await validateReCaptchaApi(reCaptcha);
});

export const verifyEmail = createAsyncThunk<
  void,
  { email: string; registeredNumber: string; region: ApplicationRegion; redirect?: boolean }
>(VERIFY_EMAIL, async ({ email, registeredNumber, region, redirect }) => {
  await verifyEmailApi(email, registeredNumber, region);
  if (redirect) history.push(routes.confirmCode);
});

export const setError = createAction<string | boolean>(SET_ERROR);

export const clearDirectors = createAction(CLEAR_DIRECTORS);

export const setHideOptionalFields = createAction<boolean>(SET_WITH_OPTIONAL_FIELDS);
