import {
  Document,
  VerificationSessionStatus,
  FundingReasons,
  CompanyTypes,
  EcommerceProviders,
  CompanyDocumentType,
  CompanyIndustry,
  ApplicationRegion,
} from 'core/types';

export interface Address {
  full?: string;
  id: string | null;
  house_number: string | null;
  line_1: string | null;
  line_2: string | null;
  city: string | null;
  county: string | null;
  postcode: string | null;
  country_code: string | null;
  current: boolean;
  start_date: string | null;
  status: string | null;
  property_equity: number | null;
}

export interface KycIdentity {
  id: string;
  session_id: string;
  session_status: VerificationSessionStatus;
  verification_attempts: number;
  user_id: string;
}

export interface Applicant {
  id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  area_code: string | null;
  phone_number: string | null;
  date_of_birth: string | null;
  nationality?: string;
  country_of_residence?: string;
  role?: string;
  director?: boolean;
  applicant?: boolean;
  occupation?: string;
  appointed_on?: string;
  resigned_on?: string;
  addresses: Address[];
  kyc_identity?: KycIdentity;
}

export enum CCJType {
  ACTIVE = 'ACTIVE',
  HISTORIC = 'HISTORIC',
}

export interface CompanyCCJ {
  type: CCJType;
  description: string;
}

export interface CompanyProvider {
  id: string;
  provider: EcommerceProviders;
  name?: string;
  domain?: string;
  link_url?: string;
}

export interface Company {
  id: string | null;
  trading_name: string | null;
  registered_name: string | null;
  registered_number: string | null;
  type: string | null;
  region: string | null;
  website: string | null;
  formation_date: string | null;
  trading_from_date: string | null;
  address: Address | null;
  sic_codes: string[];

  vat_registered: boolean;
  vat_number: string | null;
  vat_registration_date: string | null;

  funding_amount: number | null;
  funding_reasons: FundingReasons[];
  funding_date: string | null;
  monthly_revenue: number | null;
  year_to_date_revenue: number | null;
  online_sales_revenue_percentage: number | null;
  repeat_customers_revenue_percentage: number | null;

  ein: string | null;
  industry: CompanyIndustry | null;

  any_ccj: boolean | null;
  ccjs: CompanyCCJ[];

  directors?: Applicant[];
  application_id: string | null;
  providers?: CompanyProvider[];
  company_status?: string;
  documents?: Document[];
}

export type Product = Record<string, boolean>;

export interface UnmetCriteria {
  [category: string]: string[];
}

export interface PrequalificationResult {
  approved: boolean;
  credit_limit: number;
  unmet_criteria?: Record<string, string[]>;
}

export interface OnboardState {
  loading: boolean;
  error: boolean | string;
  success: boolean | string;
  company: Company;
  applicant: Applicant;
  prequalifyResult: PrequalificationResult | null;
  additionalInfo: Record<CompanyDocumentType, Document[]>;
  documentUploadSuccess: boolean;
  searchingDirectors: boolean;
  hideOptionalFields: boolean;
}

export interface DocumentsUploadData {
  documents: Document[];
  type: CompanyDocumentType;
}

export interface RemoveDocumentData {
  documentId: string;
  type: CompanyDocumentType;
}

export interface GatherOffersData {
  funding_amount: number;
}

export interface OnboardHookReturn extends OnboardState {
  patchCompany: (payload: { id: string; data: Partial<Company> }) => void;
  patchApplicant: (payload: { id: string; data: Partial<Applicant> }) => void;
  prequalifyCompany: (payload: { companyInfo: Partial<Company>; authToken?: string | undefined }) => void;
  clearError: () => void;
  saveDocument: (data: DocumentsUploadData) => void;
  removeDocument: (data: RemoveDocumentData) => void;
  setDocAlert: (state: boolean) => void;
  gatherOffers: (data: GatherOffersData) => void;
  setCompanyDetails: (data: Partial<Company>) => void;
  createApplication: (data: CreateApplication) => void;
  searchDirectors: (companyNumber: string) => void;
  getCompanyInfo: (companyNumber: string) => void;
  validateReCaptcha: (reCaptcha: string) => void;
  verifyEmail: (payload: {
    email: string;
    registeredNumber: string;
    region: ApplicationRegion;
    redirect?: boolean;
  }) => void;
  setApplicantDetails: (data: Partial<Applicant>) => void;
  clearOnboardDetails: () => void;
  setError: (error: string | boolean) => void;
  clearDirectors: () => void;
  setHideOptionalFields: (optionalFields: boolean) => void;
  setSuccess: (success: string | boolean) => void;
}

export interface CompanySearchResult {
  title: string;
  companyNumber: string;
  incorporatedOn: string;
  companyAddress: Address | null;
  companyType: CompanyTypes;
  companyStatus: string;
}

export interface PrequalifyCompanyResult {
  prequalifyResult: PrequalificationResult;
  company: Partial<Company>;
}

export interface CreateApplication {
  company: Partial<Company>;
  applicant: Partial<Applicant>;
  directors: Partial<Applicant>[];
  auth_code: string;
  online_sales_revenue_percentage: number;
  region: ApplicationRegion;
}

export interface CreateApplicationResponse {
  company: Partial<Company>;
  applicant: Partial<Applicant>;
  access_token: string;
  application_id: string;
  region: ApplicationRegion;
}

export interface CompanyInfo {
  sic_codes?: string[];
}

export enum CompanyRegion {
  GB = 'GB',
  IE = 'IE',
  AE = 'AE',
  OTHER = 'other',
}
