import { useRef, useEffect, useCallback } from 'react';

interface IsMountedReturnHook {
  whenMounted: (cb: () => void | Promise<void>) => void;
  isMounted: boolean;
}

const useIsMounted = (): IsMountedReturnHook => {
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const whenMounted = useCallback((cb: () => void | Promise<void>) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (isMounted.current) cb();
  }, []);

  return { whenMounted, isMounted: isMounted.current };
};

export default useIsMounted;
