import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    widgetContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    alertContainer: {
      top: 35,
      alignItems: 'flex-start',
      maxHeight: 50,
    },
  }),
);
