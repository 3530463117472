import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useCommonStyles from 'core/styles';
import { EcommerceProviders } from 'core/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnboard } from 'store/onboard/hooks';
import { CompanyProvider } from 'store/onboard/types';
import { toTitleCase } from 'utils';
import { IntegrationButton, getProviderIcon } from '../IntegrationButton';
import useStyles from './IntegrationsSection.styles';

interface IntegrationsSectionProps {
  title?: string;
  description?: string;
  enabledProviders: EcommerceProviders[];
}

const IntegrationsSection: FC<IntegrationsSectionProps> = ({ title, description, enabledProviders }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { company } = useOnboard();

  const connectedProviders = company.providers?.filter((provider) => !provider.link_url);

  return (
    <>
      <Typography className={clsx([commonClasses.subtitle2, commonClasses.primaryText, commonClasses.strongText])}>
        {title ?? t('pages.additionalInfo.sections.integrations.title')}
      </Typography>

      <Typography className={clsx([commonClasses.subtitle2, commonClasses.mb20, commonClasses.mt20])}>
        {description ?? t('pages.additionalInfo.sections.integrations.description')}
      </Typography>

      {enabledProviders.map((enabledProvider) => (
        <Box className={classes.content} key={enabledProvider}>
          <IntegrationButton provider={enabledProvider} />

          {!!connectedProviders?.length && (
            <List className={classes.list}>
              {connectedProviders.reduce((acc: JSX.Element[], connectedProvider: CompanyProvider): JSX.Element[] => {
                if (connectedProvider.provider === enabledProvider) {
                  acc.push(
                    <ListItem key={connectedProvider.id} className={classes.listItem}>
                      <ListItemIcon className={classes.listItemIcon}>{getProviderIcon(enabledProvider)}</ListItemIcon>
                      <ListItemText
                        primary={
                          connectedProvider.name ||
                          t('pages.additionalInfo.integrations.ecommerce.connectionName', {
                            provider: toTitleCase(connectedProvider.provider),
                          })
                        }
                        secondary={connectedProvider.domain}
                        className={classes.listItemText}
                      />
                    </ListItem>,
                  );
                }
                return acc;
              }, [])}
            </List>
          )}
        </Box>
      ))}
    </>
  );
};

export default IntegrationsSection;
