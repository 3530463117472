import { FC, ReactNode, CSSProperties } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './AlertDialog.styles';

interface AlertDialogProps {
  open: boolean;
  dialogContentTitle?: string;
  dialogContentText?: string | ReactNode;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  cancelText?: string;
  confirmText?: string;
  loading?: boolean;
  contentContainerStyle?: CSSProperties;
}

const AlertDialog: FC<AlertDialogProps> = ({
  open,
  dialogContentTitle,
  dialogContentText,
  handleConfirm,
  handleCancel,
  cancelText,
  confirmText,
  loading = false,
  children,
  contentContainerStyle = {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleCancel || handleConfirm}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // maxWidth="xs"
      classes={{ paper: classes.paper }}
      fullScreen={fullScreen}
    >
      {dialogContentTitle && (
        <DialogTitle id="alert-dialog-title" className={classes.title}>
          {dialogContentTitle}
        </DialogTitle>
      )}
      <DialogContent className={classes.contentContainer} style={contentContainerStyle}>
        {dialogContentText && (
          <DialogContentText id="alert-dialog-description" className={classes.description}>
            {dialogContentText}
          </DialogContentText>
        )}

        {children}
      </DialogContent>

      {(handleConfirm || handleCancel) && (
        <DialogActions className={classes.actionsContainer}>
          {handleCancel && (
            <Button onClick={handleCancel} color="primary" id="alertDialogCancelButton">
              {cancelText || t('global.actions.cancel')}
            </Button>
          )}

          {handleConfirm && (
            <Button onClick={handleConfirm} color="primary" id="alertDialogConfirmButton">
              {confirmText || t('global.actions.continue')}
            </Button>
          )}
        </DialogActions>
      )}

      {loading && (
        <Box className={classes.loderContainer}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </Dialog>
  );
};

export default AlertDialog;
