import { FC, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FileRejection } from 'react-dropzone';
import { HighlightOff as RejectedFileIcon, Close as DeleteFileIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { CompanyDocumentType, Document, DocUploadResponse } from 'core/types';
import { uploadDocument as uploadDocumentApi, deleteDocument } from 'http/documents';
import { allowedFileTypes } from 'core/constants';
import { noop } from 'utils';
import { useOnboard } from 'store/onboard/hooks';
import useStyles from './DocumentUpload.styles';
import { FileDrop } from '../../FileDrop';
import { AlertDialog } from '../../alerts';

interface DocumentUploadProps {
  documentType: CompanyDocumentType;
  validationError?: string;
  multipleFiles?: boolean;
}

const DocumentUpload: FC<DocumentUploadProps> = ({ documentType, validationError, multipleFiles }) => {
  const [rejectedFiles, setRejections] = useState<FileRejection[]>([]);
  const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | string>(false);
  const classes = useStyles();
  const { company, additionalInfo, saveDocument, removeDocument } = useOnboard();
  const { t } = useTranslation();

  useEffect(() => {
    if (rejectedFiles?.length > 0) {
      setError(true);
      return;
    }
    setError(false);
  }, [rejectedFiles]);

  const integrationDocuments = additionalInfo[documentType];

  const uploadDocument = useCallback(
    async (data: FormData): Promise<DocUploadResponse | null> => {
      setError(false);
      if (company.id) {
        return uploadDocumentApi(company.id, data);
      }
      return null;
    },
    [company.id],
  );

  const handleUpload = useCallback(
    (documents: Document[]) => {
      if (documents.length) {
        saveDocument({ documents, type: documentType });
      }
    },
    [documentType, saveDocument],
  );

  const handleRejection = useCallback((files: FileRejection[]) => {
    setRejections(files);
  }, []);

  const handleCancel = () => {
    setSelectedDoc(null);
  };

  const handleConfirm = useCallback(async () => {
    if (company.id && selectedDoc) {
      setError(false);
      setLoading(true);
      try {
        await deleteDocument(company.id, selectedDoc.id);
        setSelectedDoc(null);
        removeDocument({ documentId: selectedDoc.id, type: documentType });
      } catch (err) {
        setSelectedDoc(null);
        setError((err as Error)?.message);
      }
      setLoading(false);
    }
  }, [company.id, documentType, removeDocument, selectedDoc]);

  return (
    <Box>
      <FileDrop
        allowedFileTypes={allowedFileTypes}
        onUpload={handleUpload}
        onReject={handleRejection}
        uploadMessage={t('pages.additionalInfo.buttons.uploadDocuments')}
        uploadDocument={uploadDocument}
        documentType={documentType}
        error={error || validationError}
        multiple={multipleFiles}
        uploadedDocs={integrationDocuments}
      />

      {integrationDocuments && Array.isArray(integrationDocuments) && integrationDocuments.length > 0 && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>Uploaded files</Typography>

          <List>
            {/* NOTE: Just one document per section - it might change in the future */}
            {integrationDocuments.map((res: Document) => {
              return (
                <ListItem className={classes.demo} key={res.id}>
                  <ListItemText primary={res.name} className={classes.fileTitle} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => setSelectedDoc(res)}
                      id={`deleteDocumentButton-${documentType}`}
                    >
                      <Avatar className={classes.deleteAvatar}>
                        <DeleteFileIcon className={classes.deleteIcon} />
                      </Avatar>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}

      {rejectedFiles.length > 0 && (
        <Box marginTop="15px">
          <Typography className={classes.sectionTitle}>Rejected files</Typography>

          <List>
            {rejectedFiles.map((res: FileRejection, index: number) => {
              return (
                <ListItem key={res.file.name}>
                  <ListItemText primary={res.file.name} className={classes.fileTitle} />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={noop}
                      id={`rejectedDocumentButton-${documentType}${index + 1}`}
                    >
                      <Tooltip title={`${t(res.errors[0].message)}`} placement="top-start">
                        <div className={classes.deleteAvatar}>
                          <RejectedFileIcon color="error" />
                        </div>
                      </Tooltip>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}

      <AlertDialog
        open={!!selectedDoc}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        dialogContentTitle={t('pages.additionalInfo.documents.delete.title')}
        dialogContentText={
          <>
            {t('pages.additionalInfo.documents.delete.description.line1')} <strong>{selectedDoc?.name}</strong>
            {t('pages.additionalInfo.documents.delete.description.line2')}{' '}
            {t('pages.additionalInfo.documents.delete.description.line3')}
          </>
        }
        loading={loading}
      />
    </Box>
  );
};

export default DocumentUpload;
