import { EcommerceProviders } from 'core/types';
import { apiCall } from 'http/index';

export const createMerchantAuthConnectionUrl = async (provider: EcommerceProviders): Promise<string> => {
  const data = await apiCall<{ linkUrl: string }>({
    method: 'post',
    url: '/ecommerce/integrations/rutter',
    data: { provider },
  });
  return data.linkUrl;
};
