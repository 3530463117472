import { SupportedCurrencies } from 'core/types';

export interface Lender {
  name: string;
  address: string;
}

export enum OfferStatus {
  PENDING = 'PENDING',
  DEFERRED = 'DEFERRED', // Already applied for a loan
  DECLINED = 'DECLINED',
  ADDITIONAL_DETAILS_REQUESTED = 'ADDITIONAL_DETAILS_REQUESTED',
  OFFERED = 'OFFERED',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
}

export enum OfferProductType {
  REVENUE_BASED = 'REVENUE_BASED',
  CREDIT_LINE = 'CREDIT_LINE',
  REVOLVING_CREDIT_FACILITY = 'REVOLVING_CREDIT_FACILITY',
  TERM_LOAN = 'TERM_LOAN',
}

export enum MonthlyRepaymentType {
  INTEREST_ONLY = 'INTEREST_ONLY',
  INTEREST_AND_CAPITAL = 'INTEREST_AND_CAPITAL',
}

export interface OfferRate {
  interest_rate: number;
  principal: number;
  effective_on_day?: number;
  rate_id: string;
  selected: boolean;
  monthly_repayment: number;
  monthly_repayment_type: MonthlyRepaymentType;
  total_repayment: number;
}

export interface OfferRevenueRepayment {
  currency: SupportedCurrencies;
  revenue_repayment_id: string;
  selected: false;
  sweep: number;
  sweep_terms: string;
  total_get: number;
  total_repayment: number;
}

export enum OfferType {
  API = 'API',
  MANUAL = 'MANUAL',
}

export interface Offer {
  id: string;
  application_id: string;
  lender_name: string;
  lender_logo?: string;
  status: OfferStatus;
  type: OfferType;
  currency: SupportedCurrencies;
  notes?: string;
  rejection_reason?: string;
  valid_until?: string;
  created_at: string;
  updated_at: string;
  // added on frontend
  date: string; // for sorting purposes,
  // flattened props
  unique_id: string;
  loan_amount: number;
  selected: boolean;
}

export interface BasicOffer extends Offer {
  min_credit_offer: number;
  max_credit_offer: number;
  interest_rate: number;
  product_type: OfferProductType.CREDIT_LINE | OfferProductType.REVOLVING_CREDIT_FACILITY | OfferProductType.TERM_LOAN;
  duration_in_months: number;
  interest_type: string;
  rates: OfferRate[];
  // added on frontend
  rate_id: string;
  monthly_repayment: number;
  monthly_repayment_type: MonthlyRepaymentType;
  total_repayment: number;
}

export interface RevenueOffer extends Offer {
  product_type: OfferProductType.REVENUE_BASED;
  revenueRepayments: OfferRevenueRepayment[];
  // added on frontend
  repayment_id: string;
  sweep: number;
  sweep_terms: string;
  total_repayment: number;
}

export type LenderOffer = BasicOffer | RevenueOffer;

export interface OffersState {
  loading: boolean;
  error: boolean | string;
  offers: LenderOffer[];
  selectedOfferId: string | null;
}

export interface OffersHookReturn extends OffersState {
  getOffers: () => void;
  clearError: () => void;
  acceptOffer: (uniqueId: string) => void;
  setSelectedOfferId: (offerId: string | null) => void;
}

export interface AcceptOfferData {
  companyId: string;
  offerId: string;
  amount: number;
  rateId?: string;
  repaymentId?: string;
}

export interface AcceptOfferBody {
  borrow_amount: number;
  selected_rate_id?: string;
  selected_revenue_repayment_id?: string;
}
