import { FC } from 'react';
import { Box, Slider, Typography, SliderProps } from '@material-ui/core';

import { parseMoney } from 'utils/currency';
import useStyles from './CustomSlider.styles';

interface CustomSliderProps extends Omit<SliderProps, 'onChange'> {
  id: string;
  withIntervalLabels?: boolean;
  prefix?: string;
  onChange: (value: number) => void;
}

const CustomSlider: FC<CustomSliderProps> = ({
  id,
  min,
  max,
  onChange,
  withIntervalLabels = true,
  prefix = '',
  ...rest
}) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  return (
    <Box className={classes.sliderContainer}>
      <Slider id={id} className={classes.slider} min={min} max={max} onChange={handleChange} {...rest} />

      {withIntervalLabels && (
        <Box className={classes.sliderIntervalContainer}>
          <Typography className={classes.sliderInterval}>{`${prefix}${parseMoney(min)}`}</Typography>

          <Typography className={classes.sliderInterval}>{`${prefix}${parseMoney(max)}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CustomSlider;
