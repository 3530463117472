import { AnyAction, createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { ApplicationRegion, CompanyDocumentType, Document } from 'core/types';
import { getApplication as getApplicationApi } from 'http/app';
import { restoreNavSession } from 'router/service';
import { ApplicationWithAdditionalInfo, AppState } from './types';

export const UPDATE_APP_STATE = 'app/UPDATE_APP_STATE';
export const GET_APPLICATION = 'app/GET_APPLICATION';
export const CLEAR_APPLICATION = 'app/CLEAR_APPLICATION';
export const SET_ERROR = 'app/SET_ERROR';
export const SET_APP_REGION = 'app/SET_APP_REGION';
export const OPEN_DOCS_TERMS = 'app/OPEN_DOCS_TERMS';
export const OPEN_DOCS_POLICY = 'app/OPEN_DOCS_POLICY';
export const AGREE_DOCS = 'app/AGREE_DOCS';

export const updateAppState = createAction<Partial<AppState> & { accessToken?: string; adminEditMode?: boolean }>(
  UPDATE_APP_STATE,
);

export const updateAppStateAction = (appState: Partial<AppState> & { accessToken?: string }) => (
  dispatch: Dispatch<AnyAction>,
): void => {
  let adminEditMode = false;
  if (appState.accessToken) {
    const tokenPayload = jwtDecode<JwtPayload & { admin?: boolean }>(appState.accessToken);
    if (tokenPayload.admin) adminEditMode = true;
  }
  dispatch(updateAppState({ ...appState, adminEditMode }));
};

export const getApplication = createAsyncThunk<ApplicationWithAdditionalInfo, void, { state: { app: AppState } }>(
  GET_APPLICATION,
  async (_, { getState }) => {
    const application = await getApplicationApi();
    const additionalInfo: Record<string, Document[]> = {};

    const requestedDocTypes = application.details_requested?.reduce(
      (docs: CompanyDocumentType[], dReq) => Array.from(new Set([...docs, ...dReq.document_types])),
      [],
    );

    application.company.documents?.forEach((document: Document) => {
      if (requestedDocTypes?.includes(document.type)) return;

      if (additionalInfo[document.type]) {
        additionalInfo[document.type].push(document);
      } else {
        additionalInfo[document.type] = [document];
      }
    });

    const {
      app: { kycEnabled },
    } = getState();
    restoreNavSession(application, !!kycEnabled);

    return { ...application, additionalInfo };
  },
);

export const clearApplication = createAction<boolean | undefined>(CLEAR_APPLICATION);

export const setError = createAction<boolean | string>(SET_ERROR);

export const setAppRegion = createAction<ApplicationRegion>(SET_APP_REGION);

export const showPolicy = createAction<boolean>(OPEN_DOCS_TERMS);

export const showTerms = createAction<boolean>(OPEN_DOCS_POLICY);

export const agreeTerms = createAction(AGREE_DOCS);
